import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editUser, getUserData } from '../../../store/actions/userActions';
import AHButton from '../../common/AHButton/AHButton';
import ImageUploader from '../../common/ImageUploader/ImageUploader';
import './style.scss';

const PersonalInfo = function (): JSX.Element {
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState('');
  const [editFirstName, setEditFirstName] = useState(false);
  const [lastName, setLastName] = useState('');
  const [editLastName, setEditLastName] = useState(false);
  const [email, setEmail] = useState('');
  const [editEmail, setEditEmail] = useState(false);

  //Redux - State
  const fName = useSelector((state: any) => state?.user?.firstName);
  const lName = useSelector((state: any) => state?.user?.lastName);
  const mail = useSelector((state: any) => state?.user?.email);

  // --> First Name Handlers
  const handleEditFirstName = () => {
    setEditFirstName(true);
  };
  const handleChangeFirstName = (e) => {
    setFirstName(e.target.value);
  };
  const handleSubmitFirstName = () => {
    dispatch(editUser(firstName, 'first_name'));
    setEditFirstName(false);
  };

  // --> Last Name Handlers
  const handleEditLastName = () => {
    setEditLastName(true);
  };
  const handleChangeLastName = (e) => {
    setLastName(e.target.value);
  };
  const handleSubmitLastName = () => {
    dispatch(editUser(lastName, 'last_name'));
    setEditLastName(false);
  };

  // --> Email Handlers
  const handleEditEmail = () => {
    setEditEmail(true);
  };
  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleSubmitEmail = () => {
    dispatch(editUser(email, 'email'));
    setEditEmail(false);
  };

  // submit functions
  const updateProfileImage = (file: File) => {
    dispatch(editUser(file, 'profile_pic'));
  };

  // Run on Mount
  useEffect(() => {
    dispatch(getUserData());
  }, []);

  useEffect(() => {
    setFirstName(fName);
    setLastName(lName);
    setEmail(mail);
  }, [fName, lName, mail]);

  return (
    <div>
      <div className="pi-title-box">
        <h3 className="pi-title">Personal Info</h3>
      </div>
      <div className="pi-content">
        <div className="pi-content-box pi-photo">
          <h6 className="pi-content-title">Photo</h6>
          <ImageUploader update={updateProfileImage} />
        </div>
        <div className="pi-content-box pi-name">
          <h6 className="pi-content-title">First Name</h6>
          <div className="pi-content-inner">
            {editFirstName ? (
              <input
                className="pi-fname-input input"
                type="text"
                value={firstName}
                onChange={handleChangeFirstName}
              />
            ) : (
              <input
                disabled
                className="pi-content-name"
                type="text"
                value={firstName}
              />
            )}
            {editFirstName ? (
              <AHButton
                text="Save"
                variant="contained"
                color="primary"
                btntype="action"
                handleClick={handleSubmitFirstName}
              />
            ) : (
              <AHButton
                text="Edit"
                variant="contained"
                color="primary"
                btntype="action"
                handleClick={handleEditFirstName}
              />
            )}
          </div>
        </div>
        <div className="pi-content-box pi-name">
          <h6 className="pi-content-title">Last Name</h6>
          <div className="pi-content-inner">
            {editLastName ? (
              <input
                className="pi-fname-input input"
                type="text"
                onChange={handleChangeLastName}
                value={lastName}
              />
            ) : (
              <input
                disabled
                className="pi-content-name"
                type="text"
                value={lastName}
              />
            )}
            {editLastName ? (
              <AHButton
                text="Save"
                variant="contained"
                color="primary"
                btntype="action"
                handleClick={handleSubmitLastName}
              />
            ) : (
              <AHButton
                text="Edit"
                variant="contained"
                color="primary"
                btntype="action"
                handleClick={handleEditLastName}
              />
            )}
          </div>
        </div>
        <div className="pi-content-box pi-email">
          <h6 className="pi-content-title">Email</h6>
          <div className="pi-content-inner">
            {editEmail ? (
              <input
                className="pi-email-input input"
                type="text"
                value={email}
                onChange={handleChangeEmail}
              />
            ) : (
              <input
                disabled
                className="pi-content-name"
                type="text"
                value={email}
              />
            )}

            {editEmail ? (
              <AHButton
                text="Save"
                variant="contained"
                color="primary"
                btntype="action"
                handleClick={handleSubmitEmail}
              />
            ) : (
              <AHButton
                text="Edit"
                variant="contained"
                color="primary"
                btntype="action"
                handleClick={handleEditEmail}
              />
            )}
          </div>
        </div>
        {/* <div className="pi-content-box pi-notification">
          <h6 className="pi-content-title">Notifications</h6>
          <FormControlLabel
            control={
              <Checkbox
                checked={true}
                onChange={() => {}}
                name="checkedB"
                color="primary"
              />
            }
            label="Receive email notifications about comment activity."
          />
        </div> */}
      </div>
    </div>
  );
};
export default PersonalInfo;
