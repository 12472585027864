import DateFnsUtils from '@date-io/date-fns';
import { styled } from '@material-ui/core/styles';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { useSelector, useDispatch } from 'react-redux';
import { changeCampaignValue } from '../../../store/actions/campaignActions';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import React, { useEffect } from 'react';
import { IAHDatePickerProps } from '../../../models';
import './AHDatePicker.scss';

const InputContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});
const AHDatePicker: React.FC<IAHDatePickerProps> = ({
  dataTestId,
  required,
  label,
  name,
  type,
  placeholder,
  props,
}) => {
  const dispatch = useDispatch();
  const start_date = useSelector(
    (state: any) => state?.campaigns?.campaign?.start_date
  );

  const end_date = useSelector(
    (state: any) => state?.campaigns?.campaign?.end_date
  );

  // console.log(end_date.setHours(0, 0, 0, 0));

  const handleDateChange = (date: Date | null) => {
    date && date.setHours(0, 0, 0, 0);
    window.analytics.track(
      `${type === 'start' ? 'Start' : 'End'} date change event`,
      { date }
    );
    dispatch(
      changeCampaignValue({
        name: type === 'start' ? 'start_date' : 'end_date',
        data: date,
      })
    );
  };

  return (
    <div className="custom-date-picker-wrapper">
      <div className="label-wrapper">
        <div className="campForm-label">{label}</div>
        {!require && <div className="optional">optional</div>}
      </div>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          inputProps={{ 'data-testid': `${dataTestId}` }}
          disableToolbar
          variant="inline"
          name={name}
          className="custom-date-picker"
          format="MM/dd/yyyy"
          margin="normal"
          id="date-picker-inline"
          // label="Date picker inline"
          value={type === 'start' ? start_date : end_date}
          onChange={handleDateChange}
          InputAdornmentProps={{ position: 'start' }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          keyboardIcon={<DateRangeIcon />}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default AHDatePicker;
