import React from 'react';
import './style.scss';

const BillingSettings = function (): JSX.Element {
  return (
    <div>
      <div className="bs-title-box">
        <h3 className="bs-title">Billing Settings</h3>
      </div>
      <div className="bs-content">
        <div className="bs-content-box bs-photo">
          <h6 className="bs-content-title">Photo</h6>
        </div>
      </div>
    </div>
  );
};
export default BillingSettings;
