import SearchIcon from '@material-ui/icons/Search';
import React from 'react';
import './style.scss';

interface IProps {
  campaignSearch: (lookup: string) => void;
}
//@ts-ignore
const Search: React.FC = ({ campaignSearch }: IProps) => {
  return (
    <div className="search-wrapper">
      <SearchIcon className="search-icon" />
      <input
        placeholder="Search my campaigns"
        onChange={(e) => campaignSearch(e.target.value)}
      />
    </div>
  );
};
export default Search;
