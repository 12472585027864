import React from 'react';
import SubmitForm from '../form/submit-form';
import './style.scss';

export interface ISignInForm {
  title: string;
  subtitle: string;
  label: string;
  type?: string;
  placeholder: string;
  value: string;
  buttonLabel: string;
  formHandler: (event: any) => void;
  handleInputChange: (event: any) => void;
  isPasscodeStep?: boolean;
  activity: boolean;
  resendPasscode?: (event: any) => void;
}

const SignInForm: React.FC<ISignInForm> = ({
  value,
  activity,
  title,
  subtitle,
  label,
  type = 'text',
  placeholder,
  buttonLabel,
  formHandler,
  handleInputChange,
  isPasscodeStep,
  resendPasscode,
}) => {
  return (
    <div className={`sign-in-form ${isPasscodeStep ? 'passcode' : ''}`}>
      <h1 className="sign-in-form__title">{title}</h1>
      <h2 className="sign-in-form__subtitle">{subtitle}</h2>
      <SubmitForm
        inputValue={value}
        activity={activity}
        label={label}
        type={type}
        placeholder={placeholder}
        buttonLabel={buttonLabel}
        formHandler={formHandler}
        handleInputChange={handleInputChange}
      />
      {isPasscodeStep ? (
        <p className="sign-in-form__passcode-message">
          Didn't receive the passcode? 
          <span
            style={{
              color: 'blue',
              textDecoration: 'underline',
              cursor: 'pointer',
              marginLeft: '.5rem',
            }}
            onClick={resendPasscode}
          >
            Request a new passcode.
          </span>
        </p>
      ) : null}
    </div>
  );
};
export default SignInForm;
