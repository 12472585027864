import React, { ReactElement } from 'react';
import Header from '../components/header/index';

interface IProps {
  children: ReactElement;
}

function WithHeaderLayout({ children }: IProps): any {
  return (
    <>
      <Header />
      {children}
    </>
  );
}
export default WithHeaderLayout;
