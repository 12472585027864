import React, { useEffect, useState } from 'react';
import { createAdvertiserAction } from '../../../store/actions/userActions';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import AHButton from '../../common/AHButton/AHButton';
import { categories } from './categoriesDropdownData'
import {
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import './style.scss'
import Card from '../../Card'
import isURL from 'validator/es/lib/isURL';
import { useHistory } from 'react-router-dom'

const AddAdvertiser = () => {

  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const history = useHistory()

  const [checked, setChecked] = useState(false)

  const [urlIsValid, setUrlIsValid] = useState(true)
  const [showUrlError, setShowUrlError] = useState(false)
  const [url, setUrl] = useState('https://')

  const [isLoading, setIsLoading ] = useState(false)

  const [value, setValue] = useState({
    companyLogo: null,
  });

  const { companyLogo } = value;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true)

    const organization = e.target.name.value
    const website = e.target.url.value
    const category = e.target.category.value

    const logo = companyLogo

    if (!urlIsValid) {
      setShowUrlError(true)
    }

    const response: any = await dispatch(
      createAdvertiserAction(
        organization,
        category,
        logo,
        website,
        enqueueSnackbar
      )
    )
    
    setIsLoading(false)

    if (response.error) {
      // note: do nothing
    } else {
      history.push('/dashboard')
    }
      
  }

  const validateUrl = () => {
    const valid = isURL(url, { require_protocol: true })
    setUrlIsValid(valid) 
  }

  const handleUrlChange = (e) => {
    setUrl(e.target.value)
    const url = e.target.value
    setShowUrlError(false)
  }

  useEffect(() => {
    validateUrl()
  }, [url])

  const handleChangeLogo = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        setValue({ ...value, companyLogo: file });
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="w-full">
      <Card>
        <div className="add-advertiser-wrapper">
          <div className="w-full h-16 flex place-items-center">
            <div className="text-md font-semibold text-black pl-6">
              Add advertiser page
            </div>
          </div> 
          <hr className="h-1 my-0"/>
          <div className="p-6 max-w-sm">
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="flex pb-8 w-full">
                <input className="input w-full" type="text" placeholder="Company Name" name="name" />
              </div>
              <div className="flex w-full">
                <input onChange={handleUrlChange} value={url} className="input w-full" type="text" placeholder="Url" name="url" />
              </div>
              <div className="h-8 pt-1 text-red-500">
                <span className={!showUrlError ? 'hidden' : ''}>Invalid website</span>
              </div>

              <div className="">
                <select className="input w-full" name="category">
                  {categories.map((category) => (
                    <option 
                      key={category.id} 
                      value={category.value}>{category.name}</option>
                      ))}
                </select>
              </div>

              <div className="company-logo-uploader">
                <label
                  className="company-logo-label"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <span>Upload Logo</span>
                  <span style={{ opacity: 0.5 }}>.png or .jpg</span>
                </label>
                <div className="company-logo-uploader-inner">
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    placeholder="Logo"
                    className="company-logo-field"
                    onChange={handleChangeLogo}
                  />
                  {companyLogo && (
                    <p className="company-logo-name">{companyLogo.name}</p>
                  )}
                </div>
              </div>

              <div className="pb-8">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                      color="primary"
                    />
                  }
                  label={
                    <span>
                      I've read and agree to the
                      <a href="https://www.audiohook.com/terms" target="_blank">
                        {' '}
                        terms of service{' '}
                      </a>
                      and
                      <a
                        href="https://www.audiohook.com/privacy-policy"
                        target="_blank"
                      >
                        {' '}
                        privacy policy
                      </a>
                      .
                    </span>
                  }
                />
              </div>

              <div className="flex justify-end">
                <AHButton
                  text="Submit"
                  handleClick={() => {}}
                  btntype="action"
                  color="primary"
                  disabled={!checked || isLoading}
                  variant="contained"
                  type="submit"
                />
              </div>

            </form>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AddAdvertiser;
