export enum PartialFreqCampEnum {
    Freq = 'frequency',
    Value = 'intervalValue',
    Type = 'intervalType',
}

export enum FreqCapEnum {
    Freq = 'frequency',
    Inter = "interval"
}

export enum IntervalEnum {
    Hour = "hour",
    Day = "day",
    Week = "week"
}

export enum DropzoneEnum {
    EmailList = "email_list",
    Creative = "creative"
}

export enum CampaignTypeEnum {
    PERFORMANCE = 'performance',
    IMPRESSION = 'impression'
}

export enum BugetTypeEnum {
    SPEND = 'spend',
    CPA = 'cpa',
    IMPRESSIONS = 'impressions',
    COMPLETED_AUDIO_ADS = 'completed_audio_ads'
}

export enum InventoryBranchEnum {
    INCLUDE = 'include',
    EXCLUDE = 'exclude'
}