import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Dashboard from '../pages/dashboard/Dashboard';
import Information2 from '../pages/information/Information2';
import ProfileV2 from '../pages/profile-v2/profileV2';
import Advertisers from '../pages/advertisers/advertisers';
import AddAdvertiser from '../pages/AddAdvertiser';
import PublicSignIn from '../pages/Signin/PublicSignIn';
import SignIn from '../pages/Signin/SignIn';
import SignUp from '../pages/Signup/Signup';
import PrivateRoute from './PrivateRoute';
import { paths as teamPaths } from './teamRoutes';

export enum AuthRoutesEnum {
  Dashboard = '/dashboard',
  Information = '/campaign',
  Profile = '/profile',
  Information2 = '/info2',
  AdvertiserTeams = '/advertisers/:id/team-settings',
  AdvertiserCard = '/advertisers/:id/credit-card-info',
  AdvertiserPmp   = '/advertisers/:id/private-marketplace',
  AdvertiserPixel = '/advertisers/:id/pixel-info',
  AdvertiserTeamSettings = '/advertisers/:id/team-settings',
  AdvertiserCreditCardInfo = '/advertisers/:id/credit-card-info',
  AddAdvertiser = '/add-advertiser'
}

export const routesByPage = {
  'advertiser': [
    'AdvertiserTeams',
    'AdvertiserCard', 
    'AdvertiserPmp',
    'AdvertiserPixel',
    'AdvertiserTeamSettings',
    'AdvertiserCreditCardInfo' 
  ],
  'profile': ['Profile'],
  'addAdvertiser': ['AddAdvertiser']
} 

export enum NonAuthRoutesEnum {
  SignIn = '/',
  SignUp = '/signup',
  Auth = '/auth',
}

// Routes -->
const Routes: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path={NonAuthRoutesEnum.SignIn} exact>
          <SignIn />
        </Route>
        <Route path={NonAuthRoutesEnum.SignUp} exact>
          <SignUp />
        </Route>
        <Route path={NonAuthRoutesEnum.Auth} exact>
          <PublicSignIn />
        </Route>

        <PrivateRoute
          path={AuthRoutesEnum.Dashboard}
          Component={Dashboard}
          exact
        />
        {/* TODO add reroute if the person ends up on /advertisers to team-settings */}
        <PrivateRoute path={AuthRoutesEnum.Profile} Component={ProfileV2} exact={true} />
        <PrivateRoute path={AuthRoutesEnum.AdvertiserTeamSettings} Component={Advertisers} exact={false} />
        <PrivateRoute path={AuthRoutesEnum.AdvertiserCreditCardInfo} Component={Advertisers} exact={false} />
        <PrivateRoute path={AuthRoutesEnum.AdvertiserPmp} Component={Advertisers} exact={false} />
        <PrivateRoute path={AuthRoutesEnum.AdvertiserPixel} Component={Advertisers} exact={false} />
        <PrivateRoute path={AuthRoutesEnum.AdvertiserPixel} Component={Advertisers} exact={false} />
        <PrivateRoute path={AuthRoutesEnum.AddAdvertiser} Component={AddAdvertiser} exact={true} />
        <PrivateRoute
          path={AuthRoutesEnum.Information}
          Component={Information2}
          exact
        />
        {/* <PrivateRoute
          path={AuthRoutesEnum.Information2}
          Component={Information2}
          exact
        /> */}
      </Switch>
    </>
  );
};
export default Routes;
