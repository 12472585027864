import { Step, StepLabel, Stepper } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

// File global variables and functions
const arrow = '/images/arrow.svg';

type TCampFormStepperProps = { step: number, steps: string[] };

// Main Component Function
const CampFormStepper: React.FC<TCampFormStepperProps> = function ({step, steps}) {
  const history = useHistory();
  const campName = useSelector((state: any) => state?.campaigns?.campaign?.name) || '';

  // Handler Methods
  const onBackBtnClicked = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    history.goBack();
  };
  

  return (
    <div className="stepper-wrapper">
      <div className="back-wrapper">
        <div className="back" onClick={onBackBtnClicked}>
          <img src={arrow} />
          <span>Back to Dashboard</span>
        </div>
        {campName && <span className="back__campName"> ({campName})</span>}
      </div>

      <Stepper activeStep={step}>
        {steps.map((label, index) => {
          // const stepProps: { completed?: boolean } = {};
          // const labelProps: { optional?: React.ReactNode } = {};
          return (
            <Step key={label}>
              <StepLabel className="step-label">{label}</StepLabel>
              {index < 2 && (
                <span className="step-divider">- - - - - - - - -</span>
              )}
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};
export default CampFormStepper;
