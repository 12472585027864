import { RadioGroup, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import React from 'react';
import AHDatePicker from '../../common/AHDatePicker/AHDatePicker';
import AHInputBox from '../../common/AHInputBox';
import AHMenu from '../../common/AHMenu/AHMenu';
import AHRadio from '../../common/AHRadio/AHRadio';
import './optimization.scss';
import { changeCampaignValue } from '../../../store/actions/campaignActions';
import { useDispatch, useSelector } from 'react-redux';
import { getRemainingBudget } from '../../../store/actions/dashboardActions';
import CPMComponent from './components/CPMComponent/CPMComponent';
import FreqCapComponent from './components/FreqCapComponent/FreqCapComponent';
import types from '../../../store/actions/types';
import {
  CampaignTypeEnum,
  BugetTypeEnum,
  PartialFreqCampEnum,
} from 'constants/enums';
import PacingComponent from './components/PacingComponent';

const OptimizationForm: React.FC = function () {
  const campaign = useSelector((state: any) => state.campaigns?.campaign);
  const duplicate = useSelector((state: any) => state?.campaigns?.duplicate);
  const dispatch = useDispatch();
  const editMode = useSelector((state: any) => state?.campaigns?.editMode);
  const admin = useSelector((state: any) => state?.user?.admin);
  type TcampType = CampaignTypeEnum.PERFORMANCE | CampaignTypeEnum.IMPRESSION;
  const [checkAuto, setCheckState] = React.useState(
    campaign?.auto_optimize_cpm
  );
  const campaignType = useSelector(
    (state: any) => state?.campaigns?.campaignType
  );

  const isBudgetAdornment =
    campaign.budget_type === BugetTypeEnum.SPEND ||
    campaign.budget_type === BugetTypeEnum.CPA
      ? true
      : false;

  const getKpiAdornment = () => {
    const kpiType = campaign.kpi_type;
    if (kpiType === 'reach') {
      return '';
    }
    if (kpiType === 'cac') {
      return '$';
    }
    if (kpiType === 'roas') {
      return '%';
    }
    return '';
  };

  const handleDisableMaxCpm = (): boolean => {
    if (checkAuto) {
      return true;
    }

    if (campaign?.budget_type === BugetTypeEnum.CPA) {
      if (admin) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  };

  const getKPIOptions = () => {
    if (campaignType === CampaignTypeEnum.IMPRESSION) {
      return [
        { data: 'purchase', label: 'Purchase' },
        { data: 'form completion', label: 'Form Completion' },
        { data: 'website visit', label: 'Website Visit' },
        { data: 'reach', label: 'REACH' },
        { data: 'roas', label: 'ROAS' },
        { data: 'cac', label: 'CAC' },
      ];
    }
    if (campaignType === CampaignTypeEnum.PERFORMANCE) {
      return [
        { data: 'purchase', label: 'Purchase' },
        { data: 'form completion', label: 'Form Completion' },
        { data: 'website visit', label: 'Website Visit' },
      ];
    }
  };

  const handleChangeCampType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = (e.target as HTMLInputElement).value;
    let value: TcampType;
    if (targetValue === CampaignTypeEnum.IMPRESSION) {
      value = CampaignTypeEnum.IMPRESSION;
      dispatch(
        changeCampaignValue({
          name: 'budget_type',
          data: '',
        })
      );
    }
    if (targetValue === CampaignTypeEnum.PERFORMANCE) {
      value = CampaignTypeEnum.PERFORMANCE;
      dispatch(
        changeCampaignValue({
          name: 'budget_type',
          data: BugetTypeEnum.CPA,
        })
      );
      dispatch(
        changeCampaignValue({
          name: 'pacing',
          data: '1',
        })
      );
    }

    dispatch({
      type: types.SET_CAMPAIGN_TYPE,
      payload: value,
    });

    dispatch(changeCampaignValue({ name: 'kpi_type', data: '' }));
  };

  const handleCheckAutoCPM = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckState(event.target.checked);
    dispatch(
      changeCampaignValue({
        name: 'auto_optimize_cpm',
        data: event.target.checked,
      })
    );
  };

  const handleDisableAutoCpm = (): boolean => {
    if (campaign?.budget_type === BugetTypeEnum.CPA) {
      if (admin) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  };

  const handlFreqInterChange = (
    index: number,
    type: string,
    value: number | string
  ) => {
    console.log({ index, type, value });
    if (type === PartialFreqCampEnum.Type) {
      dispatch({
        type: types.UPDATE_PARTIAL_FREQCAP,
        payload: { index, type, value },
      });
    } else {
      if (value > 0 || value === '') {
        dispatch({
          type: types.UPDATE_PARTIAL_FREQCAP,
          payload: { index, type, value },
        });
      }
    }
  };

  const handleAddFreqCap = () => {
    dispatch({
      type: types.ADD_NEW_PARTIAL_FREQCAP,
    });
  };

  const handleDeleteFreqCap = (index: number) => {
    dispatch({
      type: types.DELETE_PARTIAL_FREQCAP,
      payload: index,
    });
  };

  return (
    <div className="optimization">
      <div className="optimization-title" data-testid="opti-title">
        Basic Information & KPI Optimizations
      </div>
      {/***************** Campaign Name ***************** */}
      <div className="campForm" data-testid="campaign-form">
        <div className="campForm-wrapper">
          <div className="campForm-itembox">
            <div className="campForm-input">
              <div className="campForm-input-label">Сampaign Name *</div>
              <AHInputBox
                testID="campaign-name"
                type="text"
                placeholder=""
                value={campaign?.name}
                name="campaign-name"
                handleChange={(e) => {
                  dispatch(
                    changeCampaignValue({ name: 'name', data: e.target.value })
                  );
                }}
              />
            </div>
          </div>

          {/********************* Campaign Type *************** */}
          <div className="campForm-itembox">
            <div className="campForm-input-wrapper">
              <div className="campForm-label">Campaign Type *</div>
              <FormControl
                disabled={editMode}
                component="fieldset"
                data-testid="campaign-type"
              >
                <RadioGroup
                  row
                  aria-label="campaign-type"
                  name="controlled-radio-buttons-group"
                  className="custom-radio"
                  value={campaignType}
                  onChange={handleChangeCampType}
                >
                  <FormControlLabel
                    className="radio-option"
                    value={CampaignTypeEnum.PERFORMANCE}
                    control={<AHRadio />}
                    label={
                      <Typography style={{ fontWeight: 'bold' }}>
                        Pay For Performance
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    className="radio-option"
                    value={CampaignTypeEnum.IMPRESSION}
                    control={<AHRadio />}
                    label={
                      <Typography style={{ fontWeight: 'bold' }}>
                        Pay Per Impression
                      </Typography>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          {/* *********** Start & End Date ********** */}
          <div className="campForm-double-itembox">
            <div className="item-in-double">
              <AHDatePicker
                dataTestId="start-date"
                label="Start Date *"
                name="start-date"
                type="start"
                required
                placeholder="From"
              />
            </div>
            <div className="item-in-double">
              <AHDatePicker
                dataTestId="end-date"
                label="End Date *"
                name="end-date"
                type="end"
                required
                placeholder="From"
              />
            </div>
          </div>
          {/* *********** Budget & Budget Type ********** */}
          <div className="campForm-double-itembox">
            <div className="item-in-double">
              <div className="campForm-input-label">Budget *</div>
              <AHInputBox
                testID="campaign-budget"
                type="number"
                value={campaign?.budget}
                placeholder=""
                name=""
                handleChange={(e) => {
                  Number(e.target.value) >= 0 &&
                    dispatch(
                      changeCampaignValue({
                        name: 'budget',
                        data: e.target.value,
                      })
                    );
                }}
                adornmentPosition="start"
                adornmentType={isBudgetAdornment ? 'icon' : 'other'}
                adornment={isBudgetAdornment ? <AttachMoneyIcon /> : ''}
              />
              {editMode && (
                <span className="remaining-budget">
                  Budget Remaining: $
                  {dispatch(
                    getRemainingBudget({
                      campId: campaign?.id,
                      budgetType: campaign?.budget_type,
                      budget: campaign?.budget,
                    })
                  )}
                </span>
              )}
            </div>
            {campaignType !== CampaignTypeEnum.PERFORMANCE && (
              <div className="item-in-double" data-testid="budget-type">
                <div className="campForm-input-label">Budget Type</div>
                <AHMenu
                  value={campaign?.budget_type}
                  disabled={editMode && !duplicate}
                  options={[
                    { data: 'spend', label: 'Spend' },
                    { data: 'impressions', label: 'Impressions' },
                    {
                      data: 'completed_audio_ads',
                      label: 'Completed Audio Ads',
                    },
                  ]}
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    dispatch(
                      changeCampaignValue({
                        name: 'budget_type',
                        data: e.target.value,
                      })
                    );
                  }}
                />
              </div>
            )}
          </div>

          {/* *********** CPM & Auto Optimized CPM ********** */}
          {campaignType !== CampaignTypeEnum.PERFORMANCE && (
            <CPMComponent
              budgetType={campaign?.budget_type}
              handleDisableMaxCpm={handleDisableMaxCpm}
              changeCampaignValue={changeCampaignValue}
              onCheckStateChange={handleCheckAutoCPM}
              handleDisableAutoCpm={handleDisableAutoCpm}
            />
          )}
          {/******************** Pacing ********************* */}
          {campaignType !== CampaignTypeEnum.PERFORMANCE && (
            <PacingComponent
              pacing={campaign.pacing}
              changeCampaignValue={changeCampaignValue}
            />
          )}

          {/* *********** KPI & KPI Value ********** */}
          <div className="campForm-double-itembox">
            <div className="item-in-double">
              <div className="campForm-input-label">
                {campaignType === CampaignTypeEnum.PERFORMANCE
                  ? 'Desired Action*'
                  : 'KPI*'}
              </div>
              <AHMenu
                value={campaign.kpi_type}
                options={getKPIOptions()}
                handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  dispatch(
                    changeCampaignValue({
                      name: 'kpi_type',
                      data: event.target.value,
                    })
                  );
                }}
              />
            </div>
            <div className="item-in-double">
              <div className="campForm-input-label">
                {campaignType === CampaignTypeEnum.PERFORMANCE
                  ? 'Max Cost Per Action*'
                  : 'Value*'}
              </div>
              <AHInputBox
                testID="campaign-kpi"
                value={campaign.kpi_value}
                type="number"
                placeholder=""
                name=""
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  dispatch(
                    changeCampaignValue({
                      name: 'kpi_value',
                      data: e.target.value,
                    })
                  );
                }}
                adornmentPosition={
                  campaign.kpi_type === 'cac' ? 'start' : 'end'
                }
                adornmentType="other"
                adornment={getKpiAdornment()}
              />
            </div>
          </div>

          {/* *********** Freq Cap Stuff ********** */}
          {/**
           * when campaign type is pfp do not show freqcap component
           * but show if the user is admin even if campaign type is pfp
           */}
          {(admin || campaignType !== CampaignTypeEnum.PERFORMANCE) && (
            <FreqCapComponent
              budgetType={campaign?.budget_type}
              handlFreqInterChange={handlFreqInterChange}
              handleAddFreqCap={handleAddFreqCap}
              handleDeleteFreqCap={handleDeleteFreqCap}
            />
          )}

          {/* // end of fields  */}
        </div>
      </div>
    </div>
  );
};
export default OptimizationForm;
