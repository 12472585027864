import React from 'react'
import BeatLoader from "react-spinners/BeatLoader";

interface IProps {
  onClick?: any
  label?: String
  appendClasses?: String
  isLoading?: Boolean
  isRounded?: Boolean
  hasShadow?: Boolean
  isSelected?: Boolean
  selectedClasses?: String
  id?: string
  useChild?: Boolean
  children?: any
}

const Button = ({ children, id, label, isLoading = false, appendClasses = "", isRounded = true, onClick = () => {}, hasShadow = false, isSelected = false, selectedClasses, useChild = false }: IProps) => { 


  const Loader = () => {
    return (
      <span>{isLoading && <div className="absolute" ><BeatLoader  /></div>}<span className={isLoading ? "invisible" : ""}>{label}</span></span>
    )
  }

  const normalAppend = "bg-cyan-200 hover:bg-cyan-300 focus:ring-cyan-100"
  const rounded = isRounded ? "rounded-md" : ""
  const shadow = hasShadow ? "shadow-sm" : ""
  const selectedClassesCompute = isSelected ? selectedClasses : ""
  const normalClasses = "inline-flex items-center px-4 py-2 border border-transparent font-medium focus:outline-none focus:ring-2 focus:ring-offset-2"
  const buttonClasses = normalClasses + " " + shadow + " " + rounded + " " + appendClasses + " " + selectedClassesCompute

  return (
    <>
    <button
      id={id}
      onClick={onClick}
      type="submit"
      className={buttonClasses}
    >
      {!useChild && <Loader />}
      {useChild && children}
    </button>
    </>
  )

}
export default Button;