import axios from 'axios';
import jwt_decode from 'jwt-decode';

// SETTING UP DOMAIN
let domain: string = '';
let is_dev: boolean = false

if (window.location.hostname === 'app.audiohook.com') {
  if (window.location.protocol === "http:") {
    window.location.href = 'https://app.audiohook.com';
  }
  domain = 'https://api.audiohook.com';
}
else if (window.location.hostname === 'dsp.audiohook.com') {
  if (window.location.protocol === "http:") {
    window.location.href = 'https://dsp.audiohook.com';
  }
  domain = 'https://api.audiohook.com';
}
else if (window.location.hostname === "preproduction.audiohook.com") {
  if (window.location.protocol === "http:") {
    window.location.href = 'https://preproduction.audiohook.com';
  }
  domain = 'https://audiohook-api-preproduction.herokuapp.com/';
}
else if (window.location.hostname === "localhost") {
  domain = 'https://stage.api.audiohook.com';
}
else {
  if (window.location.protocol === "http:" && !is_dev) {
    window.location.href = 'https://stage.app.audiohook.com';
  }
  domain = 'https://stage.api.audiohook.com';
}

if (is_dev) domain = 'http://127.0.0.1:5050'

export const baseUrl: string = domain;

// SETTING UP STRIPE KEY
export var STRIPE_KEY: string = "";

if(window.location.hostname === "localhost") {
  STRIPE_KEY = 'pk_test_2vuL2jfWZVyYPU1VtGh3MSz300QoMMkIuH';
}
else if(window.location.hostname === 'app.audiohook.com') {
  STRIPE_KEY = 'pk_live_tyNscO3nLogHTsFtr1hbIQIc00m4UF8XOz';
}
else if (window.location.hostname === "preproduction.audiohook.com" ){
  STRIPE_KEY = 'pk_test_2vuL2jfWZVyYPU1VtGh3MSz300QoMMkIuH'
}
else {
  STRIPE_KEY = 'pk_test_2vuL2jfWZVyYPU1VtGh3MSz300QoMMkIuH'
}

// export const API = axios.create({
//   baseURL: baseUrl,
// });



// const getDecodedAccessToken = (token: string): any => {
//   try {
//     return jwt_decode(token);
//   } catch (Error) {
//     return null;
//   }
// };

// export const getExpirationDate = (token: string) => {
//   if (!token) {
//     return null;
//   }

//   const expDate = getDecodedAccessToken(token)?.exp;
//   return expDate * 1000;
// };

// const isExpired = (exp: number) => {
//   if (exp) {
//     return Date.now() > exp;
//   }
//   return false;
// };

// export const setTokenToAxios = (token: string) => {
//   API.interceptors.request.use(
//     (config) => {
//       config.headers['x-access-token'] = token;
//       return config;
//     },
//     (error) => {
//       return Promise.reject(error);
//     }
//   );
// };



// export const getToken = () => {
//   const token = localStorage.getItem('token');
//   if (!token) {
//     return null;
//   }
//   if (isExpired(getExpirationDate(token))) {
//     return null;
//   }
//   setTokenToAxios(token);
//   return token;
// };


// const renewToken = () => {
//   const token = getToken();

//   let updatedToken: string = '';
//   axios.get(`${baseUrl}/renew-token`, {
//     headers: {
//       'x-access-token': token,
//     },
//   })
//     .then(res => {
//       if (res.status === 200 && res?.data?.success) {
//         console.log(res)
//         updatedToken = res?.data?.token;
//         setToken(updatedToken)
//         setTokenToAxios(updatedToken);
//       }
//     })
//     .catch(e => console.error(e))
// }

// const runRenewToken = setInterval(renewToken, 300000);

// export function stopRenewToken() {
//   clearInterval(runRenewToken)
// }
