import React, { useState } from 'react';
import BillingSettings from '../../components/ProfileContents/BillingSettings';
import CreditCardInfo from '../../components/ProfileContents/CreditCardInfo';
import PersonalInfo from '../../components/ProfileContents/PersonalInfo';
import TeamSettings from '../../components/AdvertiserContents/TeamSettings';
import WithSideNavLayoutV2 from '../../layout/WithSideNavLayout';

const getPage = (name: string) => {
  if (name === 'personal-info') {
    return <PersonalInfo />;
  } else if (name === 'team-settings') {
    return <TeamSettings />;
  } else if (name === 'billing-settings') {
    return <BillingSettings />;
  } else if (name === 'credit-card-info') {
    return <CreditCardInfo />;
  } else {
    return <PersonalInfo />;
  }
};

const Profile = function () {
  const [page, setPage] = useState('personal-info');

  const handleSetPageContent = (name: string) => {
    setPage(name);
  };
  

  return (
    <div style={{ background: '#F2F2F2' }}>
      <WithSideNavLayoutV2 handleClick={handleSetPageContent}>
        {getPage(page)}
      </WithSideNavLayoutV2>
    </div>
  );
};
export default Profile;
