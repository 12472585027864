// import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

const useAdvertiserUuid = () => {
  const { advertiserId, advertisers } = useSelector((state: any) => state?.user ?? {});

  const foundAdvertiser = advertisers.find((advertiser) => {
    return advertiser.id === advertiserId
  })
  const uuid = foundAdvertiser.uuid

  return uuid
}

export default useAdvertiserUuid;