import API from 'services/api';
import { baseUrl } from 'config';
import { ICampaigns } from 'models/campaign';
import types from '../types';

export const getCampaigns =
  (enqueueSnackbar, closeSnackbar) => (dispatch, getState) => {
    const id = getState().user?.advertiserId;
    const admin = getState().user?.admin;
    API.get(`${baseUrl}/${id}/campaign`)
      .then((res) => {
        console.log(res);
        if (res.status === 200 && res.data.success) {
          let campaigns: ICampaigns = res.data.data;

          // store campaigns
          dispatch({
            type: types.STORE_CAMPAIGN_DATA,
            payload: campaigns,
          });
        } else {
          enqueueSnackbar(
            `Load campaigns could not be processed.${res.data.message}`,
            { variant: 'error' }
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error?.response?.data?.success === false) {
            if (admin)
              enqueueSnackbar(
                `Load campaigns could not be processed. ${error?.response?.data?.message}`,
                {
                  variant: 'error',
                }
              );
          } else {
            if (admin)
              enqueueSnackbar(
                'Something went wrong while loading campaign data!'
              );
          }
        } else if (error.request) {
          console.error(error.request);
        } else {
          enqueueSnackbar('Unknown Error!', {
            variant: 'error',
          });
        }
      });
  };
