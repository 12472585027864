import { CircularProgress } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import * as filestack from 'filestack-js';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { DropzoneEnum } from 'constants/enums';
import { removeCreative } from 'store/actions/campaignActions';
import { getAssociatedCreatives } from 'store/actions/campaign/campaignAssociatedCreatives';
import types from 'store/actions/types';
import { getCreative } from 'utils/fileHandler';
import FileDetails from '../file-details';
import './style.scss';

const client = filestack.init('AkNpgNoylTfquSG4YdAHkz');

const DownloadIcon = '/images/download-icon.svg';

const getFormattedFileSize = (bytes: number, decimals = 2) => {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const kb = 1024;
  const i = Math.floor(Math.log(bytes) / Math.log(kb));
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  return `${(bytes / kb ** i).toFixed(decimals)} ${sizes[i]}`;
};

interface IFile {
  name: string;
  size: number;
  type: string;
}

interface IMyDropzone {
  type: string;
  showCreateAd?: boolean;
  acceptedFileType: string;
  getFile: Function;
}

const MyDropzone: React.FC<IMyDropzone> = ({
  type,
  showCreateAd = false,
  getFile,
  acceptedFileType,
}) => {
  // local state
  const [isDeleting, setIsDeleting] = useState(false);
  const [fileId, setFileId] = useState(Infinity);
  const [fileDropped, setFileDropped] = useState(false);
  const [addNewFile, setAddNewFile] = useState(false);
  const [acceptedFile, setAcceptedFile] = useState<IFile>({
    name: '',
    size: 0,
    type: '',
  });

  // data from redux
  const advertiserId = useSelector((state: any) => state?.user?.advertiserId);
  const campName: string = useSelector(
    (state: any) => state?.campaigns?.campaign?.name
  );
  const creatives = useSelector(
    (state: any) => state?.campaigns?.campaign?.creative
  );
  const isFileUploading = useSelector(
    (state: any) => state.campaigns.fileUploading
  );

  const list_url = useSelector(
    (state: any) => state?.campaigns?.campaign?.list_url
  );

  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // Effect Hook
  React.useEffect(() => {
    if (type === 'creative') {
      dispatch(getAssociatedCreatives(setFileDropped, enqueueSnackbar));
    }
  }, []);

  // local functions
  const onDrop = useCallback((acceptedFiles: any[]) => {
    if (type === 'email_list') {
      dispatch({ type: types.SET_FILE_UPLOADING, payload: true });
      setAcceptedFile(acceptedFiles[0]);

      client
        .upload(acceptedFiles[0])
        .then((data) => {
          dispatch({
            type: types.SET_EMAIL_LIST,
            payload: data.url,
          });

          dispatch({ type: types.SET_FILE_UPLOADING, payload: false });
          setFileDropped(true);
          setAddNewFile(false);
        })
        .catch((err) => {
          console.error(err);
          enqueueSnackbar('Failed to upload email list!', {
            variant: 'error',
          });
          dispatch({ type: types.SET_FILE_UPLOADING, payload: false });
        });
    } else if (type === 'creative') {
      const audio = new Audio(URL.createObjectURL(acceptedFiles[0]));
      audio.setAttribute('preload', 'metadata');
      audio.onloadedmetadata = function () {
        if (![15, 30].includes(~~audio.duration)) {
          enqueueSnackbar(
            'This is not a standard duration (15 sec or 30 sec) and will effect how many ads the campaign will deliver!',
            {
              variant: 'warning',
            }
          );
        }
        if (~~audio.duration <= 90) {
          // start file uploader loader
          dispatch({ type: types.SET_FILE_UPLOADING, payload: true });

          //upload file to fileStack
          client
            .upload(acceptedFiles[0])
            .then((data) => {
              dispatch(
                getCreative({
                  fileUrl: data.url,
                  fileName: data._file.name,
                  file: acceptedFiles[0],
                  getFile,
                  advertiserId,
                  setAddNewFile,
                  setFileDropped,
                  enqueueSnackbar,
                })
              );
            })
            .catch((err) => {
              console.error(err);
              enqueueSnackbar('Failed to upload creative!', {
                variant: 'error',
              });
              dispatch({ type: types.SET_FILE_UPLOADING, payload: false });
            });
        }
      };
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    onDrop,
    accept: acceptedFileType,
  });
  const { name, size } = acceptedFile;

  // Delete a Creative file
  const handleResetClick = async (id: number) => {
    setFileId(id);
    setIsDeleting(true);

    dispatch({
      type: types.SET_DELETE_CREATIVE,
      payload: id,
    });
    dispatch(removeCreative(id));
    setIsDeleting(false);
  };

  const handleAddNewCreative = () => {
    setAddNewFile(true);
  };

  const handleClearList = () => {
    getFile('');
    // setAddNewFile();
    setFileDropped(false);
  };

  React.useEffect(() => {
    if (type === 'email_list') {
      if (list_url) {
        setFileDropped(true);
      } else {
        setFileDropped(false);
      }
    }
    if (type === 'creative') {
      if (creatives.length > 0) {
        setFileDropped(true);
      } else {
        setFileDropped(false);
      }
    }
  }, [list_url, creatives]);

  // const handleIntercomClick = (e) => {
  //   e.stopPropagation();
  //   const myCustomMessage = `Hi, I would like help creating a audio ad for my campaign "${campName}"`;
  //   //@ts-ignore
  //   window.Intercom('showNewMessage', myCustomMessage);
  // };

  const showFiles =
    type === 'creative' ? (
      <div className="dropped-files-screen">
        {creatives.map((item) => {
          return (
            <FileDetails
              type="creative"
              key={item?.id}
              creative={item}
              onResetClick={handleResetClick}
              name={item.hasOwnProperty('filehost') ? item.name : item.filename}
              size={
                item.hasOwnProperty('filehost')
                  ? getFormattedFileSize(item.size)
                  : ''
              }
              isDeleting={isDeleting}
              id={fileId}
            />
          );
        })}

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {isFileUploading && (
            <CircularProgress size={20} style={{ marginTop: '20px' }} />
          )}

          <span
            // onClick={handleIntercomClick}
            id="intercom"
            className="dropped-files-screen__help"
          >
            Need help with a Creative ?
          </span>

          {showCreateAd && (
            <button
              type="button"
              onClick={handleAddNewCreative}
              className="dropped-files-screen__add"
            >
              + Create New Ad
            </button>
          )}
        </div>
      </div>
    ) : (
      <div className="email-list-area">
        <FileDetails
          type="email"
          key=""
          onResetClick={handleResetClick}
          name={name}
          size={getFormattedFileSize(size)}
          isDeleting={isDeleting}
          id={fileId}
        />
        <CancelIcon
          className="email-list-cancel-icon"
          onClick={handleClearList}
        />
      </div>
    );

  return (
    <>
      {fileDropped && !addNewFile ? (
        showFiles
      ) : (
        <div {...getRootProps()} className="dropzone">
          <input {...getInputProps()} />
          <img src={DownloadIcon} alt="" />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <div>
              <p className="dropzone__text">
                Drag & Drop or <span>Browse</span>
              </p>
              {type === DropzoneEnum.Creative && (
                <>
                  <p
                    className="dropzone__additional-text"
                    style={{ cursor: 'pointer' }}
                  >
                    Need help with a Creative?{' '}
                    <span className="dropzone__additional-link">
                      We're here to help
                    </span>
                  </p>
                  <p
                    style={{
                      marginTop: '45px',
                      fontSize: '12px',
                      color: '#b3b9c2',
                    }}
                  >
                    Creative may take up to 10 minutes to be fully processed.
                  </p>
                </>
              )}
            </div>
          )}
          {isFileUploading && (
            <CircularProgress size={20} style={{ marginTop: '20px' }} />
          )}
        </div>
      )}
    </>
  );
};

export default MyDropzone;
