import Axios from 'axios';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { baseUrl } from '../../config';
import { AuthRoutesEnum } from '../../Routing/Routes';
import { setToken } from '../../services/tokenService';
import types from '../../store/actions/types';

interface IApiRes {
  advertiser_id: number;
  advertiser_name: string;
  created_at: number;
  first_name: string;
  last_name: string;
  message: string;
  success: boolean;
  token: string;
  user_id: number;
  admin: boolean;
}

const PublicSignIn = function (): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { email, api_key } = queryString.parse(location.search);
  const [error, setError] = useState('');

  useEffect(() => {
    Axios.get(`${baseUrl}/auth`, {
      params: {
        email,
        api_key,
      },
    })
      .then((res) => {
        console.log(res);
        const data: IApiRes = res.data;
        if (res.status === 200) {
          dispatch({
            type: types.SET_USER,
            payload: {
              advertiserId: data?.advertiser_id,
              advertiserName: data?.advertiser_name,
              firstName: data?.first_name,
              lastName: data?.last_name,
              createdAt: data?.created_at,
              id: data?.user_id,
              admin: data?.admin,
              email,
            },
          });
        }
        
        setToken(data.token);
        history.push(AuthRoutesEnum.Dashboard);
      })
      .catch((error) => {
        console.error(error);
        setError(error?.response?.data?.message);
      });
  }, [email, api_key]);

  return (
    <>
      {error && <h4 style={{ color: 'red', textAlign: 'center' }}>{error}</h4>}
    </>
  );
};
export default PublicSignIn;
