import axios from 'axios';
import { baseUrl } from '../config';
import { reduxStore } from '../store';
import { getToken } from './tokenService';
import { logoutAction } from '../store/actions/userActions';

const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: {
        "Content-Type": "application/json",
    },
})

axiosInstance.interceptors.request.use(
    (config) => {
        const token = getToken();
        if (token) {
            config.headers['x-access-token'] = token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        console.log({err});
        const originalConfig = err.config;

        if ( err.response ) {
            // Access Token was expired
            if (err.response.status === 401) {
                setTimeout(() => {
                    //@ts-ignore
                    reduxStore.dispatch(logoutAction());
                }, 3000);
            }
        }
        return Promise.reject(err);
    }
);

export default axiosInstance;
