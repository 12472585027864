import React from 'react';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import AHRadio from 'components/common/AHRadio/AHRadio';
import { useDispatch } from 'react-redux';
import { IChangeCampValue } from 'store/actions/actions.interface';

interface Props {
  changeCampaignValue: (value: IChangeCampValue) => void;
  pacing: number;
}

const PacingComponent = ({ changeCampaignValue, pacing }: Props) => {
  const dispatch = useDispatch();
  return (
    <div className="campForm-itembox">
      <div className="campForm-input-wrapper">
        <div className="campForm-label">Pacing Strategy</div>
        <FormControl style={{ width: '90%' }} component="fieldset">
          <RadioGroup
            row
            aria-label="gender"
            name="controlled-radio-buttons-group"
            className="custom-radio"
            value={pacing + ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch(
                changeCampaignValue({
                  name: 'pacing',
                  data: e.target.value,
                })
              );
            }}
          >
            <FormControlLabel
              className="radio-option"
              value="1"
              control={<AHRadio />}
              label={
                <Typography style={{ fontWeight: 'bold' }}>Evenly</Typography>
              }
            />
            <FormControlLabel
              className="radio-option"
              value="2"
              control={<AHRadio />}
              label={
                <Typography style={{ fontWeight: 'bold' }}>
                  As Fast As Possible
                </Typography>
              }
            />
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
};

export default PacingComponent;
