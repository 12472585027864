import types from '../actions/types';

const initialState = {
  reporting: {
    daily: [],
    total: [],
    historical: [],
    attributed_sessions: [],
    loading: false
  },
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_REPORT_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case types.GET_REPORTING_DATA:
      return {
        ...state,
        reporting: {
          ...state.reporting,
          ...action.payload,
        },
      };
    case types.SIGN_OUT:
      return state;
    default:
      return state;
  }
};
export { dashboardReducer };

