
import React, { useEffect } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Skeleton } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DEFAULT_FREQ_CAP } from 'constants/defaults';
import { CampaignTypeEnum } from 'constants/enums';
import { ICampaignTableData } from 'models';
import { AuthRoutesEnum } from 'Routing/Routes';
import { IFreqCap, IPartialFreqCap } from 'models/freqCap';
import {
  downloadCampaignData,
  downloadAggregateData,
  getAndEditCampaign,
} from 'store/actions/campaignActions';
import { submitCampaignStatusChange } from 'store/actions/campaign/campaignStatusChangeAction';
import types from 'store/actions/types';
import { getDate, getDaysDifference } from 'utils/commonFns';
import ddh from 'utils/DashboadDataHandler';
import {
  formatIntervalInHours,
  formatIntervalInSeconds,
} from '../../utils/freqCap';
import './style.scss';
import StatusComponent from './StatusComponent';
import { isFreqCapsDefault } from 'utils/freqCap';

import useDebug from '../../hooks/useDebug'

export interface IPropsForCampaignTable {
  tableData: ICampaignTableData;
  getCampaignImpression: Function;
  getCampaignBudgetSpent: Function;
  dateRange: any;
  getRoas: Function;
  handleSelectRow: Function;
  selected: any;
}

const CampaignTable: React.FC<IPropsForCampaignTable> = ({
  tableData,
  getCampaignImpression,
  getCampaignBudgetSpent,
  dateRange,
  getRoas,
  handleSelectRow,
  selected,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isDebugMode = useDebug();

  const advId = useSelector((state: any) => state?.user?.advertiserId);
  const reportLoading = useSelector((state: any) => state?.dashboard?.loading);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isDebugMode) console.log('is debug mode ', isDebugMode)
  }, [isDebugMode]) 

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>, row) => {
    setSelectedRow(row);
    console.log(row);
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setSelectedRow(null);
    setAnchorEl(null);
  };

  // ********** ADD NEW CAMPAIGN *************
  // ********** ADD NEW CAMPAIGN *************
  // ********** ADD NEW CAMPAIGN *************
  const onAdd = () => {
    window.analytics.track('Clicked on Create New Campaign button', {});

    let freqCaps: Array<IFreqCap> = [];
    let partialFreqCaps: Array<IPartialFreqCap> = [];

    freqCaps = [...DEFAULT_FREQ_CAP];

    freqCaps.forEach((fc: IFreqCap) => {
      const freqCapObj = formatIntervalInHours(fc);
      partialFreqCaps = [...partialFreqCaps, freqCapObj];
    });

    dispatch({
      type: types.SET_DEFAULT_FREQCAP,
      payload: true,
    });

    dispatch({
      type: types.STORE_PARTIAL_FREQCAP,
      payload: partialFreqCaps,
    });

    dispatch({ type: types.CLEAR_EDIT_CAMPAIGN });
    history.push(AuthRoutesEnum.Information);
  };

  // ********** EDIT CAMPAIGN *************
  // ********** EDIT CAMPAIGN *************
  // ********** EDIT CAMPAIGN *************
  const onEdit = () => {
    window.analytics.track('Clicked on Edit Campaign button', {});

    // set campagin type depending on budget type
    if (selectedRow.budget_type === 'cpa') {
      dispatch({
        type: types.SET_CAMPAIGN_TYPE,
        payload: CampaignTypeEnum.PERFORMANCE,
      });
    } else {
      dispatch({
        type: types.SET_CAMPAIGN_TYPE,
        payload: CampaignTypeEnum.IMPRESSION,
      });
    }

    // format freqcap and store to partial-freq-cap
    let freqCaps: Array<IFreqCap> = [];
    let partialFreqCaps: Array<IPartialFreqCap> = [];

    freqCaps =
      selectedRow.hasOwnProperty('frequency_cap') &&
      selectedRow.frequency_cap.length > 0
        ? [...selectedRow.frequency_cap]
        : [];
    // console.log(selectedRow.frequency_cap);
    if (selectedRow.hasOwnProperty('freq_version')) {
      freqCaps.forEach((fc: IFreqCap) => {
        const freqCapObj = formatIntervalInSeconds(fc);
        partialFreqCaps = [...partialFreqCaps, freqCapObj];
      });
    } else {
      freqCaps.forEach((fc: IFreqCap) => {
        const freqCapObj = formatIntervalInHours(fc);
        partialFreqCaps = [...partialFreqCaps, freqCapObj];
      });
    }

    // const isIncomingFreqCapsDefault = (fcs) => {
    //   if (fcs.length !== 3) return false;

    //   while (fcs.length > 0) {
    //     const fc = fcs.pop();

    //     if (fc.frequency === 2) {
    //       if (fc.interval !== 86400) return false;
    //     } else if (fc.frequency === 4) {
    //       if (fc.interval !== 604800) return false;
    //     } else if (fc.frequency === 8) {
    //       if (fc.interval !== 2419200) return false;
    //     } else {
    //       return false;
    //     }
    //   }
    //   return true;
    // };

    dispatch({
      type: types.SET_DEFAULT_FREQCAP,
      payload: isFreqCapsDefault(freqCaps),
    });

    dispatch({
      type: types.STORE_PARTIAL_FREQCAP,
      payload: partialFreqCaps,
    });

    // store campaign to reducer
    dispatch(getAndEditCampaign(selectedRow, history));
  };

  // ****** change status *********
  // ****** change status *********
  // ****** change status *********
  const handleChangeStatus = (status: string) => {
    dispatch(
      submitCampaignStatusChange(selectedRow.id, status, enqueueSnackbar)
    );
  };

  // ****** Duplicate campaign *********
  // ****** Duplicate campaign *********
  // ****** Duplicate campaign *********
  const handleDuplicateCampaign = () => {
    dispatch({
      type: types.SET_DUPLICATE,
      payload: true,
    });

    let modifiedSelectedRow = {
      ...selectedRow,
      name: 'DUPLICATE - CHANGE ME',
      start_date: null,
      end_date: null,
    };
    delete modifiedSelectedRow['id'];

    // format freqcap and store to partial-freq-cap
    let freqCaps: Array<IFreqCap> = [];
    let partialFreqCaps: Array<IPartialFreqCap> = [];

    freqCaps =
      modifiedSelectedRow.hasOwnProperty('frequency_cap') &&
      modifiedSelectedRow.frequency_cap.length > 0
        ? [...modifiedSelectedRow.frequency_cap]
        : [...DEFAULT_FREQ_CAP];

    if (modifiedSelectedRow.hasOwnProperty('freq_version')) {
      freqCaps.forEach((fc: IFreqCap) => {
        const freqCapObj = formatIntervalInSeconds(fc);
        partialFreqCaps = [...partialFreqCaps, freqCapObj];
      });
    } else {
      freqCaps.forEach((fc: IFreqCap) => {
        const freqCapObj = formatIntervalInHours(fc);
        partialFreqCaps = [...partialFreqCaps, freqCapObj];
      });
    }

    dispatch({
      type: types.SET_DEFAULT_FREQCAP,
      payload: isFreqCapsDefault(freqCaps),
    });

    dispatch({
      type: types.STORE_PARTIAL_FREQCAP,
      payload: partialFreqCaps,
    });

    dispatch(getAndEditCampaign(modifiedSelectedRow, history));
  };

  const tableContentTemplate = tableData.map((item, id: number, index) => {
    // console.log(item);
    return (
      <tr
        className={selected ? (selected?.id === item.id ? 'selected' : '') : ''}
        key={`tr-${id}`}
        onClick={() => handleSelectRow(item)}
      >
        {isDebugMode && <td> 
          <pre>{item.id}</pre>
        </td>}
        <td>
          <div className="name-box-wrapper">
            <StatusComponent
              id={item.id}
              budgetType={item.budget_type}
              status={item.status}
              budget={item.budget}
              endDate={item.end_date}
            />
            {item.name}
          </div>
        </td>
        <td>
          {item.budget_type === 'spend' && '$'}
          {item.budget_type === 'cpa' && '$' }
          {ddh.numberWithCommas(item.budget)}
          {item.budget_type === 'impressions' && ' imp'}
          {item.budget_type === 'completed_audio_ads' && ' completed imp'}

        </td>
        <td>{getDaysDifference(item.start_date, item.end_date)} days</td>
        <td>{getDate(item.start_date)}</td>
        <td>{getDate(item.end_date)}</td>
        <td>
          {reportLoading ? (
            <Skeleton width={80} variant="text" />
          ) : (
            ddh.numberWithCommas(getCampaignImpression(item.id))
          )}
        </td>
        <td>
          {reportLoading ? (
            <Skeleton width={80} variant="text" />
          ) : (
            '$' +
            getCampaignBudgetSpent(
              item.id,
              item.budget_type,
              // item.budget,
              // item.status
            )
          )}
        </td>
        <td>
          {reportLoading ? (
            <Skeleton width={80} variant="text" />
          ) : (
            getRoas(item.id)
          )}
        </td>
        <td>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={(e) => handleClickMenu(e, item)}
          >
            <MoreVertIcon className="edit-icon" />
          </IconButton>
        </td>
      </tr>
    );
  });
  const header = [
    'Сampaign',
    'Budget',
    '',
    'Duration',
    '',
    'Impressions',
    'Spend',
    'ROAS',
    // <CSVGenerator
    //   startDate={dateRange[0]}
    //   endDate={dateRange[1]}
    //   id={advertiserId}
    // />,
  ];

  // ADD Campaign id column 
  if (isDebugMode) header.unshift('campn id') 

  const headerTemplate = header.map((item, id) => (
    <th key={`th-${id}`}>{item}</th>
  ));
  

  const handleDownloadVastTag = (campaignId) => {
    const downloadTxtFile = (txtContents: string) => {
      const element = document.createElement("a");
      const file = new Blob([txtContents], {type: 'text/plain'});
      element.href = URL.createObjectURL(file);
      element.download = "vast-wrapper.txt";
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    }

    const interpolatedVastTag = `https://vast.audiohook.com/v2/campaign/${campaignId}/wrapper.xml?cpm={{CPM}}&impression={{IMPRESSION_ID}}&pub={{PUBLISHER_ID}}&inventory={{INVENTORY_SOURCE}}&app={{APP_NAME}}&site={{SITE_NAME}}&pmp={{DEAL_ID}}`

    downloadTxtFile(interpolatedVastTag)
  }

  return (
    <div className="campaign shadow">
      <div className="campaign-wrapper">
        <div className="table-wrapper">
          <div className="tTop shadow">
            <button className="add" onClick={() => onAdd()}>
              <AddIcon className="add-icon" />
              Create New Campaign
            </button>
          </div>
          <table className="campaign-table">
            <thead>
              <tr>{headerTemplate}</tr>
            </thead>
            <tbody>{tableContentTemplate}</tbody>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={openMenu}
              onClose={handleCloseMenu}
            >
              {selectedRow?.status !== 0 && (
                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                    handleChangeStatus('0');
                  }}
                >
                  Pause Campaign
                </MenuItem>
              )}

              {selectedRow?.status === 0 && (
                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                    handleChangeStatus('1');
                  }}
                >
                  Resume Campaign
                </MenuItem>
              )}

              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  onEdit();
                }}
              >
                Edit Campaign
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  downloadCampaignData(
                    advId,
                    selectedRow.id,
                    // dateRange[-1],
                    // dateRange[-1],
                    enqueueSnackbar
                  );
                }}
              >
                Download Data
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  handleDownloadVastTag(
                    selectedRow.id,
                  );
                }}
              >
                Download Vast Tag
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  downloadAggregateData(
                    advId,
                    selectedRow.id,
                    // dateRange[-1],
                    // dateRange[-1],
                    enqueueSnackbar
                  );
                }}
              >
                Download Aggregate Data
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  handleDuplicateCampaign();
                }}
              >
                Duplicate Campaign
              </MenuItem>
            </Menu>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CampaignTable;
