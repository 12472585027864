import { useState, useEffect } from 'react';
import useViewport from './useViewport';

const useChartWidthFull = () => {
  const [chartWidth, setChartWidth] = useState(0);
  const { width } = useViewport();

  useEffect(() => {
    console.log('width full', width);
  }, [width])

  const mainMinus = 40

  useEffect(() => {
    if (width >= 1025) {
      setChartWidth((width - mainMinus));
      document.body.classList.remove('mobile');
    } else if (width >= 941) {
      setChartWidth((width - mainMinus));
      document.body.classList.remove('mobile');
    } else if (width >= 579) {
      setChartWidth(width - mainMinus);
      document.body.classList.remove('mobile');
    } else {
      setChartWidth(width - 40);
      document.body.classList.add('mobile');
    }
  }, []);

  return { chartWidth };
};
export default useChartWidthFull;
