import React, { useEffect, useState } from 'react';
import './style.scss';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { AuthRoutesEnum as paths } from '../../Routing/Routes'
import usePage from '../../hooks/router/usePage'

const logoutImg = '/images/logout.svg';

const SideBarDynamic = () => {
  const history = useHistory()
  const { id }: {id: string } = useParams()

  const { path }  = useRouteMatch()

  const [title, setTitle] = useState(undefined) 
  const [sideOptions, setSideOptions] = useState(undefined)

  const page = usePage();

  const advertiserName = useSelector((state: any) => state?.user?.advertiserName);

  enum Titles {
    profile = 'Profile',
    addAdvertiser = 'Add Advertsier',
    advertiser = 'Advertiser'
  }

  const allSideOptions = {
    'profile': [
      { 
        id: 1,
        name: 'Settings',
        path: paths.Profile,
        route: paths.Profile 
      }
    ], 
    'addAdvertiser': [
      { 
        id: 1,
        name: 'Add Advertiser',
        path: paths.AddAdvertiser,
        route: paths.AddAdvertiser
      }
    ], 
    'advertiser': [
      { 
        id: 1,
        name: 'Teams',
        path: paths.AdvertiserTeams,
        route: paths.AdvertiserTeams
      },
      { 
        id: 2,
        name: 'Credit Card Info',
        path: paths.AdvertiserCard,
        route: paths.AdvertiserCard
      },
      { 
        id: 3,
        name: "Pixel Info",
        path: paths.AdvertiserPixel,
        route: paths.AdvertiserPixel,
      },
      { 
        id: 4,
        name: 'Private Marketplace',
        path: paths.AdvertiserPmp,
        route: paths.AdvertiserPmp
      }
    ]
  }

  useEffect(() => {
    setTitleFunc(page)
    setSideOptionsFunc(page)
  }, [page])

  useEffect(() => {
    if (page === 'advertiser') {
      setTitleFunc(page)
    }

  }, [advertiserName])


  const setTitleFunc = (page: string) => {
    if (page) {
      const title = Titles[page].toString()
      if (title) {
        if (page === 'advertiser') {
          setTitle(advertiserName)
        } else {
          setTitle(title)
        }
      }
    }
  }

  const setSideOptionsFunc = (page: string) => {
    if (page) {
      const sideOptions = allSideOptions[page]
      if (sideOptions) {
        setSideOptions(sideOptions)
      }
    }
  }

  const formatRoute = (route) => {
    let newRoute = route
    if (route.includes(':id')) {
      newRoute = newRoute.replace(':id', id)
    }
    return newRoute
  }

  const navigateLogic = (route) => {
    const formattedRoute = formatRoute(route)
    history.push(formattedRoute)
  }


  if (page === undefined || title === undefined) {
    return (
      <div>
        some cool loading state ...
      </div> 
    )
  }


  return (
    <div className="sidebar">
      <div className="title-box">
        <h6 className="title">{title}</h6>
      </div>
      {sideOptions.map((sideOption) => 
        <div 
          key={sideOption.id} 
          className={`nav-box ${path === sideOption.path && 'selected'}`} 
          onClick={() => navigateLogic(sideOption.route)}
        >
          <h6 className="nav-title">{sideOption.name}</h6>
        </div>
      )}
      <div className="footer-nav-box">
        <div className="footer-nav-button">
          <span className="footer-nav-icon">
            <img src={logoutImg} />
          </span>
          <h6 className="footer-nav-title">Logout</h6>
        </div>
      </div>
    </div>
  );
};

export default SideBarDynamic;
