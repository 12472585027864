import axios from 'axios';
import { History } from 'history';
import { Dispatch } from 'redux';
import { baseUrl } from '../../config';
import { AuthRoutesEnum } from '../../Routing/Routes';
import { isInBlackList } from '../../utils/emailBlackList';
import types from './types';
import API from '../../services/api';
import {
  getToken,
  stopRenewToken,
  setToken,
} from '../../services/tokenService';

export const t = {
  useNew: true,
}

// ---> Logout Action
export const logoutAction = () => (dispatch) => {
  if (getToken()) {
    window.analytics.track('logged out', {});
  }

  window.Beacon('logout');

  stopRenewToken();
  setToken('');
  dispatch({
    type: types.SIGN_OUT,
  });
  window.location.href = '/';
};

// ---> Signup Action
export const signupAction =
  (
    firstName: string,
    lastName: string,
    email: string,
    organization: string,
    category: string,
    companyLogo: string,
    website: string,
    history,
    enqueueSnackbar: Function
  ) =>
  async (dispatch) => {
    if (await isInBlackList(email)) {
      enqueueSnackbar(`Invalid Email!`, { variant: 'error' });
      return;
    }

    const formDataBody = new FormData();
    formDataBody.append('first_name', firstName);
    formDataBody.append('last_name', lastName);
    formDataBody.append('email', email);
    formDataBody.append('website', website);
    formDataBody.append('category', category);
    formDataBody.append('organization', organization);
    formDataBody.append('logo', companyLogo);

    dispatch({
      type: types.SET_USER_LOADING,
      payload: true,
    });

    API({
      url: `${baseUrl}/create-account`,
      method: 'post',
      data: formDataBody,
    })
      .then((res) => {
        if (res.status === 200 && res.data.success) {
          const { advertiser_id, message, uuid, warnings } = res.data;
          dispatch({
            type: types.SET_PASSCODE_SENT,
            payload: true,
          });

          // send passcode after signup
          API.get(`${baseUrl}/login`, { params: { email: email } })
            .then((res) => {
              console.log(res);
              if (res.data.success === true) {
                enqueueSnackbar('Passcode Sent to your email.');

                dispatch({
                  type: types.SET_USER,
                  payload: { email: email },
                });
              } else {
                enqueueSnackbar(
                  `Sign In could not be processed. ${res.data.message}`,
                  { variant: 'error' }
                );
              }
            })
            .catch((error) => {
              enqueueSnackbar('Your email is not registered.');
            });
        } else if (res.status === 200 && !res.data.success) {
          enqueueSnackbar(res?.data?.message, { variant: 'error' });
        } else {
          const { message } = res.data;
          console.log(res);
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(
          `Create user could not be processed. ${error?.response?.data?.message}`,
          {
            variant: 'error',
          }
        );
      })
      .finally(() => {
        dispatch({
          type: types.SET_USER_LOADING,
          payload: false,
        });
      });
  };

// ---> Create Advertiser
export const createAdvertiserAction =
  (
    organization: string,
    category: string,
    logo: string,
    website: string,
    enqueueSnackbar: Function
  ) =>
  async (dispatch, getState) => {

    const formDataBody = new FormData();
    formDataBody.append('website', website);
    formDataBody.append('category', category);
    formDataBody.append('organization', organization);
    formDataBody.append('logo', logo);

    dispatch({
      type: types.SET_USER_LOADING,
      payload: true,
    });

    let dataResponse;
    try { 
      const res = await API({
        url: `${baseUrl}/create-advertiser`,
        method: 'post',
        data: formDataBody,
      })

      dataResponse = res.data

      if (res.status === 200 && res.data.success) {
        const data = dataResponse.data
        const advertiserId = data?.advertiser_id

        // GET all advertisers
        await dispatch(getAdvertisersAction(enqueueSnackbar))

        const user = await getState()?.user;
        const advertisers = user?.advertisers;

        let advertiser; 
        advertisers.forEach((advertItem) => {
          if (advertItem.id === advertiserId) {
            advertiser = advertItem
          }
        })

        const advertiserName = advertiser?.name

        if (advertiserId && advertiserName) {
          const payload = {
            advertiserId: advertiserId,
            advertiserName: advertiserName,
          }
          dispatch({
            type: types.SET_USER,
            payload,
          });
        }

        enqueueSnackbar('Successfuly created advertiser ', { variant: 'success' });
      } else if (res.status === 200 && !res.data.success) {
        enqueueSnackbar(res?.data?.message, { variant: 'error' });
      } else {
        const { message } = res.data;
      }

      return { message: res.data }

    } catch (error) {
      console.error(error);
      dataResponse = error;
      enqueueSnackbar(
        `Create Advertiser could not be processed. ${error?.response?.data?.message}`,
        {
          variant: 'error',
        }
      );
      return { error }
      
    } finally {
      dispatch({
        type: types.SET_USER_LOADING,
        payload: false,
      });

      if (dataResponse.isAxiosError) {
        return { error: true }
      } else if (!dataResponse.success) {
        return { error: true }
      } else {
        return { success: true, data: dataResponse.data }
      }

    }
  };

// -----> get Advertisers
export const getAdvertisersAction =
  (
    enqueueSnackbar: Function
  ) =>
    async (dispatch, getState) => {
      let data;

      try { 
        const res = await API({
          url: `${baseUrl}/user-advertisers`,
          method: 'get',
        })

        if (res.status === 200 && res?.data?.success) {
          data = res.data
          const advertisers = data.data

          const payload = {
            advertisers

          }

          dispatch({
            type: types.SET_USER,
            payload,
          });
        } else {
          enqueueSnackbar(res?.data?.message, { variant: 'error' });
        }
      } catch (err) {
        console.log('err xx9 ', err.response);
        enqueueSnackbar(err?.response?.data?.message, {
          variant: 'error',
        });
      } finally {
        return data
      }
    };


// ----> Resend Passcode
export const resendPasscodeAction =
  (email, enqueueSnackbar) => (dispatch: Dispatch) => {
    // send passcode after signup
    API.get(`${baseUrl}/login`, { params: { email: email } })
      .then((res) => {
        if (res.data.success === true) {
          enqueueSnackbar('Passcode sent to your email.');

          dispatch({
            type: types.SET_USER,
            payload: { email: email },
          });
        } else {
          enqueueSnackbar(
            `Sign In could not be processed. ${res.data.message}`,
            { variant: 'error' }
          );
        }
      })
      .catch((error) => {
        enqueueSnackbar('Your email is not registered.');
      });
  };

// ---> Submit Email Action
export const submitEmail =
  (
    email: string,
    handleActive: Function,
    changeStep: Function,
    setInputValue: Function,
    enqueueSnackbar: Function
  ) =>
  async (dispatch: Dispatch) => {
    if (await isInBlackList(email)) {
      enqueueSnackbar(`Invalid Email!`, { variant: 'error' });
      handleActive();
      return;
    }

    API.get(`${baseUrl}/${t.useNew ? 'signin' :  'login'}`, { params: { email: email } })
      .then((res) => {
        if (res.data.success === true) {
          enqueueSnackbar('Passcode Sent to your email.');
          handleActive();
          dispatch({
            type: types.SET_USER,
            payload: { email: email },
          });
          changeStep(false);
          setInputValue('');
        } else {
          enqueueSnackbar(
            `Sign In could not be processed. ${res.data.message}`,
            { variant: 'error' }
          );
          handleActive();
        }
      })
      .catch((error) => {
        enqueueSnackbar('Your email is not registered.');
        handleActive();
      });
  };

// ----> Submit Passcode Action
export const submitPasscode =
  (
    email: string,
    passcode: string,
    enqueueSnackbar: Function,
    history: History,
    handleActive: Function
  ) =>
  (dispatch: Dispatch) => {
    API.post(
      `${baseUrl}/${t.useNew ? 'signin' :  'login'}`,
      {},
      {
        auth: {
          username: email,
          password: passcode,
        },
      }
    )
      .then((res) => {
        if (res.data.success) {
          enqueueSnackbar('Successfully logged In.');

          const userId = res?.data?.user_id;
          const firstName = res?.data?.first_name;
          const lastName = res?.data?.last_name;
          const email = res?.data?.email;

          // Segment -> identify and track
          window['analytics'].identify(userId, {
            name: `${firstName} ${lastName}`,
            email: email,
          });

          window['analytics'].track('logged in', {});


          let payload;
          if (t.useNew) {
            // NOTE -- new way 
            const advertisers = res?.data?.advertisers;

            const firstAdvertiser = advertisers[0]

            payload = {
              firstName,
              lastName,
              advertisers,
              advertiserId: firstAdvertiser.id,
              advertiserName: firstAdvertiser.name,
              createdAt: res?.data?.created_at,
              id: userId,
              admin: res?.data?.admin,
            }
          // NOTE - old way
          } else {
            payload = {
              firstName,
              lastName,
              advertiserId: res?.data?.advertiser_id,
              advertiserName: res?.data?.advertiser_name,
              createdAt: res?.data?.created_at,
              id: userId,
              admin: res?.data?.admin,
            }

          }

          dispatch({
            type: types.SET_USER,
            payload,
          });

          const token = res.data.token

          setToken(token);
          // setTokenToAxios(res.data.token);
          history.push(AuthRoutesEnum.Dashboard);
        }
      })
      .catch((error) => {
        enqueueSnackbar('This account is not currently active or has been dissociated. Please try again and if this error occurs again please contact support@audiohook.com', { variant: 'error' });
        console.log(error);
      })
      .finally(() => {
        handleActive();
      });
  };

// ************* User Card Actions ****************
// ************* User Card Actions ****************
// ************* User Card Actions ****************
// ----> Add Card
type ReqType = 'put' | 'post';
export const cardHandler =
  (tokenId: string, enqueueSnackbar: Function, reqType: ReqType) =>
  (dispatch, getState) => {
    const advId = getState()?.user?.advertiserId;
    const bodyFormData = new FormData();
    bodyFormData.append('token', tokenId);

    if (reqType === 'post') {
      API({
        url: `${baseUrl}/${advId}/stripe-token`,
        method: 'post',
        data: bodyFormData,
      })
        .then((res) => {
          console.log(res);
          if (res.status === 200 && res?.data?.success) {
            enqueueSnackbar('Card has been added!', { variant: 'success' });
            dispatch(getLastFourDigits(enqueueSnackbar));
          } else {
            const message =
              res?.data?.message ||
              'Something went wrong while adding the card!';
            enqueueSnackbar(message, { variant: 'error' });
          }
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar('Something went wrong while adding the card!', {
            variant: 'error',
          });
        });
    }
    if (reqType === 'put') {
      API({
        url: `${baseUrl}/${advId}/stripe-token`,
        method: 'put',
        data: bodyFormData,
      })
        .then((res) => {
          console.log(res);
          if (res.status === 200 && res?.data?.success) {
            enqueueSnackbar('Card has been edited!', { variant: 'success' });
            dispatch(getLastFourDigits(enqueueSnackbar));
          } else {
            const message =
              res?.data?.message ||
              'Something went wrong while editing the card!';
            enqueueSnackbar(message, { variant: 'error' });
          }
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar('Something went wrong while editing the card!', {
            variant: 'error',
          });
        })
        .finally(() => {
          dispatch({
            type: types.SET_CARD_EDITING,
            payload: false,
          });
        });
    }
  };

// ************* PMP Actions ****************
// -----> get PMP
export const getPmps =
  (
    enqueueSnackbar: Function
  ) =>
    async (dispatch, getState) => {
      const advId = getState()?.user?.advertiserId;
      let data;

      try { 
        const res = await API({
          url: `${baseUrl}/${advId}/pmp`,
          method: 'get',
        })

        if (res.status === 200 && res?.data?.success) {
          data = res.data
        } else {
          enqueueSnackbar(res?.data?.message, { variant: 'error' });
        }
      } catch (err) {
        console.log('err xx9 ', err.response);
        enqueueSnackbar(err?.response?.data?.message, {
          variant: 'error',
        });
      } finally {
        return data
      }
    };

// ----> Add PMP
export const addPmpAction =
  (
    name: string,
    dealId: string,
    floorCPM: string,
    enqueueSnackbar: Function
  ) =>
    async (dispatch, getState) => {
      const advId = getState()?.user?.advertiserId;

      const bodyFormData = new FormData();
      bodyFormData.append('name', name);
      bodyFormData.append('deal_id', dealId);
      bodyFormData.append('floor_cpm', floorCPM);

      let dataResponse
      try { 
        const res = await API({
          url: `${baseUrl}/${advId}/save-pmp`,
          method: 'post',
          data: bodyFormData,
        })

        dataResponse = res.data
        if (res.status === 200 && res?.data?.success) {
          enqueueSnackbar("Successfully added pmp.", { variant: 'success' });
        } else {
          enqueueSnackbar(res?.data?.message, { variant: 'error' });
        }
      } catch (err) {
        dataResponse = err;
        enqueueSnackbar(err?.response?.data?.message, {
          variant: 'error',
        });
      } finally {
        if (dataResponse.isAxiosError) {
          return { error: true }
        } else if (!dataResponse.success) {
          return { error: true }
        } else {
          return { success: true, data: dataResponse }
        }

      }
    };

// ----> Retrieve Last 4 digits for a card
export const getLastFourDigits =
  (enqueueSnackbar: Function) => (dispatch, getState) => {
    const advId = getState()?.user?.advertiserId;
    API({
      url: `${baseUrl}/${advId}/get-card-last-4`,
      method: 'get',
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200 && res.data.success) {
          dispatch({
            type: types.SET_CARD_DIGIT,
            payload: res.data.data,
          });
        } else {
          // let message = res?.data?.message || 'Something went wrong during retrieving card!';
          // enqueueSnackbar(message, { variant: "error" });
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar('Something went wrong during retrieving card!', {
          variant: 'error',
        });
      });
  };

// Delete Card
export const deleteCardAction =
  (enqueueSnackbar: Function) => (dispatch, getState) => {
    const advId = getState()?.user?.advertiserId;

    API({
      url: `${baseUrl}/${advId}/stripe-token`,
      method: 'delete',
    })
      .then((res) => {
        let message = '';
        if (res.status === 200 && res?.data?.success) {
          message = res?.data?.message || 'Card has been deleted successfully!';
          enqueueSnackbar(message, { variant: 'success' });
          dispatch({
            type: types.SET_CARD_DIGIT,
            payload: '',
          });
        } else {
          message =
            res?.data?.message || 'Something went wrong while deleting card!';
          enqueueSnackbar(message, { variant: 'error' });
        }
      })
      .catch((error) => {
        let message = 'Error! Something went wrong while deleting card!';
        enqueueSnackbar(message, { variant: 'error' });
        console.log(error);
      });
  };

// ----> Add Team Member Action
export const addTeamMemberAction =
  (
    firstName: string,
    lastName: string,
    email: string,
    enqueueSnackbar: Function
  ) =>
  (dispatch, getState) => {
    const advId = getState()?.user?.advertiserId;

    const bodyFormData = new FormData();
    bodyFormData.append('email', email);
    bodyFormData.append('first_name', firstName);
    bodyFormData.append('last_name', lastName);

    API({
      url: `${baseUrl}/${advId}/add-coworker`,
      method: 'post',
      data: bodyFormData,
    })
      .then((res) => {
        console.log(res);
        if (res.status === 200 && res?.data?.success) {
          dispatch(retrieveCoWorkersAction(enqueueSnackbar));
        } else {
          enqueueSnackbar(res?.data?.message, { variant: 'error' });
        }
      })
      .catch((err) => {
        console.log(err.response);
        enqueueSnackbar(err?.response?.data?.message, {
          variant: 'error',
        });
      });
  };

// ---> Retrieve Co-Workers Action
export const retrieveCoWorkersAction =
  (enqueueSnackbar: Function) => (dispatch, getState) => {
    const advId = getState()?.user?.advertiserId;
    API.get(`${baseUrl}/${advId}/coworkers`)
      .then((res) => {
        console.log(res);
        if (res.status === 200 && res.data.success) {
          dispatch({
            type: types.SET_TEAM,
            payload: res?.data?.data,
          });
        } else {
          enqueueSnackbar(res.data.message, { variant: 'error' });
        }
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar(err.response.data.message, { variant: 'error' });
      });
  };

// ---> change team ownership Action
export const changeTeamOwnershipAction =
  (id: string, enqueueSnackbar: Function) => (dispatch, getState) => {
    const advId = getState()?.user?.advertiserId;
    const bodyFormData = new FormData();
    bodyFormData.append('user_id', id);
    API({
      url: `${baseUrl}/${advId}/change-owner`,
      method: 'PUT',
      data: bodyFormData,
    })
      .then((res) => {
        console.log(res);
        if (res.status === 200 && res.data.success) {
          dispatch(retrieveCoWorkersAction(enqueueSnackbar));
          enqueueSnackbar('success', { variant: 'success' });
        } else {
          enqueueSnackbar(res.data.message, { variant: 'error' });
        }
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar(err.response.data.message, { variant: 'error' });
      });
  };

// ---> Remove Co-worker Action
export const removeCoworkerAction =
  (id: string | number, enqueueSnackbar: Function) => (dispatch, getState) => {
    const advId = getState()?.user?.advertiserId;
    const bodyFormData = new FormData();
    bodyFormData.append('user_id', id.toString());
    API({
      url: `${baseUrl}/${advId}/remove-coworker`,
      method: 'delete',
      data: bodyFormData,
    })
      .then((res) => {
        console.log(res);
        if (res.status === 200 && res.data.success) {
          enqueueSnackbar(res.data.message, { variant: 'success' });
          dispatch({
            type: types.REMOVE_TEAM_MEMBER,
            payload: id,
          });
        } else {
          enqueueSnackbar(res.data.message, { variant: 'error' });
        }
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar(err.response.data.message, { variant: 'error' });
      });
  };

// ---> GET User Data
export const getUserData = () => (dispatch: Dispatch, getState) => {
  const userId = getState()?.user?.id;
  API.get(`${baseUrl}/${userId}/user`).then((res) => {
    if (res.status === 200 && res.data.success) {
      console.log(res);
      dispatch({
        type: types.SET_USER,
        payload: {
          firstName: res?.data?.data?.first_name,
          lastName: res?.data?.data?.last_name,
          profilePic: res?.data?.data?.profile_pic,
          email: res?.data?.data?.email,
        },
      });
    }
  });
};

type Tdata = File | string;
// ---> Edit User Action
export const editUser =
  (data: Tdata, field: string) => (dispatch: Dispatch, getState) => {
    const userId = getState()?.user?.id;
    const bodyFormData = new FormData();
    bodyFormData.append(field, data);

    API({
      method: 'put',
      url: `${baseUrl}/${userId}/user`,
      data: bodyFormData,
    })
      .then((res) => {
        if (res.status === 200 && res.data.success) {
          console.log(res);
          dispatch({
            type: types.UPDATE_USER,
            payload: {
              field: field,
              data: data,
            },
          });
        } else {
          console.error(res);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
