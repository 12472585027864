import React, { useState, useEffect, useMemo } from 'react'
import { useSnackbar } from 'notistack';
import Card from '../../Card'
import { useSelector } from 'react-redux'
import ChartSkeleton from 'components/chart/ChartSkeleton'
import LineChartV2 from 'components/chart-v2/line'
import SplineChartV2 from 'components/chart-v2/spline'
import useChartWidthFull from 'hooks/useChartWidthFull'
import { DateRangePicker } from 'rsuite'
import ddh from 'utils/DashboadDataHandler'
import Button from '../../Button'

type Props = {
  reportData: any;
  selectedTab: String;
  setSelectedTab: any;
  dateRange: Date[];
  setDateRange: React.Dispatch<React.SetStateAction<Date[]>>;
};

const ChartSelect = ({ reportData, selectedTab, dateRange, setDateRange, setSelectedTab }: Props) => {

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const reportLoading = useSelector((state: any) => state?.dashboard?.loading)
  const { chartWidth } = useChartWidthFull()

  const [graphTab, setGraphTab] = useState('double')
  const [doubleGraphsState, setDoubleGraphsState] = useState({
    'impressions': true,
    'spend': false,
    'attributed-sessions': false,
    'roas': false
  })

  const graphData = {
    'impressions': {
      title: 'Impressions',
      value: 'impressions',
      valueData: `${ddh.numberWithCommas(ddh.getTotalImpression(reportData))}`,
      color: '#32C5FF',
      data: ddh.getDataForImpression(reportData)
    },
    'spend': {
      title: 'Spend',
      value: 'spend',
      valueData: `$${ddh.getTotalCost(reportData)}`,
      color: '#B620E0',
      data: ddh.getDataForCost(reportData),
    },
    'attributed-sessions': {
      title: 'Attributed Sessions',
      value: 'attributed-sessions',
      valueData: `${ddh.getTotalAttrSess(reportData.attributed_sessions)}`,
      color: '#356FFF',
      data: ddh.getDataForAttrSess(reportData.attributed_sessions),
    },
    'roas': {
      title: 'Roas',
      value: 'roas',
      valueData: `${ddh.getTotalRoas(reportData)}`,
      color: '#00F799',
      data: ddh.getDataForRoas(reportData),
    }
  }

  const graphDataBlank = {
    'blank': {
      title: 'blank',
      value: 'blank',
      valueData: [],
      color: '#000',
      data: [],
    }
  }
  const [graphOne, setGraphOne]: any = useState(graphData['impressions'])
  const [graphTwo, setGraphTwo]: any = useState(graphDataBlank['blank'])

  const [doubleGraphs, setDoubleGraphs] = useState([
    {
      id: 1,
      label: 'Impressions',
      value: 'impressions',
      isSelected: true
    },
    {
      id: 2,
      label: 'Spend',
      value: 'spend',
      isSelected: false,
    },
    {
      id: 3,
      label: "Attributed Sessions",
      value: 'attributed-sessions',
      isSelected: false,
    },
    {
      id: 5,
      label: "Roas",
      value: 'roas',
      isSelected: false,
    }
  ])

  const TopOfCharts = ({ topOptions }) => {
    return (
      <>
        <div className="flex justify-between">
          <div className="flex justify-start">
            <div className="pr-2 capitalize font-bold">{topOptions.title}</div>
            <div className="font-semibold">{topOptions.value}</div>
          </div>
          <div className="">
            <DateRangePicker
              data-testid="dashboard-datepicker"
              placement="bottomEnd"
              appearance="default"
              // @ts-ignore
              value={dateRange}
              onChange={(value) => {
                console.log('value ', value)
                // @ts-ignore
                setDateRange(value);
              }}
            />
          </div>
        </div>
      </>
    )
  }

  const ImpressionsChart = () => {

    const generalOptions = {
      title: 'Impressions',
      value: `${ddh.numberWithCommas(ddh.getTotalImpression(reportData))}`
    }

    const option = {
      color: '#32C5FF',
      ...generalOptions
    }

    const topOptions = {
      ...generalOptions
    }

    return (<LineChartV2
      data={ddh.getDataForImpression(reportData)}
      width={chartWidth}
      minDate={ddh.getMinDate(reportData)}
      option={option}
      top={<TopOfCharts topOptions={topOptions} />}
    />)
  }

  const SpendChart = () => {

    const generalOptions = {
      title: 'Spend',
      value: `$${ddh.getTotalCost(reportData)}`,
    }

    const option = {
      color: '#B620E0',
      ...generalOptions
    }

    const topOptions = {
      ...generalOptions
    }
    return (<LineChartV2
      data={ddh.getDataForCost(reportData)}
      minDate={ddh.getMinDate(reportData)}
      width={chartWidth}
      option={option}
      top={<TopOfCharts topOptions={topOptions} />}
    />)
  }

  const AttributedSessionsChart = () => {

    const generalOptions = {
      title: 'Attributed Sessions',
      value: `${ddh.getTotalAttrSess(reportData.attributed_sessions)}`,
    }

    const option = {
      color: '#356FFF',
      ...generalOptions
    }

    const topOptions = {
      ...generalOptions
    }

    return (<LineChartV2
      data={ddh.getDataForAttrSess(reportData.attributed_sessions)}
      width={chartWidth}
      minDate={ddh.getMinDateFromAttrSession(
        reportData.attributed_sessions
      )}
      option={option}
      top={<TopOfCharts topOptions={topOptions} />}
    />)
  }

  const SelectGraphs = () => {
    return (<span className="relative z-0 inline-flex shadow-sm rounded-md">
      <button
        type="button"
        className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
        onClick={() => setSelectedTab('impressions')}
      >
        Impressions
      </button>
      <button
        type="button"
        className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
        onClick={() => setSelectedTab('spend')}
      >
        Spend
      </button>
      <button
        type="button"
        className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
        onClick={() => setSelectedTab('attributed-sessions')}
      >
        Days
      </button>
    </span>)
  }

  const SelectGraph = () => {

    const genericAppendClasses = "text-base bg-gray-50 hover:bg-gray-100 focus:ring-transparent"
    const selectedClasses = "text-base bg-gray-200 hover:bg-gray-300 focus:ring-transparent"

    return (
      <div className="pt-8 ">
        <div className="flex justify-start">
          <div className="pr-2">
            <Button
              label="Normal"
              isRounded={false}
              appendClasses={genericAppendClasses}
              selectedClasses={selectedClasses}
              isSelected={graphTab === 'normal'}
              onClick={() => setGraphTab('normal')}
            />
          </div>
          <Button
            label="Double"
            isRounded={false}
            appendClasses={genericAppendClasses}
            selectedClasses={selectedClasses}
            isSelected={graphTab === 'double'}
            onClick={() => setGraphTab('double')}
          />
        </div>
        <div className="pt-4" />
        <hr />
      </div>
    )

  }


  const TopOfChartsV2 = ({ topOptions }) => {
    return (
      <>
        <div className="flex justify-end">
            <DateRangePicker
              data-testid="dashboard-datepicker"
              placement="bottomEnd"
              appearance="default"
              // @ts-ignore
              value={dateRange}
              onChange={(value) => {
                console.log('value ', value)
                // @ts-ignore
                setDateRange(value);
              }}
            />
          </div>
      </>
    )
  }

  useEffect(() => {
    let count = 0
    Object.keys(doubleGraphsState).forEach((key) => {
      const value = doubleGraphsState[key]
      if (value === true && count === 0) {
        setGraphOne(graphData[key])
        count++
      } else if (value === true && count === 1) {
        setGraphTwo(graphData[key])
        count++
      }
    })



  }, [doubleGraphsState, reportData])


  const graphDataFiltered = useMemo(() => {

    let filteredGraphData = Object.keys(doubleGraphsState).filter((key) => {
      const value = doubleGraphsState[key]
      return value === true
    })

    let leftOverGraphData = {}

    filteredGraphData.forEach((key) => {
      leftOverGraphData[key] = graphData[key]
    })

    return leftOverGraphData

  }, [doubleGraphsState]);

  const graphDataFilteredLength = useMemo(() => {
    return Object.keys(graphDataFiltered).length
  }, [graphDataFiltered])


  const DoubleGraph = ({ graphData, graphOneValue = 'impressions', graphTwoValue = 'spend' }) => {

    const generalOptions = {
      title: 'Double Graph',
      titleOne: graphOne.title,
      titleTwo: graphTwo.title,
      valueOne: graphOne.valueData,
      valueTwo: graphTwo.valueData,
      dataOne: graphOne.data,
      dataTwo: graphTwo.data
    }

    const option = {
      colorOne: graphOne.color,
      colorTwo: graphTwo.color,
      ...generalOptions
    }

    const topOptions = {
      ...generalOptions
    }



    return (<SplineChartV2
      chartSize={graphDataFilteredLength}
      width={chartWidth}
      minDate={ddh.getMinDate(reportData)}
      option={option}
      top={<TopOfChartsV2 topOptions={topOptions} />}
    />)
  }



  const SelectDoubleGraphs: any = () => {

    interface TProps {
      graphButton: any
    }

    const handleSetDoubleGraph = (graphValue: String) => {

      let currentState: any = JSON.parse(JSON.stringify(doubleGraphsState))

      // @ts-expect-error
      currentState[graphValue] = !currentState[graphValue]

      console.log('object entries ', Object.values(currentState))

      const objectValues = Object.values(currentState)

      const countTrues = (objectValues) => {
        let count = 0
        objectValues.forEach((value) => {
          if (value === true) {
            count++
          }
        })
        return count
      }

      const trueCount = countTrues(objectValues)

      if (trueCount < 3 && trueCount > 0) {
        setDoubleGraphsState(currentState)
      } else if (trueCount > 2) {
        enqueueSnackbar(
          `Maximum of two graphs only.`,
          { variant: 'warning' }
        );
      } else if (trueCount < 1) {
        enqueueSnackbar(
          `Minimum of one graph required.`,
          { variant: 'warning' }
        );

      }

      console.log('doubleGraphsState', doubleGraphsState);
    }


    const TheButton = ({ graphButton }: TProps) => {
      const genericAppendClasses = "text-base bg-gray-50 hover:bg-gray-100 focus:ring-transparent"
      const selectedClasses = "text-base bg-blue-200 hover:bg-blue-300 focus:ring-transparent"

      const isSelected = useMemo(() => doubleGraphsState[graphButton.value], [doubleGraphsState]);


      return (<Button
        id={graphButton.id + '-select-double-graphs'}
        label={graphButton.label}
        isRounded={true}
        appendClasses={genericAppendClasses}
        selectedClasses={selectedClasses}
        isSelected={isSelected}
        onClick={() => handleSetDoubleGraph(graphButton.value)}
      />)
    }

    return (
      <div className="">
        <div className="flex justify-start">
          {doubleGraphs.map((graphButton) => {
            return (<div key={graphButton.id} className="pr-2">
              <TheButton graphButton={graphButton} />
            </div>)
          })}
        </div>
      </div>
    )

  }

  const DoubleGraphLayout = () => {
    return (
      <>
        <div className="pt-4 mb-2">
          <div className="py-4 grid place-content-center w-full">
            <div className="content-chart relative">
              {reportLoading ? (
                <ChartSkeleton width={chartWidth} />
              ) : (
                <DoubleGraph graphData={graphData} />
              )}
            </div>
          </div>
          <div className="flex justify-end h-16 ">
            <SelectDoubleGraphs />
          </div>
        </div>
      </>
    )
  }

  const NormalGraphLayout = () => {
    return (
      <div className="px-4 mb-2">
        <div className="py-4 grid place-content-center w-full">
          <div className="content-chart relative">
            {reportLoading ? (
              <ChartSkeleton width={chartWidth} />
            ) : (
              selectedTab === 'impressions' && ImpressionsChart() ||
              selectedTab === 'spend' && SpendChart() ||
              selectedTab === 'attributed-sessions' && AttributedSessionsChart() ||
              <div>Error</div>
            )}
          </div>
        </div>
        <div className="h-16 ">
          <SelectGraphs />
        </div>
      </div>
    )
  }


  return (
    <Card>
      <div className="px-8">
        <DoubleGraphLayout />
      </div>
    </Card>
  )
}

export default ChartSelect;