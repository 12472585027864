import Avatar from '@material-ui/core/Avatar';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import SettingsIcon from '@material-ui/icons/Settings';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  changeTeamOwnershipAction,
  removeCoworkerAction,
} from '../../../../store/actions/userActions';
import './style.scss';

interface IMember {
  memberName: string;
  memberEmail: string;
  memberId: string;
  memberImg?: string;
  handleClickSettings: Function;
  isOwner: boolean;
  clickedUserId: string;
}

const Member = function (props: IMember): JSX.Element {
  const {
    memberImg,
    memberName,
    memberEmail,
    memberId,
    handleClickSettings,
    isOwner,
    clickedUserId,
  } = props;

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleMakeOwner = (id: string) => {
    dispatch(changeTeamOwnershipAction(id, enqueueSnackbar));
  };

  const handleRemoveCoworker = (id: string) => {
    dispatch(removeCoworkerAction(id, enqueueSnackbar));
  };

  return (
    <div className="member-box">
      <div className="m-box-left">
        <Avatar className="m-box-avatar" src={memberImg} alt="profile-img" />
        <div className="m-box-info">
          <p className="m-box-name">{memberName}</p>
          <p className="m-box-email">{memberEmail}</p>
        </div>
      </div>
      <div className="m-box-right">
        {isOwner ? (
          <div>owner</div>
        ) : (
          <div
            className="m-box-settings"
            onClick={() => handleClickSettings(memberId)}
          >
            <SettingsIcon />
            {clickedUserId === memberId ? (
              <ArrowDropUpIcon />
            ) : (
              <ArrowDropDownIcon />
            )}

            {clickedUserId === memberId && (
              <div className="m-box-settings-dd">
                <div className="m-box-s-dd-wrapper">
                  <button
                    onClick={() => handleMakeOwner(memberId)}
                    className="dd-btn"
                  >
                    Make Owner
                  </button>
                  <button
                    onClick={() => handleRemoveCoworker(memberId)}
                    className="dd-btn"
                  >
                    Remove Member
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Member;
