import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import './style.scss';

const Mp3FormatIcon = '/images/mp3-format-icon.svg';
const CsvIcon = '/images/csv-Icon.svg';
const BinIcon = '/images/bin-icon.svg';

interface IFileDetails {
  creative?: any;
  name?: string;
  size?: string;
  onResetClick: (id: number) => void;
  isDeleting: boolean;
  id: number;
  type: string;
}

const FileDetails: React.FC<IFileDetails> = ({
  creative,
  name,
  type,
  size,
  onResetClick,
  isDeleting,
  id,
}) => {
  const creativeId: number = creative?.id;
  const list_url = useSelector(
    (state: any) => state?.campaigns?.campaign?.list_url
  );

  return (
    <div className="file-details">
      <img
        src={type === 'creative' ? Mp3FormatIcon : CsvIcon}
        alt=""
        className="file-details__image"
      />
      <div className="file-datails__info">
        {type === 'creative' && (
          <p className="file-details__name">
            {name}
          </p>
        )}
        {type === 'email' && (
          <p className="file-details__name">
            {list_url ? (
              <a href={list_url} target="_blank">
                {list_url}
              </a>
            ) : (
              name
            )}
          </p>
        )}

        {type === 'creative' && creative.hasOwnProperty('filehost') && (
          <p className="file-details__size">{size}</p>
        )}
      </div>

      {type === 'creative' ? (
        isDeleting && id === creativeId ? (
          <CircularProgress size={20} style={{ marginLeft: 'auto' }} />
        ) : (
          <button
            type="button"
            onClick={() => onResetClick(creativeId)}
            className="file-details__delete-image"
          >
            <img src={BinIcon} alt="" />
          </button>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default FileDetails;
