import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Page from '../../components/page/Page';
import SignInForm from '../../components/sign-in-form';
import { getToken } from '../../services/tokenService';
import { AuthRoutesEnum } from '../../Routing/Routes';
import types from '../../store/actions/types';
import {
  resendPasscodeAction,
  signupAction,
  submitPasscode,
} from '../../store/actions/userActions';
import { validateEmail, validateUrl } from '../../utils/validateFns';
import './style.scss';
import { isInBlackList } from '../../utils/emailBlackList';

const logo = '/images/ahsvg.svg';
const SignInIcon = '/images/audiohook-logo.svg';

/**
 * ########################
 *    Main Function
 * ########################
 */
const SignUp: React.FC = (props: any) => {
  const [error, setError] = useState({
    email: '',
    website: '',
  });
  const [value, setValue] = useState({
    firstName: '',
    lastName: '',
    email: '',
    website: '',
    category: '',
    organization: '',
    companyLogo: null,
  });

  const [checked, setChecked] = useState(false);

  const [edit, setEdit] = useState({
    firstName: false,
    lastName: false,
    email: false,
    website: false,
    category: false,
    organization: false,
    companyLogo: false,
  });

  const loading = useSelector((state: any) => state?.user?.loading);

  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const passcodeSent = useSelector((state: any) => state?.user?.passcodeSent);
  const [activity, setActivity] = useState(true);
  const [inputValue, setInputValue] = useState('');

  // handle Change Field
  const handleChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const handleActive = () => {
    setActivity(true);
  };

  const handleDeActive = () => {
    setActivity(false);
  };

  const handleCheckError = (e) => {
    let validated: boolean;
    if (e.target.name === 'email') {
      let isBlacklisted = isInBlackList(e.target.value);
      validated = validateEmail(e.target.value);

      if (!validated) {
        setError({ ...error, email: 'Email is not valid!' });
      } else if (validated && isBlacklisted) {
        setError({ ...error, email: 'Email domain name is not valid!' });
      } else {
        setError({ ...error, email: '' });
      }
    }
    if (e.target.name === 'website') {
      validated = validateUrl(e.target.value);
      if (!validated) {
        setError({
          ...error,
          website: 'Website URL is not valid! e.g: https://example.com',
        });
      } else {
        setError({ ...error, website: '' });
      }
    }
  };

  const handleChangeLogo = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        setValue({ ...value, companyLogo: file });
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const parsedToken = getToken();
    if (parsedToken) {
      history.push(AuthRoutesEnum.Dashboard);
    }
    dispatch({
      type: types.SET_PASSCODE_SENT,
      payload: false,
    });
    dispatch({
      type: types.SET_USER_LOADING,
      payload: false,
    });
  }, []);

  const {
    firstName,
    lastName,
    email,
    organization,
    category,
    companyLogo,
    website,
  } = value;

  // --> Disable Submit button status
  const handleSubmitDisabled = () => {
    if (
      firstName &&
      lastName &&
      email &&
      organization &&
      category &&
      companyLogo &&
      website &&
      !error.email &&
      checked
    ) {
      return false;
    }
    return true;
  };

  // ---> Handle Submit Form
  const submitForm = (e) => {
    e.preventDefault();

    dispatch(
      signupAction(
        firstName,
        lastName,
        email,
        organization,
        category,
        companyLogo,
        website,
        history,
        enqueueSnackbar
      )
    );
  };

  // ----> Resend PassCode
  const resendPasscode = () => {
    // write code here
    dispatch(resendPasscodeAction(email, enqueueSnackbar));
  };

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setInputValue(event.target.value);
  };

  // ----> Submit Passcode
  const passcodeSubmitHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    event.preventDefault();
    handleDeActive();
    dispatch(
      submitPasscode(email, inputValue, enqueueSnackbar, history, handleActive)
    );
  };

  const content = passcodeSent ? (
    <Page>
      <div className="sign-in">
        <div className="sign-in__left-side">
          <div className="sign-in__form-wrapper">
            <SignInForm
              activity={activity}
              title="Welcome!"
              subtitle="We’re excited you’re here! Check your email for a passcode so we can show you around."
              label="Passcode"
              value={inputValue}
              placeholder="Enter passcode"
              buttonLabel="Sign In"
              formHandler={passcodeSubmitHandler}
              handleInputChange={handleInputChange}
              isPasscodeStep
              resendPasscode={resendPasscode}
            />
          </div>
        </div>
        <div className="sign-in__right-side">
          <img src={SignInIcon} className="sign-in__icon" alt="" />
        </div>
      </div>
    </Page>
  ) : (
    <div className="signup-wrapper">
      <div className="signup-inner">
        {/* <IntercomComponent
          email=""
          firstName="Unknown"
          lastName="Name"
          userId="-1"
          companyId="-1"
          createdAt={new Date().getTime()}
        /> */}

        <div className="signup-header">
          <div className="signup-logo">
            <a href="/">
              <img src={logo} alt="" />
            </a>
          </div>
        </div>

        <div className="signup-body">
          <div className="signup-body__inner">
            <div className="signup-title">Get Started For Free</div>
            <div className="signup-form">
              <input
                type="text"
                placeholder="First Name"
                name="firstName"
                className="input"
                onChange={handleChange}
              />
              <input
                type="text"
                placeholder="Last Name"
                name="lastName"
                className="input"
                onChange={handleChange}
              />
              <input
                type="email"
                placeholder="Email"
                name="email"
                className="input"
                onChange={handleChange}
                onBlur={handleCheckError}
              />
              {error.email && <p className="signup-error">{error.email}</p>}

              <input
                type="text"
                placeholder="Website"
                name="website"
                className="input"
                onChange={handleChange}
                onBlur={handleCheckError}
              />
              {error.website && <p className="signup-error">{error.website}</p>}

              <select className="input" onChange={handleChange} name="category">
                <option value="">Category</option>
                <option value="IAB1">Arts & Entertainment</option>
                <option value="IAB2">Automotive</option>
                <option value="IAB3">Business</option>
                <option value="IAB4">Careers</option>
                <option value="IAB5">Education</option>
                <option value="IAB6">Family & Parenting</option>
                <option value="IAB7">Health & Fitness</option>
                <option value="IAB8">Food & Drink</option>
                <option value="IAB9">Hobbies & Interests</option>
                <option value="IAB10">Home & Garden</option>
                <option value="IAB11">Law, Government, & Politics</option>
                <option value="IAB12">News</option>
                <option value="IAB13">Personal Finance</option>
                <option value="IAB14">Society</option>
                <option value="IAB15">Science</option>
                <option value="IAB17">Sports</option>
                <option value="IAB18">Style & Fashion</option>
                <option value="IAB19">Technology & Computing</option>
                <option value="IAB20">Travel</option>
                <option value="IAB21">Real Estate</option>
                <option value="IAB22">Shopping</option>
                <option value="IAB23">Religion & Spirituality</option>
                <option value="IAB24">Uncategorized</option>
                <option value="IAB25">Non-Standard Content</option>
              </select>

              <input
                type="text"
                name="organization"
                placeholder="Company Name"
                className="input"
                onChange={handleChange}
              />

              <div className="company-logo-uploader">
                <label
                  className="company-logo-label"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <span> Upload Logo</span>
                  <span style={{ opacity: 0.5 }}>.png or .jpg</span>
                </label>
                <div className="company-logo-uploader-inner">
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    placeholder="Logo"
                    className="company-logo-field"
                    onChange={handleChangeLogo}
                  />
                  {companyLogo && (
                    <p className="company-logo-name">{companyLogo.name}</p>
                  )}
                </div>
              </div>

              <div className="signup-checkbox">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                      color="primary"
                    />
                  }
                  label={
                    <span>
                      I've read and agree to the
                      <a href="https://www.audiohook.com/terms" target="_blank">
                        {' '}
                        terms of service{' '}
                      </a>
                      and
                      <a
                        href="https://www.audiohook.com/privacy-policy"
                        target="_blank"
                      >
                        {' '}
                        privacy policy
                      </a>
                      .
                    </span>
                  }
                />
              </div>

              <button
                type="button"
                className="submit-btn"
                disabled={handleSubmitDisabled()}
                onClick={submitForm}
              >
                Let's Do This
                {loading && (
                  <CircularProgress size={15} className="button-loader" />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return content;
};
export default SignUp;
