import Button from '@material-ui/core/Button';
import React from 'react';
import './style.scss';

interface IButton {
  text: string;
  variant: 'text' | 'outlined' | 'contained';
  color: string;
  btntype: 'cancel' | 'action';
  handleClick: () => void;
  type?: string;
  disabled?: boolean;
}

const AHButton = (props: IButton) => {
  const { text, variant, color, type, btntype, handleClick, disabled } = props;
  return (
    <Button
      id="button"
      className={`${btntype === 'cancel' ? 'cancel-btn' : ''}`}
      color={color === 'primary' ? 'primary' : 'default'}
      variant={variant}
      onClick={handleClick}
      type={type ? 'submit' : 'button'}
      component="button"
      disabled={disabled}
      disableRipple={btntype === 'cancel'}
    >
      {text}
    </Button>
  );
};
export default AHButton;
