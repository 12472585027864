export const getFullName = (firstName = '', lastName = '') => {
  return firstName + ' ' + lastName;
};

// ----> Get Days Difference Between Start To End of A Campaign
export const getDaysDifference = (date1: string, date2: string): number => {
  console.log(date1 + '   ' + date2);
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  const timeDiff: number = d2.getTime() - d1.getTime();
  const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
  console.log(daysDiff);
  return daysDiff;
};

export const getDate = (d: string) => {
  // let date = new Date(d);
  // let dateString = new Intl.DateTimeFormat('en-US').format(date);
  // return dateString;

  return formatDateUTC_MMDDYYY(d);
};

// ------> Format Date YYYY-MM-DD
export const formatDateUTC_MMDDYYY = (dateString: string): string => {
  let date = new Date(dateString);
  const month =
    date.getUTCMonth() + 1 < 10
      ? '0' + (date.getUTCMonth() + 1)
      : date.getUTCMonth() + 1;
  const day =
    date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate();
  let formattedDate = month + '-' + day + '-' + date.getUTCFullYear();
  return formattedDate;
};

// ------> Format Date YYYY-MM-DD
export const formatDateYYYMMDD = (dateString: string): string => {
  let date = new Date(dateString);
  const month =
    date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1;
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  let formattedDate = date.getFullYear() + '-' + month + '-' + day;
  return formattedDate;
};

export const formatStringToJSON = (jsonString: string) => {
  if (!jsonString) return null;
  let json = null;
  try {
    json = JSON.parse(jsonString);
  } catch (e) {
    json = null;
  }
  return json;
};
