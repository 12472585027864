import { baseUrl } from '../../config';
import { DEFAULT_FREQ_CAP } from '../../constants/defaults';
import { AuthRoutesEnum } from '../../Routing/Routes';
import { formatDateYYYMMDD, formatStringToJSON } from '../../utils/commonFns';
import { reverseFormatIntervalInSeconds } from '../../utils/freqCap';
import { IChangeCampValue } from './actions.interface';
import { ICampaigns } from '../../models/campaign';
import types from './types';
import API from '../../services/api';
import { IPublisher, TInventoryBranch } from '../../models/publisher';

// ************* GET ASSOCIATED CREATIVES *************
// ************* GET ASSOCIATED CREATIVES *************
// ************* GET ASSOCIATED CREATIVES *************

// ************* CHANGE CAMPAIGN VALUE *************
// ************* CHANGE CAMPAIGN VALUE *************
// ************* CHANGE CAMPAIGN VALUE *************
export const changeCampaignValue =
  (value: IChangeCampValue) =>
  (dispatch, getState): void => {
    const budgetType = getState()?.campaigns?.campaign?.budget_type;
    const kpiType = getState()?.campaigns?.campaign?.kpi_type;

    // if auto optimized cpm is selected then clear max-cpm

    // budget limit check depending on budget type
    if (value.name === 'budget') {
      if (budgetType === 'spend' || budgetType === 'cpa') {
        if (value.data === '') {
          dispatch({
            type: types.CHANGE_CAMPAIGN_DATA,
            payload: {
              name: value.name,
              data: value.data,
            },
          });
        }

        dispatch({
          type: types.CHANGE_CAMPAIGN_DATA,
          payload: {
            name: value.name,
            data: value.data,
          },
        });
      } else {
        if (value.data === '') {
          dispatch({
            type: types.CHANGE_CAMPAIGN_DATA,
            payload: {
              name: value.name,
              data: value.data,
            },
          });
        }
        if (parseInt(value.data) <= 20000000) {
          dispatch({
            type: types.CHANGE_CAMPAIGN_DATA,
            payload: {
              name: value.name,
              data: value.data,
            },
          });
        }
      }
    } else if (value.name === 'kpi_value') {
      if (value.data > 0) {
        dispatch({
          type: types.CHANGE_CAMPAIGN_DATA,
          payload: {
            name: value.name,
            data: value.data,
          },
        });
      }
    } else if (value.name === 'auto_optimize_cpm') {
      if (value.data) {
        dispatch({
          type: types.CHANGE_CAMPAIGN_DATA,
          payload: {
            name: 'max_cpm',
            data: '',
          },
        });
      }
      dispatch({
        type: types.CHANGE_CAMPAIGN_DATA,
        payload: {
          name: value.name,
          data: value.data,
        },
      });
    }
    //  else if (value.name === 'advanced') {
    //   console.log(value.data);
    //   dispatch({
    //     type: types.CHANGE_CAMPAIGN_DATA,
    //     payload: {
    //       name: value.name,
    //       data: formatStringToJSON(value.data),
    //     },
    //   });
    // }
    else {
      dispatch({
        type: types.CHANGE_CAMPAIGN_DATA,
        payload: {
          name: value.name,
          data: value.data,
        },
      });
    }
  };

// ************* ADD NEW FREQ CAP *************
// ************* ADD NEW FREQ CAP *************
// ************* ADD NEW FREQ CAP *************
export const addNewFreqCap =
  () =>
  (dispatch): void => {
    dispatch({
      type: types.ADD_NEW_FREQCAP,
    });
  };

// ************* DELETE FREQ CAP *************
// ************* DELETE FREQ CAP *************
// ************* DELETE FREQ CAP *************
export const deleteFreqCap = (index: number) => (dispatch) => {
  dispatch({
    type: types.DELETE_FREQCAP,
    payload: index,
  });
};

// ************* ADD FREQ CAP VALUE *************
// ************* ADD FREQ CAP VALUE *************
// ************* ADD FREQ CAP VALUE *************
export const addFreqCapValue =
  (index: number, type: string, value: number | string) =>
  (dispatch): void => {
    dispatch({
      type: types.ADD_FREQCAP_VALUE,
      payload: { index, type, value },
    });
  };

// ************* ADD FREQ CAP VALUE FOR EDIT *************
// ************* ADD FREQ CAP VALUE FOR EDIT *************
// ************* ADD FREQ CAP VALUE FOR EDIT *************
export const addFreqCapValueForEdit = (freq_cap) => (dispatch) => {
  dispatch({
    type: types.ADD_FREQCAP_VALUE_FOR_EDIT,
    payload: freq_cap,
  });
};

// ************* REMOVE CREATIVE *************
// ************* REMOVE CREATIVE *************
// ************* REMOVE CREATIVE *************
export const removeCreative = (id: number) => (dispatch) => {
  dispatch({
    type: types.REMOVE_CREATIVE,
    payload: id,
  });
};

// ************* CAMPAIGN CREATE *************
// ************* CAMPAIGN CREATE *************
// ************* CAMPAIGN CREATE *************
export const submitCampaign =
  (history, enqueueSnackbar) => (dispatch, getState) => {
    const advId = getState()?.user?.advertiserId;
    const admin = getState()?.user?.admin;
    const partialFreqCaps = getState()?.campaigns?.partialFreqCap;
    const defaultFreqCap = getState()?.campaigns?.defaultFreqCap;
    let freqCaps = [];

    partialFreqCaps.length > 0 &&
      partialFreqCaps.forEach((pfc) => {
        let fc = reverseFormatIntervalInSeconds(pfc);
        freqCaps = [...freqCaps, fc];
      });

    const { creative, id, list_url, budget_spent, ...campaign } =
      getState()?.campaigns?.campaign;

    if (list_url) campaign.list_url = list_url;

    campaign.start_date = formatDateYYYMMDD(campaign.start_date);
    campaign.end_date = formatDateYYYMMDD(campaign.end_date);
    // campaign.advanced_targeting = formatStringToJSON(
    //   campaign.advanced_targeting
    // );
    campaign.frequency_cap = freqCaps;
    campaign.pacing = parseInt(campaign.pacing);

    if (campaign.advanced_targeting.trim() === '') {
      delete campaign.advanced_targeting;
    } else {
      campaign.advanced_targeting = formatStringToJSON(
        campaign.advanced_targeting
      );
    }

    dispatch({ type: types.CHANGE_SUBMIT });

    API.post(`${baseUrl}/${advId}/campaign`, {
      data: { ...campaign },
    })
      .then((res) => {
        if (res.status === 200 && res?.data?.success) {
          dispatch({
            type: types.SET_DUPLICATE,
            payload: false,
          });

          if (admin)
            enqueueSnackbar(
              res?.data?.message || 'Campaign created Successfully.'
            );

          const creatives = getState().campaigns.campaign.creative;
          const campId = res.data.id;

          if (creatives.length > 0) {
            creatives.forEach((creative) => {
              if (creative.hasOwnProperty('filehost')) {
                const bodyFormData = new FormData();
                bodyFormData.append('creative_id', creative.id.toString());
                bodyFormData.append('campaign_id', campId);
                API({
                  method: 'post',
                  url: `${baseUrl}/${advId}/creative-campaign-assoc`,
                  data: bodyFormData,
                })
                  .then((assocRes) => {
                    history.push(AuthRoutesEnum.Dashboard);
                    console.log(assocRes);
                    dispatch({ type: types.CHANGE_SUBMIT });
                  })
                  .catch((error) => {
                    dispatch({ type: types.CHANGE_SUBMIT });
                    console.error(error);
                  });
              }
            });
          } else {
            history.push(AuthRoutesEnum.Dashboard);
            dispatch({ type: types.CHANGE_SUBMIT });
          }
        } else {
          dispatch({ type: types.CHANGE_SUBMIT });
          if (res.status === 200 && res?.data?.success === false) {
            enqueueSnackbar(
              `Create campaign could not be processed. ${res?.data?.message}`,
              { variant: 'error' }
            );
          }
        }
      })
      .catch((error) => {
        dispatch({ type: types.CHANGE_SUBMIT });
        if (error?.response?.data?.success === false) {
          enqueueSnackbar(
            `Create campaign could not be processed. ${error?.response?.data?.message}`,
            {
              variant: 'error',
            }
          );
        } else {
          enqueueSnackbar('Something went wrong while creating the campaign!');
        }
        console.error(error);
      });
  };

// ************* CALCULATE DEFAULT DAILY BUDGET *************
// ************* CALCULATE DEFAULT DAILY BUDGET *************
// ************* CALCULATE DEFAULT DAILY BUDGET *************
// export const calculateDailyBudgetDefault = (
//   budgetStr,
//   budget_type,
//   max_cpm,
//   start_date,
//   end_date
// ) => {
//   let budget = parseFloat(budgetStr) || 0;
//   let maxCpm = parseFloat(max_cpm) || 0;
//   let modifier = 0.75;

//   let timeDiff = new Date(end_date).getTime() - new Date(start_date).getTime();
//   let totalDays = timeDiff / (1000 * 3600 * 24);

//   if (~~totalDays === 0) {
//     totalDays = 1;
//   }

//   let dailyBudget = 0;

//   if (budget_type === 'spend') {
//     dailyBudget = Math.ceil((budget * 1.25) / totalDays);
//     if (dailyBudget < 0) {
//       dailyBudget = 1;
//     }
//   }

//   if (budget_type === 'impressions') {
//     // dailyBudget = Math.ceil((budget / totalDays) * 1.25);
//     dailyBudget = Math.ceil((budget * 1.25) / totalDays);
//     if (dailyBudget < 0) {
//       dailyBudget = 1;
//     }
//   }

//   if (budget_type === 'completed_audio_ads') {
//     // dailyBudget = Math.ceil(((budget / 0.95) * 1.25) / totalDays);
//     dailyBudget = Math.ceil((budget * 1.25) / totalDays);
//     if (dailyBudget < 0) {
//       dailyBudget = 1;
//     }
//   }

//   if (budget_type === 'cpa') {
//     dailyBudget = 5;
//   }

//   return Number.isInteger(dailyBudget) ? dailyBudget : dailyBudget.toFixed(2);
// };

// ************* ON EDIT & CHANGE CAMPAIGN DATA  *************
// ************* ON EDIT & CHANGE CAMPAIGN DATA  *************
// ************* ON EDIT & CHANGE CAMPAIGN DATA  *************
export const getAndEditCampaign =
  (campaign, history) => (dispatch, getState) => {
    const {
      name,
      id,
      budget,
      budget_spent,
      budget_type,
      max_cpm,
      auto_optimize_cpm,
      kpi_type,
      kpi_value,
      campaign_type,
      status,
      pacing,
    } = campaign;

    const advanced_targeting = Object.hasOwnProperty.call(
      campaign,
      'advanced_targeting'
    )
      ? campaign.advanced_targeting
      : '';

    const geo = Object.prototype.hasOwnProperty.call(campaign, 'geo')
      ? campaign?.geo
      : [];
    const listUrl = Object.prototype.hasOwnProperty.call(campaign, 'list_url')
      ? campaign.list_url
      : '';

    const startDate = campaign?.start_date;
    const endDate = campaign?.end_date;

    let frequencyCap = [];

    if (Object.prototype.hasOwnProperty.call(campaign, 'frequency_cap')) {
      if (campaign.frequency_cap.length > 0) {
        frequencyCap = campaign.frequency_cap;
      } else {
        frequencyCap = DEFAULT_FREQ_CAP;
      }
    } else {
      frequencyCap = DEFAULT_FREQ_CAP;
    }

    dispatch(changeCampaignValue({ name: 'name', data: name }));
    dispatch(changeCampaignValue({ name: 'start_date', data: startDate }));
    dispatch(changeCampaignValue({ name: 'end_date', data: endDate }));
    dispatch(changeCampaignValue({ name: 'kpi_type', data: kpi_type }));
    dispatch(changeCampaignValue({ name: 'kpi_value', data: kpi_value }));
    dispatch(changeCampaignValue({ name: 'max_cpm', data: max_cpm }));
    dispatch(
      changeCampaignValue({
        name: 'auto_optimize_cpm',
        data: auto_optimize_cpm,
      })
    );
    dispatch(changeCampaignValue({ name: 'budget', data: budget }));
    dispatch(changeCampaignValue({ name: 'budget_spent', data: budget_spent }));
    dispatch(changeCampaignValue({ name: 'budget_type', data: budget_type }));
    // dispatch(changeCampaignValue({ name: 'daily_budget', data: dailyBudget }));
    dispatch(changeCampaignValue({ name: 'geography', data: geo }));
    dispatch(changeCampaignValue({ name: 'list_url', data: listUrl }));
    dispatch(
      changeCampaignValue({ name: 'campaign_type', data: campaign_type })
    );
    dispatch(changeCampaignValue({ name: 'id', data: id }));
    dispatch(changeCampaignValue({ name: 'status', data: status }));
    dispatch(addFreqCapValueForEdit(frequencyCap));
    dispatch(changeCampaignValue({ name: 'pacing', data: pacing }));
    dispatch({
      type: types.SET_EDIT_CAMPAIGN,
    });

    dispatch(
      changeCampaignValue({
        name: 'advanced',
        data: advanced_targeting ? JSON.stringify(advanced_targeting) : '',
      })
    );

    history.push(AuthRoutesEnum.Information);
  };

/**
 * Download Campaign Data from the campaign Dropdown
 *
 * @param advID
 * @param campID
 * @param enqueueSnackbar
 */
export const downloadCampaignData = async (
  advID: string | number,
  campID: string | number,
  enqueueSnackbar: Function
): Promise<void> => {
  try {
    const res = await API.get(`${baseUrl}/${advID}/campaign-data`, {
      params: {
        campaign_ids: campID,
      },
      responseType: 'blob',
    });

    if (res.status === 200 || res.status === 204) {
      enqueueSnackbar(
        'You will receive an email with a link to download the data in the next 15 minutes',
        { variant: 'success' }
      );
    } else {
      enqueueSnackbar('Something went wrong downloading data!', {
        variant: 'error',
      });
    }
  } catch (err) {
    console.error(err);
    enqueueSnackbar('Something went wrong downloading data!', {
      variant: 'error',
    });
  }
};
/**
 * Download Aggregated Campaign Data from the campaign Dropdown
 *
 * @param advID
 * @param campID
 * @param enqueueSnackbar
 */
export const downloadAggregateData = async (
  advID: string | number,
  campID: string | number,
  enqueueSnackbar: Function
): Promise<void> => {
  try {
    const res = await API.get(`${baseUrl}/${advID}/campaign-aggregate-data`, {
      params: {
        campaign_ids: campID,
      },
      responseType: 'blob',
    });

    if (res.status === 200 || res.status === 204) {
      enqueueSnackbar(
        'You will receive an email with a link to download the data in the next 15 minutes',
        { variant: 'success' }
      );
    } else {
      enqueueSnackbar('Something went wrong downloading data!', {
        variant: 'error',
      });
    }
  } catch (err) {
    console.error(err);
    enqueueSnackbar('Something went wrong downloading data!', {
      variant: 'error',
    });
  }
};

// Set Item To Default Inventory
export const setPublisherToInventory =
  (data: IPublisher, inventoryBranch: TInventoryBranch) => (dispatch) => {
    interface IPayload extends IPublisher {
      branch: TInventoryBranch;
    }

    const payload: IPayload = {
      ...data,
      branch: inventoryBranch,
    };

    dispatch({
      payload,
      type: types.SET_INVENTORY,
    });
  };

export const handleToggoleBranch =
  (branch: TInventoryBranch, objectID: string) => (dispatch) => {
    console.log('handle click');
    dispatch({
      type: types.TOGGLE_INVENTORY_PUBLISHER,
      payload: {
        branch,
        id: objectID,
      },
    });
  };
