import React, { ReactElement } from 'react';
import Header from '../components/header/index';

interface IProps {
  children: ReactElement;
}

function WithHeaderLayoutSinglePage({ children }: IProps): any {
  return (
    <>
      <div className=" bg-gray-100 h-screen">
        <Header />
        <div className="flex justify-center max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
          {children}
        </div>
      </div>
    </>
  );
}
export default WithHeaderLayoutSinglePage;
