import React from 'react';
import './style.scss';

export interface ISubmitForm {
  label: string;
  type: string;
  activity: boolean;
  placeholder: string;
  inputValue: string;
  buttonLabel: string;
  formHandler: (event: any) => void;
  handleInputChange: (event: any) => void;
}

const SubmitForm: React.FC<ISubmitForm> = ({
  inputValue,
  activity,
  label,
  type,
  placeholder,
  buttonLabel,
  formHandler,
  handleInputChange,
}) => {
  return (
    <form className="submit-form" onSubmit={formHandler}>
      <label className="submit-form__label">{label}</label>
      <input
        value={inputValue}
        type={type}
        placeholder={placeholder}
        className="submit-form__input"
        required
        onChange={handleInputChange}
      />
      <button
        disabled={!activity}
        type="submit"
        className={`submit-form__button ${
          !activity && 'submit-form__button_disabled'
        }`}
      >
        {buttonLabel}
      </button>
    </form>
  );
};
export default SubmitForm;
