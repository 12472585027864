import React, { ReactElement, useEffect } from 'react';
import Header from '../components/header/index';
import SideBarDynamic from '../components/SideBarDynamic';
import './WithSideNav.scss';
import { useRouteMatch } from 'react-router-dom'
import { AuthRoutesEnum as paths } from '../Routing/Routes'



interface IProps {
  children: ReactElement;
  handleClick: (name: string) => void;
}

function WithSideNavLayout({ children, handleClick }: IProps): any {

  const { path } =  useRouteMatch()

  // console.log('team paths ', teamPaths[path])
  // console.log('team paths ', teamPaths)
  // console.log('team paths ', pathsArray)
  // console.log('team paths ', pathsArray.includes(path))

  // {/* {path === paths.Profile && <SideBarProfile handleClick={handleClick} />} */}
  // {/* this will see if the team path exists */}
  // {/* {advertPathsArray.includes(path) && <SideBarAdvertiser />} */}
  // {/* {path === `/advertisers/:id/credit-card-info` && <SideBarAdvertiser />} */}
  // {/* {listOfSideBarDynamicPaths.includes(path) && <SideBarDynamic handleClick={handleDynamicClick} /> } */}

  const listOfSideBarDynamicPaths = [paths.AddAdvertiser.toString()]
  return (
    <div className="wrapper">
      <Header />
      <div className="sidebar-layout-content">
        {<SideBarDynamic />}
        <div className="main-content">{children}</div>
      </div>
    </div>
  );
}

export default WithSideNavLayout;
