import { setCookie, getCookie } from './cookieService';
import axios from 'axios';
import { baseUrl } from '../config';
import { t } from '../store/actions/userActions'

export const setToken = (token: string): void => {
    if (token) {
        setCookie("audiohookAppSessionCookie", token, 7);
    } else {
        setCookie("audiohookAppSessionCookie", "", 0);
    }
};


export const getToken = () => {
    const token = getCookie('audiohookAppSessionCookie');
    return token ? token : null;
};

export const getNewToken = async (): Promise<string> => {
    const token = getToken();
    let updatedToken: string = '';
    try {
        const response = await axios.get(`${baseUrl}/${t.useNew ? 'renewToken' : 'renew-token'}`, {
            headers: {
                'x-access-token': token,
            },
        })
        if(response) {
          updatedToken = response?.data?.token;
        }
    }
    catch(error) {
        console.log(error);
        console.error(error);
    }

    return updatedToken;
}

const renewToken = async () => {
    let updatedToken: string = await getNewToken();
    if(updatedToken) setToken(updatedToken);
}

const runRenewToken = setInterval(renewToken, 300000);

export function stopRenewToken() {
    clearInterval(runRenewToken)
}