import API from 'services/api';
import { baseUrl } from 'config';
import types from '../types';

export const getAssociatedCreatives =
  (setFileDropped: Function, enqueueSnackbar: Function) =>
  (dispatch, getState) => {
    const advertiserId = getState().user?.advertiserId;
    const campaignId = getState().campaigns?.campaign?.id;
    if (campaignId) {
      dispatch({
        type: types.SET_LOADING,
        payload: true,
      });
      API.get(
        `${baseUrl}/${advertiserId}/creative-campaign-assoc/${campaignId}`
      )
        .then((res) => {
          console.log(res);
          if (res.status === 200 && res.data.success === true) {
            const creatives = res.data?.creatives;
            // if (Array.isArray(creatives) && creatives.length > 0) ;

            if (Array.isArray(creatives)) {
              creatives.forEach((creative) => {
                API.get(`${baseUrl}/${advertiserId}/creative/${creative}`).then(
                  (creativeRes) => {
                    if (
                      creativeRes.status === 200 &&
                      creativeRes.data.success === true
                    ) {
                      setFileDropped(true);
                      dispatch({
                        type: types.SET_CREATIVE,
                        payload: creativeRes.data?.data,
                      });
                    }
                  }
                );
              });
            }
          } else {
            enqueueSnackbar(
              `Creative associative could not be processed. ${res.data.message}`,
              { variant: 'error' }
            );
          }
        })
        .catch((error) => {
          console.error(error);
          enqueueSnackbar(
            `Creative associative could not be processed. ${error.response.data.message}`,
            { variant: 'error' }
          );
        })
        .finally(() => {
          dispatch({
            type: types.SET_LOADING,
            payload: false,
          });
        });
    }
  };
