import 'date-fns';
import React from 'react';
import { useDispatch } from 'react-redux';
import { changeCampaignValue } from '../../store/actions/campaignActions';
import Dropzone from '../dropzone';
import './style.scss';

interface ICreative {
  id: number;
  name: string;
  size: number;
  filehost: string;
}

const CreativeStep: React.FC = () => {
  const dispatch = useDispatch();
  const handleCreativeId = (creative: ICreative) => {
    if (creative.id) {
      dispatch(changeCampaignValue({ name: 'creative', data: creative }));
    }
  };
  return (
    <div className="creative-step">
      <div className="creative-step__title">Creative</div>
      <div className="creative-step__wrapper">
        <div
        id = "prefight"
        className="creative-step__dropzone">
          <Dropzone
            type="creative"
            acceptedFileType=".mp3, .wav"
            getFile={handleCreativeId}
            showCreateAd
          />
        </div>
      </div>
    </div>
  );
};
export default CreativeStep;
