import { useSnackbar } from 'notistack';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import CreativeStep from '../../components/creative-step';
import AudienceForm from '../../components/form/audience';
import OptimizationForm from '../../components/form/optimization/optimization';
import PublisherForm from '../../components/form/publisher/publisher';
import CampFormControl from '../../components/Information2/CampFormControl/CampFormControl';
import CampFormStepper from '../../components/Information2/CampFormStepper/CampFormStepper';
import WithHeaderLayout from '../../layout/WithHeaderLayout';
import { AuthRoutesEnum } from '../../Routing/Routes';
import { loadGeoFromAlgolia } from '../../utils/loadGeo';
import { submitCampaign } from 'store/actions/campaignActions';
import { submitEditCampaign } from 'store/actions/campaign/campaignEditAction';
import './information2.scss';
import './style.scss';

const steps = [
  'Basic Information & KPI Optimizations',
  'Audience & Geography',
  // 'Publishers',
  'Creative',
];

function renderStepComponent(step: number) {
  switch (step) {
    case 0:
      // return <PublisherForm />;
      return <OptimizationForm />;
    case 1:
      return <AudienceForm />;
    // case 2:
    //   return <PublisherForm />;
    case 2:
      return <CreativeStep />;
    default:
      return <OptimizationForm />;
  }
}

// Main Component Function
export const Information2: React.FC = function () {
  const dispatch = useDispatch();
  const history = useHistory();
  const [activeStep, setActiveStep] = React.useState(0);
  const editmode = useSelector((state: any) => state?.campaigns?.editMode);
  const duplicate = useSelector((state: any) => state?.campaigns?.duplicate);
  const storedEditMode = JSON.parse(localStorage.getItem('State'))?.editMode;
  const geo = useSelector((state: any) => state?.campaigns?.campaign?.geo);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // if editmode is not true then go to dashboard.
  React.useEffect(() => {
    if (storedEditMode !== editmode) {
      history.push(AuthRoutesEnum.Dashboard);
    }
    // Load Geography from Algolia
    dispatch(loadGeoFromAlgolia(geo));
    // Segment functions call to track
    window.analytics.track('visited create/edit campaign page');
  }, []);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSubmitCampaignForm = () => {
    window.analytics.track(
      `Submit Campaign ${editmode ? 'edit event' : 'create event'}`,
      {}
    );
    if (editmode) {
      if (duplicate) {
        dispatch(submitCampaign(history, enqueueSnackbar));
      } else {
        dispatch(submitEditCampaign(history, enqueueSnackbar));
      }
    } else {
      dispatch(submitCampaign(history, enqueueSnackbar));
    }
  };

  return (
    <div className="info-container">
      <WithHeaderLayout>
        <>
          <CampFormStepper step={activeStep} steps={steps} />
          <div className="info-form">
            <div className="info-campForm">
              {renderStepComponent(activeStep)}
            </div>

            <div className="info-controller">
              <CampFormControl
                activeStep={activeStep}
                steps={steps}
                handleSubmit={handleSubmitCampaignForm}
                handleBack={handleBack}
                handleNext={handleNext}
              />
            </div>
          </div>
        </>
      </WithHeaderLayout>
    </div>
  );
};
export default Information2;
