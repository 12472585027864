import React, { useEffect, useState } from 'react';
import { getPmps } from '../../../store/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
interface IProps {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  requestPmpsFlag: boolean; 
  setRequestPmpsFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const PrivateMarketplace = ({ openModal, setOpenModal, requestPmpsFlag, setRequestPmpsFlag }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const advId = useSelector((state: any) => state?.user?.advertiserId);

  let [pmps, setPmps] = useState([])
  let [isLoading, setIsLoading] = useState(true)
  let [noPmps, setNoPmps] = useState(true)

  useEffect(() => {
    getAndSetPmpsFunc()
  }, [])

  useEffect(() => {
    getAndSetPmpsFunc()
  }, [advId])

  const getAndSetPmpsFunc = async () => {
    setIsLoading(true)
    const pmpsDataRaw: any = await dispatch(getPmps(enqueueSnackbar))
    const pmpsData = pmpsDataRaw?.data ?? [] 
    // TODO put back in 
    setPmps(pmpsData)
    // TODO -important to put back after building
    setIsLoading(false)
  }

  useEffect(() => {
    if (requestPmpsFlag) {
      getAndSetPmpsFunc()
      setRequestPmpsFlag(false)
    }
  }, [requestPmpsFlag])

  useEffect(() => {
    const pmpsEmpty = pmps.length < 1
    setNoPmps(pmpsEmpty)
  }, [pmps])

  const formatDecimalPlaces = (numberString) => {
    const number = parseFloat(numberString)
    return number.toFixed(2)
  }

  const Row = ({ pmpItem }) => {
    return (
      <tr key={pmpItem.email}>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
          {pmpItem.name}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{pmpItem.deal_id}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">{formatDecimalPlaces(pmpItem.floor_cpm)}</td>
      </tr>
    )
  }

  return (
    <div>
     <div className="pt-4" />

      <div className="p-6">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">Private Marketplace</h1>
              <p className="mt-2 text-sm text-gray-700">
                
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                onClick={() => setOpenModal(true)}
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              >
                Add
              </button>
            </div>
          </div>
          <div className="mt-8 flex flex-col pb-24">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  {!isLoading && !noPmps && <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Name
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Deal Id
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-right text-gray-900">
                          Floor CPM
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {pmps.map((pmpItem) => (
                        <Row key={pmpItem.id} pmpItem={pmpItem} /> 
                      ))}
                    </tbody>
                  </table>}
                  {isLoading && noPmps && <div className="flex justify-center w-full place-items-center h-64">
                    <div>
                      loading ... 
                    </div>
                  </div>}
                  {noPmps && !isLoading && <div className="flex justify-center w-full place-items-center h-64">
                    <div>
                      There are currently no items in the private marketplace. 
                    </div>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};


export default PrivateMarketplace;