import { IntervalEnum } from '../constants/enums';
import { IFreqCap, IPartialFreqCap } from '../models/freqCap';

// calculated in HOURS

export const formatIntervalInHours = ({
  frequency,
  interval,
}: IFreqCap): IPartialFreqCap => {
  const res: IPartialFreqCap = {
    frequency: frequency,
    intervalValue: 0,
    intervalType: '',
  };

  const hours = interval;

  if (hours < 24) {
    res.intervalType = IntervalEnum.Hour;
    res.intervalValue = hours;
  }

  if (hours >= 24 && hours < 168) {
    res.intervalType = IntervalEnum.Day;
    //@ts-ignore
    res.intervalValue = ~~(interval / 24);
  }

  if (hours >= 168) {
    res.intervalType = IntervalEnum.Week;
    //@ts-ignore
    res.intervalValue = ~~(interval / 24 / 7);
  }

  return res;
};

export const reverseFormatIntervalInHours = ({
  frequency,
  intervalValue,
  intervalType,
}: IPartialFreqCap): IFreqCap => {
  const res: IFreqCap = {
    //@ts-ignore
    frequency: frequency,
    interval: 0,
  };

  if (intervalType === IntervalEnum.Hour) {
    res.interval = intervalValue;
  } else if (intervalType === IntervalEnum.Day) {
    //@ts-ignore
    res.interval = intervalValue * 24;
  } else if (intervalType === IntervalEnum.Week) {
    //@ts-ignore
    res.interval = intervalValue * 24 * 7;
  }

  return res;
};

// calculated in Seconds

export const formatIntervalInSeconds = ({
  frequency,
  interval,
}: IFreqCap): IPartialFreqCap => {
  const res: IPartialFreqCap = {
    frequency: frequency,
    intervalValue: 0,
    intervalType: '',
  };
  //@ts-ignore
  const hours = ~~(interval / 60 / 60);

  if (hours < 24) {
    res.intervalType = IntervalEnum.Hour;
    res.intervalValue = hours;
  }

  if (hours >= 24 && hours < 168) {
    res.intervalType = IntervalEnum.Day;
    //@ts-ignore
    res.intervalValue = ~~(interval / 60 / 60 / 24);
  }

  if (hours >= 168) {
    res.intervalType = IntervalEnum.Week;
    //@ts-ignore
    res.intervalValue = ~~(interval / 60 / 60 / 24 / 7);
  }

  return res;
};

export const reverseFormatIntervalInSeconds = ({
  frequency,
  intervalValue,
  intervalType,
}: IPartialFreqCap): IFreqCap => {
  const res: IFreqCap = {
    frequency: frequency,
    interval: 0,
  };

  if (intervalType === IntervalEnum.Hour) {
    //@ts-ignore
    res.interval = intervalValue * 60 * 60;
  } else if (intervalType === IntervalEnum.Day) {
    //@ts-ignore
    res.interval = intervalValue * 60 * 60 * 24;
  } else if (intervalType === IntervalEnum.Week) {
    //@ts-ignore
    res.interval = intervalValue * 60 * 60 * 24 * 7;
  }

  return res;
};

export const isFreqCapsDefault = (fcs) => {
  if (fcs.length !== 3) return false;

  while (fcs.length > 0) {
    const fc = fcs.pop();

    if (fc.frequency === 2) {
      if (fc.interval !== 86400) return false;
    } else if (fc.frequency === 4) {
      if (fc.interval !== 604800) return false;
    } else if (fc.frequency === 8) {
      if (fc.interval !== 2419200) return false;
    } else {
      return false;
    }
  }
  return true;
};
