import { baseUrl } from '../config';
import types from '../store/actions/types';
import API from '../services/api';

interface IEmailListProps {
  fileUrl: string,
  getFile: Function,
  advertiserId: number,
  setAddNewFile: Function,
  setFileDropped: Function
}
export const getEmailList = (props: IEmailListProps) => dispatch => {

  const {fileUrl, advertiserId, getFile, setAddNewFile, setFileDropped } = props;

  // api call for file url
  const bodyFormData = new FormData();
  bodyFormData.append('list_url ', fileUrl);
  
  API({
    method: 'post',
    url: `${baseUrl}/${advertiserId}/upload-list`,
    data: bodyFormData,
  })
    .then((res) => {
      if (res.status === 200 && res.data.success === true) {
        getFile(res?.data?.result)
        setAddNewFile(false);
        setFileDropped(true);
      }
    })
    .catch((error) => {
      console.log(error);
      getFile("");
    })
    .finally(() => {
      dispatch({ type: types.SET_FILE_UPLOADING, payload: false });
    });
};

interface ICreativeProps {
  fileUrl: string,
  fileName: string,
  file: any,
  getFile: Function,
  advertiserId: number,
  setAddNewFile: Function,
  setFileDropped: Function,
  enqueueSnackbar: Function
}
export const getCreative = (props: ICreativeProps) => (dispatch) => {
  const { fileUrl, getFile, fileName, file, advertiserId, setAddNewFile, setFileDropped, enqueueSnackbar } = props;
  
  const bodyFormData = new FormData();
  bodyFormData.append('audio_url', fileUrl);
  bodyFormData.append('filename', fileName);

  API({
    method: 'post',
    url: `${baseUrl}/${advertiserId}/creative`,
    data: bodyFormData,
  })
    .then((res) => {
      console.log(res);
      if (res.status === 200) {
        const creativeId = res.data.id;
        getFile({
          id: creativeId,
          name: file.name,
          size: file.size,
          filehost: "local"
        });
        setAddNewFile(false);
        setFileDropped(true);
      }
      else {
        enqueueSnackbar(`Error code ${res.status}.`, { variant: "error" });
      }
    })
    .catch((error) => {
      console.log(error)
      if (error.response) {
        if (error?.response?.data?.success === false) {
          enqueueSnackbar(error?.response?.data?.message || "Something went wrong while creating a creative!", {
            variant: 'error',
          });
        }
      }
      else {
        enqueueSnackbar("Something went wrong while creating a creative!");
      }
      getFile(NaN);
    })
    .finally(() => {
      dispatch({ type: types.SET_FILE_UPLOADING, payload: false });
    });
};
