import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'rsuite/dist/styles/rsuite-default.css';
import CampaignTable from '../../components/CampaignTable';
import ChartComponent from './components/ChartsComponent';
import ChartSelect from '../../components/chart-implement/ChartSelect/ChartSelect';
import Search from '../../components/search';
import WithHeaderLayout from '../../layout/WithHeaderLayout';
import { ICampaignTableItem } from '../../models';
import { getCampaigns } from 'store/actions/campaign/getCampaignsAction';
import { getReportData } from '../../store/actions/dashboardActions';
import types from '../../store/actions/types';
import { getFullName } from '../../utils/commonFns';
import ddh from '../../utils/DashboadDataHandler';
import './style.scss';


const Dashboard: React.FC = () => {

  const dispatch = useDispatch();
  const reports = useSelector((state: any) => state?.dashboard?.reporting);
  const user = useSelector((state: any) => state?.user);
  const { id: userId, firstName, lastName, email } = user;
  const campaigns = useSelector((state: any) => state?.campaigns?.campaigns);
  const [tableData, setTableData] = useState([]);

  const advertiserId = useSelector((state: any) => { 
    const stateUser = state?.user || {} 
    return stateUser.advertiserId
  })

  const [dateRange, setDateRange] = useState([
    ddh.getPreviousMonth(),
    new Date(),
  ]);
  const [reportData, setReportData] = useState({
    daily: [],
    historical: [],
    total: [],
    attributed_sessions: [],
  });

  const [selected, setSelected] = useState(null);
  const [ selectedTab, setSelectedTab ] = useState('impressions');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // ********* Effect Hooks *********
  useEffect(() => {
    getCampaignsFunc()
    dispatch({ type: types.UNSET_DEFAULT_GEO });
    dispatch({ type: types.CLEAR_EDIT_CAMPAIGN });
    window.scrollTo(0, 0);
    window.analytics.track('viewed dashboard', {});
    // window.Beacon('open');
    return () => {

        // Anything in here is fired on component unmount.
    }
  }, []);

  const getCampaignsFunc = () => {
    dispatch(getCampaigns(enqueueSnackbar, closeSnackbar));
  }


  React.useEffect(() => {
    window.analytics.identify(userId, {
      email,
      name: `${firstName} ${lastName}`,
    });
  }, [userId]);

  useEffect(() => {
    dispatch(getReportData(dateRange, enqueueSnackbar));
  }, [dateRange]);

  useEffect(() => {
    dispatch(getReportData(dateRange, enqueueSnackbar));
  }, [advertiserId]);

  useEffect(() => {
    setTableData(campaigns); 
  }, [campaigns]);

  useEffect(() => {
    getCampaignsFunc()
  }, [advertiserId])

  useEffect(() => {
    const { daily, historical, total, attributed_sessions } = reports;
    const rd = {
      daily,
      historical,
      total,
      attributed_sessions,
    };
    setReportData(rd);
  }, [reports]);

  useEffect(() => {
    const filterData = (key: string) => {
      return reports[key].filter((item) => item.CAMPAIGN_ID === selected.id);
    };

    if (selected) {
      setReportData({
        daily: filterData('daily'),
        total: filterData('total'),
        historical: filterData('historical'),
        attributed_sessions: filterData('attributed_sessions'),
      });
    } else {
      setReportData(reports);
    }
  }, [selected]);

  useEffect(() => {
    window.Beacon('identify', {
      name: getFullName(firstName, lastName),
      email: email,
    });
  }, [firstName, lastName, email]);

  // ********* Handler Methods *********

  const campaignSearch = (lookup: string) => {
    window.analytics.track('Search Campaign', {
      keyword: lookup,
    });
    const data = campaigns.filter((item) =>
      item.name.toLowerCase().includes(lookup.toLowerCase())
    );
    setTableData(data);
  };

  const getCampaignImpression = (campId: string) => {
    return ddh.generateImpressionForTable(campId, reports);
  };

  const getCampaignBudgetSpent = (campId: number, budgetType: string) => {
    const spend: number = parseFloat(
      ddh.generateCostForTable(campId, budgetType, reports)
    );
    return spend;
  };

  const getCampaignRoas = (campId: number) => {
    return ddh.generateCampaignRoasForTable(campId, reports);
  };

  const handleSelectRow = (row: ICampaignTableItem) => {
    setSelected(selected ? (selected?.id === row?.id ? null : row) : row);
  };

  return (
    <>
      <WithHeaderLayout>
        <div className="content bg-gray-100 px-8 pt-8">
          <div className="pb-6">
            <ChartSelect 
              dateRange={dateRange} 
              setDateRange={setDateRange} 
              selectedTab={selectedTab} 
              setSelectedTab={setSelectedTab}
              reportData={reportData} 
            />
          </div>
          {/* NOTE: keep for ref */}
          {/* <div className="pt-8 pb-5">
            <ChartComponent reportData={reportData} />
          </div> */}
          <div className="content-header pb-5">
            <div className="padding-wrapper">
              <Search
                // @ts-ignore
                campaignSearch={campaignSearch}
              />
            </div>
          </div>
          <div className="content-table">
            <CampaignTable
              tableData={tableData}
              getCampaignImpression={getCampaignImpression}
              getCampaignBudgetSpent={getCampaignBudgetSpent}
              getRoas={getCampaignRoas}
              dateRange={dateRange}
              handleSelectRow={handleSelectRow}
              selected={selected}
            />
          </div>
        </div>
      </WithHeaderLayout>
    </>
  );
};

export default Dashboard;
