import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { AuthRoutesEnum } from '../../Routing/Routes';
import { logoutAction } from '../../store/actions/userActions';
import AvatarComponent from '../common/Avatar/index';
import Dropdown from './Dropdown';
import AdvertSwitch from '../AdvertSwitch'
// Assets
import './style.scss';

const logoImg = '/images/logo.svg';
const avatarImg = '/images/avatar.png';
const dropdownArrowImg = '/images/dropdown-arrow.svg';
// const notificationImg = '/images/notification.svg';
const logoutImg = '/images/logout.svg';

const Header = () => {
  // ---> State Variables
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // ---> Hooks
  const history = useHistory();
  const dispatch = useDispatch();

  //----> Redux
  const profilePic = useSelector((state: any) => state?.user?.profilePic);
  const advertiserName = useSelector(
    (state: any) => state?.user?.advertiserName
  );

  // ---> Handler Functions

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logoutAction());
  };

  return (
    <div className="header">
      <div className="header-right">
        <Link to={AuthRoutesEnum.Dashboard} className="logo">
          <img src={logoImg} />
        </Link>
      </div>
      <div className="header-left">
        <div className="user">
          {/* <div className="user-notification">
            <IconButton>
              <Badge color="secondary" variant="dot">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </div> */}

          <AdvertSwitch />
          <div
            className="user-avatar"
            onClick={handleMenuOpen}
            aria-controls="simple-menu"
            aria-haspopup="true"
          >
            <AvatarComponent src={profilePic} />
          </div>
          {anchorEl && (
            <div className="user-menu">
              <Dropdown handleClose={handleMenuClose} logout={handleLogout} />
            </div>
          )}

          {/* <Menu
            className="user-menu"
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem>
              <div className="user-info">
                <Typography variant="h6">
                  {generateFullName(firstName, lastName)}
                </Typography>
                {/* <h4 className="user-name">
                  
                </h4> 
                 <p className="user-email">{email}</p>
              </div> 
            </MenuItem>
            <Divider />
            <MenuItem>Profile</MenuItem>
            <MenuItem>My account</MenuItem>
            <MenuItem>Logout</MenuItem>
          </Menu>  */}

          {/* <div className="user-info">
            <div className="user-name">
              {first_name} {last_name}
            </div>
            <div className="user-email">{email}</div>
          </div> */}

          {/* <div className="user-avatar">
            <img className="user-avatarImg" src={avatarImg} />
            <img className="user-dropdownIcon" src={dropdownArrowImg} />
          </div> */}

          {/* <div className="user-separator" /> */}
          {/* <div className="user-notification">
            <img src={notificationImg} />
          </div> */}
          {/* <div className="user-logout" onClick={handleLogout}>
            <img src={logoutImg} />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Header;
