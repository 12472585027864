import axios from 'axios';
import types from '../store/actions/types';

// retrieve geo from algolia server
export const loadGeoFromAlgolia = (geo) => (dispatch) => {
    if (Array.isArray(geo) && geo.length > 0) {
        if (geo[0]?.include.hasOwnProperty('objectIDs')) {
            if (geo[0]?.include?.objectIDs.length > 0) {
                geo[0].include.objectIDs.forEach((_id) => {
                    dispatch({
                        type: types.SET_LOADING,
                        payload: true,
                    });
                    axios
                        .get(
                            `https://MGCCS3YDN4-dsn.algolia.net/1/indexes/ui_location/${_id}`,
                            {
                                headers: {
                                    'X-Algolia-API-Key': '3bf0b2a55f48991cd31a68548528db8a',
                                    'X-Algolia-Application-Id': 'MGCCS3YDN4',
                                },
                            }
                        )
                        .then((res) => {
                            console.log(res);
                            if (res.status === 200) {
                                dispatch({
                                    type: types.SET_DEFAULT_GEO,
                                    payload: res.data,
                                });
                            }
                        })
                        .finally(() => {
                            dispatch({
                                type: types.SET_LOADING,
                                payload: false,
                            });
                        });
                });
            }
        }
    }
};