import React from 'react';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { changeCampaignValue } from '../../../../../store/actions/campaignActions';
import './style.scss';

interface Props {}

const AdvanceComponent = (props: Props) => {
  const [advanced, setAdvanced] = React.useState(false);
  const advancedTargeting = useSelector(
    (state: any) => state?.campaigns?.campaign?.advanced_targeting
  );
  // console.log(advancedTargeting);
  const dispatch = useDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      changeCampaignValue({
        name: 'advanced',
        data: e.target.value,
      })
    );
  };

  return (
    <div className="advance_wrapper">
      <button onClick={() => setAdvanced(!advanced)}>
        <span>Advanced</span>
        {advanced ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </button>
      {advanced && (
        <TextField
          data-testid="adv-field"
          className="advanced_field"
          value={advancedTargeting}
          onChange={handleChange}
          multiline
          minRows={6}
          placeholder="Enter Audiohook Advanced Targeting JSON"
          variant="outlined"
        />
      )}
    </div>
  );
};
export default AdvanceComponent;
