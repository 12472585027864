import React, { useState } from 'react';
import PixelInfo from '../../components/AdvertiserContents/PixelInfo';
import CreditCardInfo from '../../components/ProfileContents/CreditCardInfo';
import TeamSettings from '../../components/AdvertiserContents/TeamSettings';
import WithSideNavLayoutV2 from '../../layout/WithSideNavLayout';
import { useParams, useRouteMatch } from 'react-router-dom'
import { paths } from '../../Routing/teamRoutes'
import AddPmpModal from '../../components/AdvertiserContents/PrivateMarketplace/AddPmpModal'

import PrivateMarketplace from '../../components/AdvertiserContents/PrivateMarketplace'

const r = {
  teamSettings: '/advertisers/:id/team-settings',
  creditCardInfo: '/advertisers/:id/credit-card-info',
  pixelInfo: '/advertisers/:id/pixel-info',
  pmp: '/advertisers/:id/private-marketplace',
}

const getPage = (
    path: string, 
    id: string,
    openModal: boolean,
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>,
    requestPmpsFlag: boolean,
    setRequestPmpsFlag: any) => {
  if (path === paths.teamSettings) {
    return <TeamSettings />;
  } else if (path === paths.creditCardInfo) {
    return <CreditCardInfo />;
  } else if (path === r.pixelInfo) {
    return <PixelInfo />;
  } else if (path === paths.pmp) {
    return <PrivateMarketplace requestPmpsFlag={requestPmpsFlag} setRequestPmpsFlag={setRequestPmpsFlag} openModal={openModal} setOpenModal={setOpenModal} />
  } else {
    return <div>Error </div>
  }
};

const Advertisers = () => {
  const { path } =  useRouteMatch()
  const { id }: { id: string } = useParams()

  const [openModal, setOpenModal] = useState(false)
  const [page, setPage] = useState(paths.teamSettings);

  let [requestPmpsFlag, setRequestPmpsFlag] = useState(false)

  const handleSetPageContent = (path: string) => {
    setPage(path);
  };
  
  // TODO make sure you need relative and absolute -- padding issue here
  return (
    <>
      <div style={{ background: '#F2F2F2' }}>
        <WithSideNavLayoutV2 handleClick={handleSetPageContent}>
          {getPage(path, id, openModal, setOpenModal, requestPmpsFlag, setRequestPmpsFlag)}
        </WithSideNavLayoutV2>
      </div>
      <div className="relative hidden">
        <div className="absolute ">
          <AddPmpModal setRequestPmpsFlag={setRequestPmpsFlag} openModal={openModal} setOpenModal={setOpenModal} />
        </div> 
      </div>
    </>
  );
};
export default Advertisers;
