import React, { Fragment, useState, useMemo, useEffect } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { CogIcon, ChevronDownIcon, PlusIcon, SwitchHorizontalIcon } from '@heroicons/react/solid'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom'
import types from '../../store/actions/types';
import Button from '../Button'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const AdvertSwitch = () => {

  const history = useHistory()

  const dispatch = useDispatch()

  const advertisersFromState = useSelector((state: any) => { 
    const stateUser = state?.user || {} 
    return stateUser.advertisers 
  })

  const advertiserName = useSelector((state: any) => { 
    const stateUser = state?.user || {} 
    return stateUser.advertiserName
  })

  let [advertisers, setAdvertisers] = useState([
    {
      id: -1,
      name: 'blank'
    },
  ]) 

  useEffect(() => {
    if (advertisersFromState) {
      let advertsFromState = JSON.parse(JSON.stringify(advertisersFromState))
      advertsFromState.sort(function(a, b){
          const aName = a.name.toLowerCase()
          const bName = b.name.toLowerCase()

          if(aName < bName) { return -1; }
          if(aName > bName) { return 1; }
          return 0;
      })

      const organizedAdvertisers =  advertsFromState

      setAdvertisers(organizedAdvertisers)
    }
  }, [advertisersFromState])


  const handleAdvertSwitch = async (advertiser) => {
    const payload = {
      advertiserId: advertiser.id,
      advertiserName: advertiser.name,
    }

    await dispatch({
      type: types.SET_USER,
      payload,
    });
  }

  const handleAdvertiserNavigate = async (advertiser: any) => {
    const advertiserId = advertiser.id
    await handleAdvertSwitch(advertiser)
    history.push(`/advertisers/${advertiserId}/team-settings`)
  }

  const Multiple = () => {

    const [hoverId, setHoverId] = useState(-1)

    return (
      <Menu as="div" className="ml-3 z-10 relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex justify-center w-full rounded-md px-4 py-2 bg-white text-sm font-medium text-gray-700 focus:outline-none focus:ring-transparent ">
            {advertiserName ?? "loading..."}
            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>


        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="divide-y">
              <div className="overflow-auto max-h-64">
                {advertisers.map((advertiser) => {
                return (<Menu.Item key={advertiser.id} >
                  {({ active }) => (
                    <div 
                      onMouseEnter={() => setHoverId(advertiser.id)}
                      onMouseLeave={() => setHoverId(-1)}
                      className="flex z-20 w-full text-gray-500 hover:bg-purple-500 hover:text-white focus:bg-purple-600 focus:text-white py-1" >
                      <div
                        className={classNames(
                          active ? ' ' : 'text-gray-700',
                          'block px-4 py-2 text-sm cursor-pointer w-full'
                        )}
                        onClick={() => handleAdvertSwitch(advertiser)}
                      >
                        {advertiser?.name ?? "loading..."}
                      </div>
                      <div onClick={() => handleAdvertSwitch(advertiser)} className={`z-30 flex justify-center cursor-pointer place-items-center`} >
                        <SwitchHorizontalIcon className={`mx-2 h-5 w-5 text-white`} aria-hidden="true" /> 
                      </div> 
                      <div onClick={() => handleAdvertiserNavigate(advertiser)} className="flex cursor-pointer place-items-center   ">
                        <CogIcon className="mx-4 h-5 w-5" aria-hidden="true" />
                      </div>
                    </div>
                  )}
                </Menu.Item>)})}
              </div> 
              <Menu.Item key="-2" >
                {({ active }) => (
                  <div
                    onClick={() => history.push(`/add-advertiser`)}
                    className="flex p-2 justify-center w-full cursor-pointer bg-gray-50  overflow-hidden rounded-b-md">
                    <Button appendClasses="w-full flex justify-center bg-purple-400 text-white hover:bg-purple-500 focus:bg-purple-600" useChild={true} ><div className="">+ Add Advertiser </div>  </Button>
                  </div>
                  // TODO put this back in in case the button isn't liked
                  // <div
                  //   onClick={() => history.push(`/add-advertiser`)}
                  //   className="flex py-2 justify-start w-full cursor-pointer bg-gray-100 hover:bg-gray-200 focus:bg-gray-300 overflow-hidden rounded-b-md">
                  //   <div className="flex place-items-center   ">
                  //     <PlusIcon className="mx-4 h-5 w-5" aria-hidden="true" />
                  //   </div>
                  //   <div
                  //     className={classNames(
                  //       active ? 'text-gray-900' : 'text-gray-700',
                  //       'block py-2 text-sm'
                  //     )}
                  //   >
                  //     <Button label="+ Add Advertiser" />
                  //   </div>
                  // </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )

  }

  return (
    <div className="mr-2">
      <Multiple />
    </div>
  )
}


export default AdvertSwitch;