import { useEffect, useState } from 'react'

const useDebug = () => {
  const [isDebugMode, setIsDebugMode] = useState(false)

  useEffect(() => {
    const dValue = window.localStorage.getItem('d')
    if (dValue === 'e') { 
      setIsDebugMode(true) 
    }
  }, [])


  return isDebugMode
} 

export default useDebug;