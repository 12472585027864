import { baseUrl } from '../../config';
import { formatDateYYYMMDD } from '../../utils/commonFns';
import ddh from '../../utils/DashboadDataHandler';
import types from './types';
import API from '../../services/api';

/**
 * Get Report Data From API
 */
export const getReportData =
  (range, enqueueSnackbar) => (dispatch, getState) => {
    const id = getState().user.advertiserId;

    dispatch({
      type: types.SET_REPORT_LOADING,
      payload: true,
    });

    API.get(`${baseUrl}/${id}/reporting`, {
      params: {
        start_date: formatDateYYYMMDD(range[0]),
        end_date: formatDateYYYMMDD(range[1]),
        test: 1,
      },
    })
      .then((res) => {
        console.log(res);
        if (res.status === 200 && res.data.success === true) {
          dispatch({
            type: types.GET_REPORTING_DATA,
            payload: res.data.data,
          });
        } else {
          enqueueSnackbar(
            `Load report data could not be processed. ${res.data.message}`,
            { variant: 'error' }
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar(
            `Load report data could not be processed. ${error.response.data.message}`,
            { variant: 'error' }
          );
        } else if (error.request) {
          enqueueSnackbar('Network Error!', { variant: 'error' });
        }
      })
      .finally(() => {
        dispatch({
          type: types.SET_REPORT_LOADING,
          payload: false,
        });
      });
  };

/**
 * Calculate Remaining Budget
 */
type TRemainingBudget = { campId: number; budgetType: string; budget: number };

export const getRemainingBudget =
  (params: TRemainingBudget) =>
  (dispatch, getState): string => {
    const { campId, budgetType, budget } = params;
    const reportData = getState()?.dashboard?.reporting;
    const budgetSpent: number = parseFloat(
      ddh.generateCostForTable(campId, budgetType, reportData)
    );
    return (budget - budgetSpent).toFixed(2);
  };
