import React from 'react';
import useAdvertiserUuid from '../../../hooks/user';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

const PixelInfo = function (): JSX.Element {


  const uuid = useAdvertiserUuid();

  return (
    <div className="px-8 pt-8">
      <div className="max-w-4xl">
        <div className="pb-8">
          <div className="text-lg font-bold">Pixel Integration Instructions</div>
        </div>
        <p className='pb-4'>
          Please read all the instructions below before beginning.  There are two steps to installing the pixel correctly.
        </p>
        <p>
          <b>Step 1:</b> Please copy and paste the code below in the body of all pages of your site. Put the pixel as close
          to the the bottom of the body as possible.
        </p>
      </div>
      <div className="pb-8">
        <SyntaxHighlighter language="html">
          {`<img src="https://listen.audiohook.com/${uuid}/pixel.png"  style="display: none;">`}
        </SyntaxHighlighter>
      </div>
      <div className="max-w-4xl ">
        <p className=''>
          <b>Step 2:</b> To track attributed purchase conversions, include the following pixel on your order confirmation page, please
          append the order id as well as the associated value to the end of the pixel as shown below.
        </p>
      </div>
      <div className="pb-8">
        <SyntaxHighlighter language="html">
          {`<img src="https://listen.audiohook.com/${uuid}/pixel.png?order={{ORDER-ID}}&value={{ORDER-AMOUNT-USD}}"  style="display: none;">`}
        </SyntaxHighlighter>
        <p className='pb-4'>
          The values in curly brackets (including the brackets) need to be replaced with actual data, The values with curly
          brackets are commonly referred to as macros. Please refer to the provider of your shopping cart to get the values
          populated properly.
        </p>
      </div>
    </div>
  );
};
export default PixelInfo;
