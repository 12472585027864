import React, { ReactElement } from 'react'
interface IProps {
  children: ReactElement;
}

const Card: React.FC = ({ children }: IProps) => {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg ">
      {children}
    </div>
  )
}

export default Card;