import types from '../actions/types';


const initialState = {
    profileVisibility: true,
    team: [],
    card: {
        last4digits: "",
        isEdit: false
    }
};

const profileReducer = (state = initialState, action) => {

    switch(action.type) {

        case types.SET_TEAM: 
            return {
                ...state,
                team: action.payload
            }
        case types.REMOVE_TEAM_MEMBER: {
            let team = state.team;
            let id = action.payload

            const updatedTeam = !id ? team : team.filter(item => item.id != id);
            return {
                ...state,
                team: updatedTeam
            }
        }
        case types.SET_CARD_DIGIT: {
            const card = state.card;
            card.last4digits = action.payload;
            return {
                ...state,
                card
            }
        }

        case types.SET_CARD_EDITING: {
            const card = state.card;
            card.isEdit = action.payload;
            return {
                ...state,
                card
            }
        }
            
        default:
            return state;
    }

}

export { profileReducer };

