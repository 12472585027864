import React from 'react';
import CCForm from './CCForm';
import './style.scss';

const CreditCardInfo = function (): JSX.Element {
  return (
    <div data-testid="cc-wrapper">
      <div className="cc-title-box">
        <h3 className="cc-title">Credit Card Info</h3>
      </div>
      <div className="cc-content">
        <CCForm />
      </div>
    </div>
  );
};
export default CreditCardInfo;
