import { DEFAULT_FREQ_CAP } from '../../constants/defaults';
import { IntervalEnum, PartialFreqCampEnum } from '../../constants/enums';
import ddh from '../../utils/DashboadDataHandler';
import { IPartialFreqCap } from '../../models/freqCap';
import types from '../actions/types';
import { CampaignTypeEnum, InventoryBranchEnum } from '../../constants/enums';

const initialState = {
  campaign: {
    id: '',
    name: '',
    start_date: new Date(),
    end_date: ddh.getNextMonth(),
    auto_optimize_cpm: true,
    max_cpm: '',
    status: 0,
    campaign_type: 'retargeting',
    kpi_type: '',
    kpi_value: '',
    budget: '',
    budget_type: 'cpa',
    daily_budget: '',
    freq_version: 2,
    frequency_cap: [],
    geo: [
      {
        exclude: { city: [], region: [], objectIDs: [] },
        include: { city: [], region: [], objectIDs: [] },
      },
    ],
    creative: [],
    list_url: '',
    budget_spent: '',
    pacing: '1',
    advanced_targeting: '',
  },
  defaultGeo: [],
  defaultInventory: [],
  campaigns: [],
  partialFreqCap: [],
  defaultFreqCap: true,
  fileUploading: false,
  editMode: false,
  loading: false,
  submit: false,
  duplicate: false,
  deleteCreative: [],
  campaignType: CampaignTypeEnum.PERFORMANCE,
};

const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    // ************* SET INVENTORY *****************
    case types.SET_INVENTORY: {
      return {
        ...state,
        defaultInventory: [...state.defaultInventory, action.payload],
      };
    }

    // *************** TOGGLE INVENTORY PUBLISHER BRANCH
    case types.TOGGLE_INVENTORY_PUBLISHER: {
      const { branch, id } = action.payload;
      const publishers = state?.defaultInventory;
      let modPublishers = publishers.map((item) => {
        if (item.objectID === id) {
          item.branch =
            branch === InventoryBranchEnum.EXCLUDE
              ? InventoryBranchEnum.EXCLUDE
              : InventoryBranchEnum.INCLUDE;
        }
        return item;
      });

      return {
        ...state,
        defaultInventory: modPublishers,
      };
    }
    // ************** SET CAMPAIGN TYPE **************
    case types.SET_CAMPAIGN_TYPE:
      return {
        ...state,
        campaignType: action.payload,
      };
    // ************** SET DELETE CREATIVE **************
    case types.SET_EMAIL_LIST: {
      const camp = state.campaign;
      camp.list_url = action.payload;

      return {
        ...state,
        campaign: { ...camp },
      };
    }

    // ************** SET DELETE CREATIVE **************
    case types.SET_DELETE_CREATIVE:
      return {
        ...state,
        deleteCreative: [...state.deleteCreative, action.payload],
      };

    // ************** STORE PARTIAL FREQCAP **************
    case types.STORE_PARTIAL_FREQCAP:
      return {
        ...state,
        partialFreqCap: action.payload,
      };

    // ********* SET Default FreqCap
    case types.SET_DEFAULT_FREQCAP:
      return {
        ...state,
        defaultFreqCap: action.payload ? true : false,
      };
    // ************** ADD NEW PARTIAL FREQCAP **************
    case types.ADD_NEW_PARTIAL_FREQCAP: {
      let newPartialFreqCap: IPartialFreqCap = {
        frequency: '',
        intervalValue: '',
        intervalType: IntervalEnum.Hour,
      };
      return {
        ...state,
        partialFreqCap: [...state.partialFreqCap, newPartialFreqCap],
      };
    }

    // ************** UPDATE PARTIAL FREQCAP **************
    case types.UPDATE_PARTIAL_FREQCAP: {
      let { index, type, value } = action.payload;
      const partialFreqCaps = state.partialFreqCap;

      if (
        type === PartialFreqCampEnum.Value ||
        type === PartialFreqCampEnum.Freq
      ) {
        value = Number.parseInt(value);
      }

      let updatedPFCs = partialFreqCaps.map(
        //@ts-ignore
        (pfc: IPartialFreqCap, i: number) => {
          if (index === i) {
            pfc[type] = value;
          }
          return pfc;
        }
      );

      return {
        ...state,
        partialFreqCap: updatedPFCs,
      };
    }

    // ************** SET DUPLICATE ***************
    case types.SET_DUPLICATE:
      return {
        ...state,
        duplicate: action.payload,
      };

    // ************** DELETE PARTIAL FREQCAP ***************
    case types.DELETE_PARTIAL_FREQCAP: {
      const partialFreqCaps = state.partialFreqCap;

      const updatedPFCs = partialFreqCaps.filter(
        //@ts-ignore
        (pfc: IPartialFreqCap, i: number) => i != action.payload
      );
      return {
        ...state,
        partialFreqCap: updatedPFCs,
      };
    }

    // ************** CLEAR PARTIAL FREQCAP ***************
    case types.CLEAR_PARTIAL_FREQCAP: {
      return {
        ...state,
        partialFreqCap: [],
      };
    }

    // ************** SET LOADING **************
    case types.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case types.STORE_CAMPAIGN_DATA:
      return {
        ...state,
        campaigns: action.payload,
      };

    // ************** SET CREATIVE **************
    case types.SET_CREATIVE:
      let { creative: creatives } = state.campaign;
      let found = creatives.find((item) => item.id === action.payload.id);

      return {
        ...state,
        campaign: {
          ...state.campaign,
          creative: found
            ? state.campaign.creative
            : [...state.campaign.creative, action.payload],
        },
      };

    // ************** REMOVE CREATIVE ***************
    case types.REMOVE_CREATIVE: {
      const { creative: creatives } = state.campaign;
      const id = action.payload;
      let modifiedCreatives = [];

      modifiedCreatives = creatives.filter((creativeId: any): boolean => {
        if (typeof creativeId === ('string' || 'number')) {
          if (creativeId !== id) {
            return true;
          }
        } else if (typeof creativeId === 'object') {
          if (creativeId?.id !== id) {
            return true;
          }
        }
        return false;
      });

      return {
        ...state,
        campaign: {
          ...state.campaign,
          creative: [...modifiedCreatives],
        },
      };
    }

    // ************** CHANGE CAMPAIGN DATA **************
    case types.CHANGE_CAMPAIGN_DATA:
      const { name, data } = action.payload;
      // console.log(action.paylod)
      // if (action.payload.name === 'start_date') {
      //   const { budget, budget_type, max_cpm, start_date, end_date } =
      //     state.campaign;
      //   return {
      //     ...state,
      //     campaign: {
      //       ...state.campaign,
      //       start_date: action.payload.data,
      //       daily_budget: calculateDailyBudgetDefault(
      //         budget,
      //         budget_type,
      //         max_cpm,
      //         action.payload.data,
      //         end_date
      //       ),
      //     },
      //   };
      // }
      // if (action.payload.name === 'end_date') {
      //   const { budget, budget_type, max_cpm, start_date, end_date } =
      //     state.campaign;
      //   return {
      //     ...state,
      //     campaign: {
      //       ...state.campaign,
      //       end_date: action.payload.data,
      //       daily_budget: calculateDailyBudgetDefault(
      //         budget,
      //         budget_type,
      //         max_cpm,
      //         start_date,
      //         action.payload.data
      //       ),
      //     },
      //   };
      // }
      // if (action.payload.name === 'budget') {
      //   const { budget, budget_type, max_cpm, start_date, end_date } =
      //     state.campaign;
      //   return {
      //     ...state,
      //     campaign: {
      //       ...state.campaign,
      //       budget: action.payload.data,
      //       daily_budget: calculateDailyBudgetDefault(
      //         action.payload.data,
      //         budget_type,
      //         max_cpm,
      //         start_date,
      //         end_date
      //       ),
      //     },
      //   };
      // }
      // if (action.payload.name === 'budget_type') {
      //   const { budget, budget_type, max_cpm, start_date, end_date } =
      //     state.campaign;
      //   return {
      //     ...state,
      //     campaign: {
      //       ...state.campaign,
      //       budget_type: action.payload.data,
      //       daily_budget: calculateDailyBudgetDefault(
      //         budget,
      //         action.payload.data,
      //         max_cpm,
      //         start_date,
      //         end_date
      //       ),
      //     },
      //   };
      // }

      return {
        ...state,
        campaign: {
          ...state.campaign,
          name: name === 'name' ? data : state.campaign.name,
          start_date: name === 'start_date' ? data : state.campaign.start_date,
          end_date: name === 'end_date' ? data : state.campaign.end_date,
          max_cpm: name === 'max_cpm' ? data : state.campaign.max_cpm,
          budget: name === 'budget' ? data : state.campaign.budget,
          pacing: name === 'pacing' ? data : state.campaign.pacing,
          kpi_type: name === 'kpi_type' ? data : state.campaign.kpi_type,
          kpi_value: name === 'kpi_value' ? data : state.campaign.kpi_value,
          list_url: name === 'list_url' ? data : state.campaign.list_url,
          geo: name === 'geography' ? data : state.campaign.geo,
          status: name === 'status' ? data : state.campaign.status,
          id: name === 'id' ? data : state.campaign.id,
          budget_type:
            name === 'budget_type' ? data : state.campaign.budget_type,
          campaign_type:
            name === 'campaign_type' ? data : state.campaign.campaign_type,
          budget_spent:
            name === 'budget_spent' ? data : state.campaign.budget_spent,
          auto_optimize_cpm:
            name === 'auto_optimize_cpm'
              ? data
              : state.campaign.auto_optimize_cpm,
          creative:
            name === 'creative'
              ? [...state.campaign.creative, data]
              : state.campaign.creative,
          advanced_targeting:
            name === 'advanced' ? data : state.campaign.advanced_targeting,
        },
      };
    // ************** Add New Freq Cap **************
    case types.ADD_NEW_FREQCAP:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          frequency_cap: [
            ...state.campaign.frequency_cap,
            { frequency: 0, interval: 0 },
          ],
        },
      };
    // ************** Add Freq Cap Value **************
    case types.ADD_FREQCAP_VALUE: {
      const { index, type, value } = action.payload;
      const freqCap = [...state.campaign.frequency_cap];
      freqCap[index].frequency =
        type === 'frequency' ? value : freqCap[index].frequency;
      freqCap[index].interval =
        type === 'interval' ? value : freqCap[index].interval;
      return {
        ...state,
        campaign: {
          ...state.campaign,
          frequency_cap: [...freqCap],
        },
      };
    }
    // ************** Delete Freq Cap **************
    case types.DELETE_FREQCAP: {
      const freqCap = [...state.campaign.frequency_cap];
      const filteredFreqCap = freqCap.filter(
        (_, index) => index !== action.payload
      );
      return {
        ...state,
        campaign: {
          ...state.campaign,
          frequency_cap: [...filteredFreqCap],
        },
      };
    }
    // ************** Add Freq Cap Value Edit **************
    case types.ADD_FREQCAP_VALUE_FOR_EDIT:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          frequency_cap: [...action.payload],
        },
      };

    case types.CHANGE_CAMPAIGN_STATUS: {
      let modCampaigns = state.campaigns;
      modCampaigns = modCampaigns.map((item) => {
        if (item.id === action.payload.id) {
          item.status = action.payload.status;
          return item;
        }
        return item;
      });

      return {
        ...state,
        campaigns: [...modCampaigns],
      };
    }

    case types.SET_EDIT_CAMPAIGN:
      return {
        ...state,
        editMode: true,
      };

    case types.UNSET_EDIT_CAMPAIGN:
      return {
        ...state,
        editMode: false,
      };
    case types.CLEAR_EDIT_CAMPAIGN:
      return {
        ...state,
        campaign: {
          id: '',
          name: '',
          start_date: new Date(),
          end_date: ddh.getNextMonth(),
          auto_optimize_cpm: true,
          max_cpm: '',
          status: 0,
          campaign_type: 'retargeting',
          kpi_type: '',
          kpi_value: '',
          budget: '',
          budget_type: 'cpa',
          daily_budget: '',
          freq_version: 2,
          frequency_cap: [],
          geo: [
            {
              exclude: { city: [], region: [], objectIDs: [] },
              include: { city: [], region: [], objectIDs: [] },
            },
          ],
          creative: [],
          list_url: '',
          budget_spent: '',
          pacing: '1',
          advanced_targeting: '',
        },
        editMode: false,
        defaultFreqCap: true,
      };
    case types.SET_DEFAULT_GEO:
      return {
        ...state,
        defaultGeo: [...state.defaultGeo, action.payload],
      };
    case types.UNSET_DEFAULT_GEO:
      return {
        ...state,
        defaultGeo: [],
      };
    case types.SET_FILE_UPLOADING:
      return {
        ...state,
        fileUploading: action.payload,
      };
    case types.CHANGE_SUBMIT:
      return {
        ...state,
        submit: action.payload,
      };
    case types.SIGN_OUT:
      return state;

    default:
      return state;
  }
};

export { campaignReducer };
