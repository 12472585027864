import types from '../actions/types';

const initialState = {
  email: "",
  firstName: "",
  lastName: "",
  createdAt: "",
  profilePic: "",
  advertisers: [
    {
      id: 1,
      name: 'Loading...'
    }
  ],
  advertiserId: "",
  advertiserName: "",
  id: "",
  lastSeen: "",
  admin: false,
  loading: false,
  passcodeSent: false
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.SET_USER:
      return {
        ...state,
        ...action.payload
      };

    case types.SIGN_OUT:
      return {
        email: "",
        firstName: "",
        lastName: "",
        createdAt: "",
        profilePic: "",
        advertisers: [
          {
            id: 1,
            name: 'Loading...'
          }
        ],
        advertiserId: "",
        advertiserName: "",
        id: "",
        lastSeen: "",
        admin: false,
        loading: false,
        passcodeSent: false
      }
    case types.SET_USER_LOADING:
      return {
        ...state,
        loading: action.payload
      }

    case types.SET_PASSCODE_SENT:
      return {
        ...state,
        passcodeSent: action.payload
      }
    default:
      return state;
  }
};
export { userReducer };

