import React from 'react';
import { Tooltip } from '@material-ui/core';
import ddh from '../../../utils/DashboadDataHandler';
import { useSelector } from 'react-redux';
import InfoIcon from '@material-ui/icons/Info';

type statusType = 0 | 1 | 2 | 3 | 4;

interface Props {
  id: number;
  budgetType: string;
  status: statusType;
  budget: number;
  endDate: string;
}

const StatusComponent: React.FC<Props> = ({
  id,
  budgetType,
  budget,
  status,
  endDate,
}) => {
  const reports = useSelector((state: any) => state?.dashboard?.reporting);
  const statusArr = [
    'Paused',
    'Running',
    'Date out of range',
    'Out of budget',
    'Creative is being processed which can take up to 10 minutes.',
  ];

  const spend: number = parseFloat(
    ddh.generateCostForTable(id, budgetType, reports)
  );

  if (budget - spend <= 0) {
    status = 3;
  }

  if (new Date().getTime() > new Date(endDate).getTime()) {
    status = 2;
  }

  const classes = ['inactive', 'active'];

  return (
    <Tooltip
      arrow
      placement="top"
      title={statusArr[status]}
      data-testid="status-tooltip"
    >
      {status > 1 ? (
        <InfoIcon data-testid="status-tooltip-holder" className="play-status info" />
      ) : (
        <span data-testid="status-tooltip-holder" className={`play-status ${classes[status]}`} />
      )}
    </Tooltip>
  );
};

export default StatusComponent;
