
// import React from 'react'
import { useEffect, useState } from 'react'
import { useRouteMatch }  from 'react-router-dom'
// import { useDispatch, useSelector } from 'react-redux';
import { routesByPage, AuthRoutesEnum } from '../../Routing/Routes'

const usePage = () => {

  const { path } = useRouteMatch()

  const [page, setPage] = useState(undefined) 
  const [routeEnumKey, setRouteEnumKey] = useState(undefined)

  useEffect(() => {
    for (let item in AuthRoutesEnum) {
      const value = AuthRoutesEnum[item]
      if (path === value) {
        setRouteEnumKey(item)
      }
    }
  }, [path, AuthRoutesEnum])

  useEffect(() => {
    if (routeEnumKey) {
      for (let pageRoute in routesByPage) {
        const pathKeys = routesByPage[pageRoute]

        if (pathKeys.includes(routeEnumKey)) {
          setPage(pageRoute)
        }
      }
    }
  }, [routeEnumKey, routesByPage])

  return page
}

export default usePage;