import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { loadState } from '../utils/localStorage';
import { campaignReducer } from './reducers/campaignReducer';
import { dashboardReducer } from './reducers/dashboardReducer';
import { profileReducer } from './reducers/profileReducer';
import { userReducer } from './reducers/userReducer';


const persistedState = loadState();

export const rootReducer = combineReducers({
  user: userReducer,
  campaigns: campaignReducer,
  dashboard: dashboardReducer,
  profile: profileReducer
});

export const reduxStore = createStore(
  rootReducer,
  persistedState,
  composeWithDevTools(applyMiddleware(thunk))
);
