import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import 'semantic-ui-css/semantic.min.css';
import App from './App';
import './index.css';
import './tailwind-styles.scss'
import * as serviceWorker from './serviceWorker';
import { reduxStore } from './store';
import { saveState } from './utils/localStorage';
import { STRIPE_KEY } from './config';

const stripePromise = loadStripe(STRIPE_KEY);

reduxStore.subscribe(() => {
  saveState({
    user: reduxStore.getState().user,
    editMode: reduxStore.getState().campaigns.editMode,
  });
});

ReactDOM.render(
  // <React.StrictMode>
  <ReduxProvider store={reduxStore}>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </ReduxProvider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
