import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

interface Props {
  handleAddFreqCap: () => void;
  budgetType: string;
  checkedDefault: boolean;
}

const AddButton = ({ handleAddFreqCap, budgetType, checkedDefault }: Props) => {
  const partialFreqCap = useSelector(
    (state: any) => state?.campaigns?.partialFreqCap
  );
  const admin = useSelector((state: any) => state?.user?.admin);

  let content = checkedDefault ? null : (
    <Button
      type="button"
      className="add"
      onClick={handleAddFreqCap}
      disabled={admin ? false : budgetType === 'cpa'}
    >
      <AddIcon className="add-icon" />
      {partialFreqCap.length > 0 ? 'Add another freqcap' : 'Add new freqcap'}
    </Button>
  );

  return partialFreqCap.length < 3 ? content : null;
};

export default AddButton;
