import React, { useState } from 'react';
import AddAdvertiser from '../../components/AdvertiserContents/AddAdvertiser';
import WithHeaderLayoutSinglePage from '../../layout/WithHeaderLayoutSinglePage';

const AddAdvertiserPage = function () {

  return (
    <WithHeaderLayoutSinglePage>
      <AddAdvertiser />
    </WithHeaderLayoutSinglePage>
  );
};
export default AddAdvertiserPage;
