import { SnackbarProvider } from 'notistack';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import withClearCache from './ClearCache';
import Routes from './Routing/Routes';

function MainApp() {
  return (
    <SnackbarProvider maxSnack={3}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </SnackbarProvider>
  );
}

const ClearCacheComponent = withClearCache(MainApp);

function App(): JSX.Element {
  return <ClearCacheComponent />;
}

export default App;
