import API from 'services/api';
import { baseUrl } from 'config';
import types from '../types';

export const submitCampaignStatusChange =
  (campaignId: number | string, status: string, enqueueSnackbar?: Function) =>
  (dispatch, getState) => {
    const advId = getState().user.advertiserId;
    dispatch({ type: types.SET_EDIT_CAMPAIGN });
    const bodyFormData = new FormData();
    bodyFormData.append('status', status);
    API({
      url: `${baseUrl}/${advId}/campaign/${campaignId}/status`,
      method: 'put',
      data: bodyFormData,
    })
      .then((res) => {
        if (res.status === 200 && res.data.success) {
          dispatch({ type: types.UNSET_EDIT_CAMPAIGN });
          dispatch({
            type: types.CHANGE_CAMPAIGN_STATUS,
            payload: {
              status: res.data.status,
              id: res.data.id,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.data?.success === false) {
          enqueueSnackbar &&
            enqueueSnackbar(
              `Campaign status change could not be processed. ${error?.response?.data?.message}`,
              {
                variant: 'error',
              }
            );
        } else {
          enqueueSnackbar &&
            enqueueSnackbar(
              'Something went wrong while changing campagin status!'
            );
        }
      });
  };
