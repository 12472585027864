import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './style.scss';

const logoutImg = '/images/logout.svg';

const generateFullName = (firstName: string = '', lastName: string = '') => {
  return firstName + ' ' + lastName;
};

function Dropdown({ handleClose, logout }) {
  // Hooks
  const firstName = useSelector((state: any) => state?.user?.firstName);
  const lastName = useSelector((state: any) => state?.user?.lastName);
  const email = useSelector((state: any) => state?.user?.email);
  const profileVisibility = useSelector((state: any) => state?.profile.profileVisibility);

  function DropDownItem(props) {
    return (
      <Link
        to={props?.route ? props.route : ''}
        className="menu-item"
        onClick={() => {
          handleClose();
          props?.handleClick && props.handleClick();
        }}
      >
        {props?.leftIcon && (
          <span className="menu-icon-left">{props.leftIcon}</span>
        )}
        {props.children}
        {props?.rightIcon && (
          <span className="menu-icon-right">{props.rightIcon}</span>
        )}
      </Link>
    );
  }

  return (
    <div className="dropdown" id="simple-menu">
      <div className="user-info">
        <h6>{generateFullName(firstName, lastName)}</h6>
        <p>{email}</p>
      </div>
      <div className="divider"></div>
      {profileVisibility && <DropDownItem route="/profile">My Profile</DropDownItem> }
      {/* <DropDownItem>System Settings</DropDownItem> */}
      {/* <DropDownItem>Help & Feedback</DropDownItem> */}
      {/* <div className="divider"></div> */}
      <DropDownItem handleClick={logout} leftIcon={<img src={logoutImg} />}>
        Logout
      </DropDownItem>
    </div>
  );
}

export default Dropdown;
