import { InputAdornment, TextField } from '@material-ui/core';
import React from 'react';
import './style.scss';

type TadornmentPostion = 'start' | 'end';
export type TadornmentType = 'icon' | 'image' | 'other';
interface IInput {
  type: string;
  placeholder: string;
  name: string;
  value?: string | number;
  handleChange: (e) => void;
  adornmentPosition?: TadornmentPostion;
  adornmentType?: TadornmentType;
  adornment?: any;
  testID?: string;
  disabled?: boolean;
}

const getAdornment = (
  position: TadornmentPostion,
  type: TadornmentType,
  adornment: any
) => {
  if (type === 'icon') {
    return <InputAdornment position={position}>{adornment}</InputAdornment>;
  } else if (type === 'image') {
    return (
      <InputAdornment position={position}>
        <img height="30px" src={adornment} />
      </InputAdornment>
    );
  } else {
    return <InputAdornment position={position}>{adornment}</InputAdornment>;
  }
};

const AHInputBox = function (props: IInput) {
  const {
    type,
    placeholder,
    name,
    handleChange,
    adornmentPosition,
    adornmentType,
    adornment,
    value,
    testID,
    disabled,
  } = props;

  return (
    <div className="input-wrapper">
      <TextField
        data-testid={testID}
        onChange={handleChange}
        disabled={disabled ?? false}
        label=""
        type={type}
        name={name}
        placeholder={placeholder}
        value={value ?? ''}
        className="custom-input"
        InputProps={{
          startAdornment:
            adornmentPosition === 'start'
              ? getAdornment(adornmentPosition, adornmentType, adornment)
              : '',
          endAdornment:
            adornmentPosition === 'end'
              ? getAdornment(adornmentPosition, adornmentType, adornment)
              : '',
        }}
      />
    </div>
  );
};
export default AHInputBox;
