import { IconButton, Tooltip, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles, Theme } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';
import React from 'react';
import { useSelector } from 'react-redux';
import { PartialFreqCampEnum } from 'constants/enums';
import { IFreqCap, IPartialFreqCap } from 'models/freqCap';
import AHInputBox from 'components/common/AHInputBox';
import AHMenu from 'components/common/AHMenu/AHMenu';
import AddButton from './AddButton';
import { useDispatch } from 'react-redux';
import types from 'store/actions/types';
import { DEFAULT_FREQ_CAP } from 'constants/defaults';
import { formatIntervalInHours } from 'utils/freqCap';
import AHSwitch from 'components/common/AHSwitch';

interface IProps {
  budgetType: string;
  handlFreqInterChange: (
    index: number,
    type: string,
    value: number | string
  ) => void;
  handleAddFreqCap: () => void;
  handleDeleteFreqCap: (index: number) => void;
}
const FreqCapComponent = (props: IProps) => {
  const {
    budgetType,
    handlFreqInterChange,
    handleAddFreqCap,
    handleDeleteFreqCap,
  } = props;

  const dispatch = useDispatch();

  const HtmlTooltip = withStyles((theme: Theme) => ({
    arrow: {
      color: '#000',
    },
    tooltip: {
      backgroundColor: '#1d1414',
      color: '#fff',
      maxWidth: 500,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #110d0d',
    },
  }))(Tooltip);

  const partialFreqCap = useSelector(
    (state: any) => state?.campaigns.partialFreqCap
  );
  const admin = useSelector((state: any) => state?.user?.admin);
  const defaultFreqCap = useSelector(
    (state: any) => state?.campaigns?.defaultFreqCap
  );

  const handleChangeDefaultFC = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      let freqCaps: Array<IFreqCap> = [];
      let partialFreqCaps: Array<IPartialFreqCap> = [];

      freqCaps = [...DEFAULT_FREQ_CAP];

      freqCaps.forEach((fc: IFreqCap) => {
        const freqCapObj = formatIntervalInHours(fc);
        partialFreqCaps = [...partialFreqCaps, freqCapObj];
      });

      dispatch({
        type: types.STORE_PARTIAL_FREQCAP,
        payload: partialFreqCaps,
      });
    } else {
      dispatch({
        type: types.STORE_PARTIAL_FREQCAP,
        payload: [],
      });
    }

    dispatch({
      type: types.SET_DEFAULT_FREQCAP,
      payload: e.target.checked,
    });
  };

  const isDisabled = () => {
    if (defaultFreqCap) return true;
    return admin ? false : budgetType === 'cpa';
  };

  let content2 = (
    <>
      <div className="campForm-itembox">
        <div className="campForm-input">
          <div className="campForm-input-label">Freq Caps *</div>
          <div className="component-wrapper">
            <AHSwitch
              dataTestId="freqcapToggle"
              handleChange={handleChangeDefaultFC}
              checked={defaultFreqCap}
              label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>Default Freq Caps</span>
                  <HtmlTooltip
                    arrow
                    title={
                      <>
                        <Typography>Defaults are here</Typography>
                      </>
                    }
                    placement="top"
                  >
                    <HelpIcon style={{ width: '1rem', marginLeft: '.5rem' }} />
                  </HtmlTooltip>
                </div>
              }
            />
          </div>
        </div>
      </div>

      {partialFreqCap.map((pfc: IPartialFreqCap, index: number) => {
        return (
          <>
            <div className="campForm-three-itembox" data-testid="freqcaps">
              <div className="campForm-inputs">
                <div className="item-in-three">
                  <div className="campForm-input-label">Freq Cap</div>
                  <AHInputBox
                    value={pfc.frequency}
                    disabled={isDisabled()}
                    type="number"
                    placeholder=""
                    name=""
                    handleChange={(e) => {
                      handlFreqInterChange(
                        index,
                        PartialFreqCampEnum.Freq,
                        e.target.value
                      );
                    }}
                  />
                </div>
                <div className="item-in-three">
                  <div className="campForm-input-label">Interval</div>
                  <AHInputBox
                    type="number"
                    placeholder=""
                    name=""
                    value={pfc.intervalValue}
                    disabled={isDisabled()}
                    handleChange={(e) =>
                      handlFreqInterChange(
                        index,
                        PartialFreqCampEnum.Value,
                        e.target.value
                      )
                    }
                  />
                </div>
                <div className="item-in-three">
                  <div className="campForm-input-label">Interval Type</div>
                  <AHMenu
                    value={pfc.intervalType}
                    disabled={isDisabled()}
                    options={[
                      { data: 'hour', label: 'Hour' },
                      { data: 'day', label: 'Day' },
                      { data: 'week', label: 'Week' },
                    ]}
                    handleChange={(e) => {
                      handlFreqInterChange(
                        index,
                        PartialFreqCampEnum.Type,
                        e.target.value
                      );
                    }}
                  />
                </div>
              </div>
              <div className="cross-button-wrapper">
                <IconButton
                  className="delete_icon_button"
                  aria-label="delete"
                  size="small"
                  onClick={() => handleDeleteFreqCap(index)}
                  disabled={isDisabled()}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
          </>
        );
      })}
      <AddButton
        checkedDefault={defaultFreqCap}
        handleAddFreqCap={handleAddFreqCap}
        budgetType={budgetType}
      />
    </>
  );

  return budgetType === 'cpa' && !admin ? null : content2;
};
export default FreqCapComponent;
