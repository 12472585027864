
// intervals with seconds
export const DEFAULT_FREQ_CAP_IN_SECONDS = [
    { frequency: 2, interval: 86400 },
    { frequency: 4, interval: 604800 },
    { frequency: 8, interval: 2419200 },
];


// intervals with hours
export const DEFAULT_FREQ_CAP = [
    { frequency: 2, interval: 24 },
    { frequency: 4, interval: 168 },
    { frequency: 8, interval: 672 },
];

//************************************************************************** */
// NOTE:: When use default freqCap with
// seconds/minutes please customise functions in /utis/freqCap.ts