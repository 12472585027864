import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'rsuite';
import {
  addTeamMemberAction,
  retrieveCoWorkersAction
} from '../../../store/actions/userActions';
import { getFullName } from '../../../utils/commonFns';
import { validateEmail } from '../../../utils/validateFns';
import AHButton from '../../common/AHButton/AHButton';
import Member from './Member';
import './style.scss';
import { TextField, InputAdornment } from '@material-ui/core';
import { InformationCircleIcon } from '@heroicons/react/solid'
// import InputAdornment from '@mui/material/InputAdornment';
import { AccountCircle } from '@material-ui/icons';


const TeamSettings = function (): JSX.Element {
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [error, setError] = useState('');
  const [clickedUserId, setClickedUserId] = useState('');

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const team = useSelector((state: any) => state?.profile?.team);
  const advId = useSelector((state: any) => state?.user?.advertiserId);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    handleCheckError(e);
  };

  const handleCheckError = (e) => {
    let hasError: boolean = false;
    if (e.target.name === 'email') {
      hasError = validateEmail(e.target.value);
      if (!hasError) {
        setError('Email is not valid!');
      } else {
        setError('');
      }
    }
  };

  const handleClickSettings = (id: string) => {
    id === clickedUserId ? setClickedUserId('') : setClickedUserId(id);
  };

  const { firstName, lastName, email } = state;
  const handleDisableSubmit = () => {
    if (firstName && lastName && email && !error) {
      return false;
    }
    return true;
  };

  const handleSubmitAddMember = () => {
    dispatch(addTeamMemberAction(firstName, lastName, email, enqueueSnackbar));
    setState({ ...state, firstName: '', lastName: '', email: '' });
  };

  useEffect(() => {
    dispatch(retrieveCoWorkersAction(enqueueSnackbar));
  }, []);

  // if the advertiser id changes grab more the correct co-workers
  useEffect(() => {
    dispatch(retrieveCoWorkersAction(enqueueSnackbar));
  }, [advId])

  const toolTip = "Please note emails must be a corporate domain - i.e. 'jane@yourcompany.com'" 

  return (
    <div>
      <div className="ts-title-box">
        <h3 data-testid="team-title" className="ts-title">Team Settings</h3>
      </div>
      <div className="ts-content">
        <div className="ts-content-box ts-add-member" >
          

          <div className="flex justify-start w-full h-8 pb-2"> 
            <div className="flex place-items-center" >
              <h6 className="ts-content-title">Add Member</h6> 
            </div> 
            <div className="flex place-items-center ml-2" title={toolTip} >
              <InformationCircleIcon className="h-5 w-5 text-gray-400" />
            </div> 
          </div> 

          <div className="grid grid-cols-1 md:grid-cols-12 gap-4  pb-4 w-full max-w-3xl">
            <div className="col-span-1 md:col-span-4 ">
              <TextField  
                id="firstname"
                name="firstName"
                data-testid="frstname"
                className="w-full"
                label="First Name"
                value={firstName}
                variant="outlined" 
                onChange={handleChange}
              />
            </div>
            <div className="col-span-1 md:col-span-4">
              <TextField  
                id="lastname"
                name="lastName"
                data-testid="lastname"
                className="w-full"
                label="Last Name"
                value={lastName}
                variant="outlined" 
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4 pb-8 w-full max-w-3xl">
            <div className="col-span-6">
              <TextField  
                id="email"
                name="email"
                data-testid="email"
                className="w-full"
                label="Email"
                value={email}
                variant="outlined" 
                onChange={handleChange}
                title={toolTip}
              />
            </div>
            <div className="col-span-6">
              <AHButton
                text="Add Member"
                variant="contained"
                color="primary"
                btntype="action"
                handleClick={handleSubmitAddMember}
                disabled={handleDisableSubmit()}
              />
            </div>


          </div>

        </div>
        <div className="ts-content-box ts-team">
          <h6 className="ts-content-title">Your Team</h6>
          <div className="ts-members">
            {Array.isArray(team) &&
              team.map((member) => {

                return (
                  <div key={member.id} >
                    <Member
                      memberId={member.id}
                      memberName={getFullName(
                        member.first_name,
                        member.last_name
                      )}
                      memberEmail={member.email}
                      memberImg={member.profile_pic}
                      handleClickSettings={handleClickSettings}
                      isOwner={member.owner}
                      clickedUserId={clickedUserId}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default TeamSettings;
