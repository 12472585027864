import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Page from '../../components/page/Page';
import SignInForm from '../../components/sign-in-form';
import { setToken } from '../../services/tokenService';
import { AuthRoutesEnum } from '../../Routing/Routes';
import { submitEmail, submitPasscode } from '../../store/actions/userActions';
import './style.scss';
import { getNewToken } from '../../services/tokenService';

const SignInIcon = '/images/audiohook-logo.svg';

const SignIn: React.FC = (props: any) => {
  const [isFirstStep, changeStep] = useState(true);
  const [activity, setActivity] = useState(true);
  const [newToken, setNewToken] = useState("");
  const [inputValue, setInputValue] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const email = useSelector((state: any) => state?.user?.email);
  const admin = useSelector((state: any) => state?.user?.admin);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [isMounted, setIsMounted]: any = useState(false)

  useEffect(() => {
    // NOTE mutable fl
    setIsMounted(true)              
    // NOTE cleanup toggles value, if unmounted
    return () => { setIsMounted(false) }; 
  }, []); 

  useEffect(() => {
    (async function() {
      const renewedToken = await(getNewToken());
      if (isMounted) {
        setNewToken(renewedToken);
      }
    })();
    
    if (newToken) {
      setToken(newToken);
      history.push(AuthRoutesEnum.Dashboard);
    }
  }, [newToken, isMounted]);

  const handleActive = () => {
    setActivity(true);
  };

  const handleDeActive = () => {
    setActivity(false);
  };

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setInputValue(event.target.value);
  };

  // ---> Submit Email
  const emailSubmitHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    event.preventDefault();
    handleDeActive();
    dispatch(
      submitEmail(
        inputValue,
        handleActive,
        changeStep,
        setInputValue,
        enqueueSnackbar
      )
    );
    
  };

  // ----> Submit Passcode
  const passcodeSubmitHandler =(
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    event.preventDefault();
    handleDeActive();
    dispatch(submitPasscode(email, inputValue, enqueueSnackbar, history, handleActive));
  };

  // ----> Reset PassCode
  const resendPasscode = () => {
    changeStep(true);
  };

  // console.log(history);

  return (
    <Page>
      <div className="sign-in">
        <div className="sign-in__left-side">
          <div className="sign-in__form-wrapper">
            {isFirstStep ? (
              <SignInForm
                title="Sign in"
                subtitle=""
                label="Email Address"
                type="email"
                placeholder="Enter your email address"
                buttonLabel="Send Passcode"
                activity={activity}
                value={inputValue}
                formHandler={emailSubmitHandler}
                handleInputChange={handleInputChange}
              />
            ) : (
              <SignInForm
                activity={activity}
                title="Passcode"
                subtitle="A 6 digit passcode has been sent to the email provided. Please enter that passcode below."
                label="Passcode"
                value={inputValue}
                placeholder="Enter passcode"
                buttonLabel="Sign In"
                formHandler={passcodeSubmitHandler}
                handleInputChange={handleInputChange}
                isPasscodeStep
                resendPasscode={resendPasscode}
              />
            )}
          </div>
        </div>
        <div className="sign-in__right-side">
          <img src={SignInIcon} className="sign-in__icon" alt="" />
        </div>
      </div>
    </Page>
  );
};
export default SignIn;
