import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}

const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 26,
      height: 18,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(8px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: 'var(--main-color)',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 16,
      height: 16,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, ...props }: Props) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      checked={props?.checked}
      value={props?.checked}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

interface AHSwitchProps {
  dataTestId?: string;
  checked: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string | JSX.Element;
}

const AHSwitch = ({
  dataTestId = '',
  checked,
  handleChange,
  label,
}: AHSwitchProps) => {
  return (
    <div>
      <FormControlLabel
        data-testid={dataTestId || ' '}
        control={
          <IOSSwitch
            checked={checked}
            onChange={handleChange}
            name="freq-cap-toggle"
          />
        }
        label={label}
      />
    </div>
  );
};

export default AHSwitch;
