import React from 'react'
import Chart from 'react-apexcharts'
import { abbreviateNumber } from '../lib/utils'

interface ISplineChartOptions { 
  title: String
  valueOne: String
  valueTwo?: String
  colorOne: String
  colorTwo?: String
  dataOne: any;
  dataTwo: any;
  titleOne: String
  titleTwo: String
} 

export interface IBaseProps {
  width?: number;
  minDate?: number;
  option: ISplineChartOptions;
  top?: any
  chartSize: number;
}

const SplineChartV2: React.FC<IBaseProps> = ({ chartSize, width, option, minDate, top }) => {
  const dataOne = option.dataOne
  const dataTwo = option.dataTwo

  const colorOne = option.colorOne
  const colorTwo = option.colorTwo

  let chartSingle
  let chartDouble

  chartSingle = {
    series: [
      {
        color: colorOne, 
        name: option.titleOne,
        data: dataOne,
      },
    ],
    options: {
      stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'round',
        colors: colorOne,
        width: 2,
        dashArray: 0,
      },
      chart: {
        id: 'area-datetime',
        type: 'area',
        height: 245,
        zoom: {
          autoScaleYaxis: true,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
        style: 'hollow',
        colors: colorOne,
      },
      yaxis: {
        show: true,
        tickAmount: 5,

        labels: {
          show: true,
          align: 'right',
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: ['#000000'],
            fontSize: '12px',
            fontWeight: 400,
            cssClass: 'line-chart-yaxis-label',
          },
          offsetX: 0,
          offsetY: 0,
          rotate: 0,
          formatter: (value) => {
            return abbreviateNumber(value);
          },
        },
        axisBorder: {
          show: false,
          color: '#B620E0',
          offsetX: 0,
          offsetY: 0,
        },
      },
      xaxis: {
        type: 'datetime',
        min: minDate,
        tickAmount: 5,
        labels: {
          style: {
            colors: ['#000000'],
            fontSize: '11px',
            cssClass: 'line-chart-xaxis-label',
          },
        },
        axisBorder: {
          show: false,
          borderType: 'dotted',
          color: 'red',
          height: 1,
          width: '100%',
          offsetX: 0,
          offsetY: 0,
        },
        axisTicks: {
          show: false,
          borderType: 'dotted',
          color: 'red',
          height: 5,
          offsetX: 0,
          offsetY: 0,
        },
        tooltip: {
          enabled: false,
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return `
          <div class="arrow_box">
            <span>
            ${series[seriesIndex][dataPointIndex]}
            </span>

            </div>`;
        },
        style: {
          fontSize: '14px',
        },
        marker: {
          show: false,
        },
        y: {
          y: {
            formatter: (val) => val,
            title: {
              formatter: (seriesName) => seriesName,
            },
          },
        },
        x: {
          show: false,
          format: 'dd MMM yyyy',
        },
      },
      // NOTE -- keep for solid colors
      fill: {
        colors: colorOne,
        type:'solid',
        opacity: [0.35, 1],
      },
      // NOTE keep for other colors
      // fill: {
      //   colors: colorOne,
      //   type: 'gradient',
      //   gradient: {
      //     shadeIntensity: 1,
      //     opacityFrom: 0.7,
      //     opacityTo: 0.9,
      //     stops: [0, 100],
      //   },
      // },
    },

    selection: 'one_month',
  };

  if (chartSize > 1) {
    chartDouble = {
      series: [
        {
          color: colorOne, 
          name: option.titleOne,
          data: dataOne,
        },
        {
          color: colorTwo, 
          name: option.titleTwo,
          data: dataTwo,
        },
      ],
      options: {
        stroke: {
          show: true,
          curve: 'smooth',
          lineCap: 'round',
          colors: [colorOne, colorTwo],
          width: 2,
          dashArray: 0,
        },
        chart: {
          id: 'area-datetime',
          type: 'area',
          height: 245,
          zoom: {
            autoScaleYaxis: true,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: 'hollow',
          colors: [colorOne, colorTwo],
        },
        yaxis: [
          {
            show: true,
            tickAmount: 5,

            labels: {
              show: true,
              align: 'right',
              minWidth: 0,
              maxWidth: 160,
              style: {
                colors: ['#000000'],
                fontSize: '12px',
                fontWeight: 400,
                cssClass: 'line-chart-yaxis-label',
              },
              offsetX: 0,
              offsetY: 0,
              rotate: 0,
              formatter: (value) => {
                return abbreviateNumber(value);
              },
            },
            axisBorder: {
              show: false,
              color: '#B620E0',
              offsetX: 0,
              offsetY: 0,
            },
          },
          {
            opposite: true,
            show: true,
            labels: {
              show: true,
              align: 'right',
              minWidth: 0,
              maxWidth: 160,
              style: {
                colors: ['#000000'],
                fontSize: '12px',
                fontWeight: 400,
                cssClass: 'line-chart-yaxis-label',
              },
             offsetX: 0,
              offsetY: 0,
              rotate: 0,
              formatter: (value) => {
                return abbreviateNumber(value);
              },
            },
            axisBorder: {
              show: false,
              color: '#B620E0',
              offsetX: 0,
              offsetY: 0,
            },
          },
        ],
        xaxis: {
          type: 'datetime',
          min: minDate,
          tickAmount: 5,
          labels: {
            style: {
              colors: ['#000000'],
              fontSize: '11px',
              cssClass: 'line-chart-xaxis-label',
            },
          },
          axisBorder: {
            show: false,
            borderType: 'dotted',
            color: 'red',
            height: 1,
            width: '100%',
            offsetX: 0,
            offsetY: 0,
          },
          axisTicks: {
            show: false,
            borderType: 'dotted',
            color: 'red',
            height: 5,
            offsetX: 0,
            offsetY: 0,
          },
          tooltip: {
            enabled: false,
          },
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            return `
            <div class="arrow_box">
              <span>
              ${series[seriesIndex][dataPointIndex]}
              </span>

              </div>`;
          },
          style: {
            fontSize: '14px',
          },
          marker: {
            show: false,
          },
          y: {
            y: {
              formatter: (val) => val,
              title: {
                formatter: (seriesName) => seriesName,
              },
            },
          },
          x: {
            show: false,
            format: 'dd MMM yyyy',
          },
        },
        // NOTE -- keep for solid colors
        // fill: {
        //   colors: [colorOne, colorTwo],
        //   type:'solid',
        //   opacity: [0.35, 1],
        // },
        fill: {
          colors: [colorOne, colorTwo],
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100],
          },
        },
      },

      selection: 'one_month',
    };
  }

  return (
    <div className="flex justify-center pr-3" style={{ width: width, height: 245 }}>
      <div className=" " style={{ width: width - 40 - 35  }}>

        {top ? top : (<div className="">
          <div className="">{option.title}</div>
          {/* TODO add back later */}
          {/* <div className="value">{option.value}</div> */}
        </div>)}
        {chartSize > 1 ? 
          <Chart
            //@ts-ignore
            options={chartDouble.options}
            series={chartDouble.series}
            type="area"
            height={185}
          /> : 
          <Chart
            //@ts-ignore
            options={chartSingle.options}
            series={chartSingle.series}
            type="area"
            height={185}
          />
        } 
      </div>
    </div>
  );
};

export default SplineChartV2;