const storeName = 'user';

export default {
  SIGN_IN_USER: `${storeName}.SIGN_IN_USER`,
  SIGN_OUT: 'SIGN_OUT',
  STORE_CAMPAIGN_DATA: 'STORE_CAMPAIGN_DATA',
  CHANGE_CAMPAIGN_DATA: 'CHANGE_CAMPAIGN_DATA',

  //freqcap
  ADD_NEW_FREQCAP: 'ADD_NEW_FREQCAP',
  DELETE_FREQCAP: 'DELETE_FREQCAP',
  ADD_FREQCAP_VALUE: 'ADD_FREQCAP_VALUE',
  ADD_FREQCAP_VALUE_FOR_EDIT: 'ADD_FREQCAP_VALUE_FOR_EDIT',
  SET_DEFAULT_FREQCAP: 'SET_DEFAULT_FREQCAP',
  //
  SUBMIT_CAMPAIGN: 'SUBMIT_CAMPAIGN',
  GET_REPORTING_DATA: 'GET_REPORTING_DATA',
  SET_EDIT_CAMPAIGN: 'SET_EDIT_CAMPAIGN',
  UNSET_EDIT_CAMPAIGN: 'UNSET_EDIT_CAMPAIGN',
  CLEAR_EDIT_CAMPAIGN: 'CLEAR_EDIT_CAMPAIGN',
  CHANGE_CAMPAIGN_STATUS: 'CHANGE_CAMPAIGN_STATUS',
  SET_DEFAULT_GEO: 'SET_DEFAULT_GEO',
  UNSET_DEFAULT_GEO: 'UNSET_DEFAULT_GEO',
  SET_FILE_UPLOADING: 'SET_FILE_UPLOADING',
  CHANGE_SUBMIT: 'CHANGE_SUBMIT',
  SET_CREATIVE: 'SET_CREATIVE',
  REMOVE_CREATIVE: 'REMOVE_CREATIVE',
  SET_LOADING: 'SET_LOADING',
  SET_REPORT_LOADING: 'SET_REPORT_LOADING',
  SET_USER_LOADING: 'SET_USER_LOADING',
  SET_USER: 'SET_USER',
  STORE_PARTIAL_FREQCAP: 'STORE_PARTIAL_FREQCAP',
  ADD_NEW_PARTIAL_FREQCAP: 'ADD_NEW_PARTIAL_FREQCAP',
  UPDATE_PARTIAL_FREQCAP: 'UPDATE_PARTIAL_FREQCAP',
  DELETE_PARTIAL_FREQCAP: 'DELETE_PARTIAL_FREQCAP',
  CLEAR_PARTIAL_FREQCAP: 'CLEAR_PARTIAL_FREQCAP',
  SET_DUPLICATE: 'SET_DUPLICATE',
  SET_DELETE_CREATIVE: 'SET_DELETE_CREATIVE',
  SET_PASSCODE_SENT: 'SET_PASSCODE_SENT',
  SET_TEAM: 'SET_TEAM',
  REMOVE_TEAM_MEMBER: 'REMOVE_TEAM_MEMBER',
  UPDATE_USER: 'UPDATE_USER',
  SET_EMAIL_LIST: 'SET_EMAIL_LIST',
  SET_CARD_DIGIT: 'SET_CARD_DIGIT',
  SET_CARD_EDITING: 'SET_CARD_EDITING',
  SET_CAMPAIGN_TYPE: 'SET_CAMPAIGN_TYPE',
  SET_INVENTORY: 'SET_INVENTORY',
  TOGGLE_INVENTORY_PUBLISHER: 'TOGGLE_INVENTORY_PUBLISHER',
};
