import Avatar from '@material-ui/core/Avatar';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import AHButton from '../AHButton/AHButton';
import './style.scss';

interface IImageUpload {
  update: Function;
}

const ImageUploader = function ({ update }: IImageUpload) {
  const profilePic = useSelector((state: any) => state?.user?.profilePic);
  const fileUploadRef = useRef(null);
  const [file, setFile] = useState(null);
  const [imgPrevUrl, setImgPrevUrl] = useState('');

  const handleImageChange = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        setFile(file);
        setImgPrevUrl(reader.result as string);
        update(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const showFileUpload = () => {
    if (fileUploadRef) {
      fileUploadRef.current.click();
    }
  };

  const removeImage = () => {
    setFile(null);
    setImgPrevUrl('');
  };

  useEffect(() => {
    setImgPrevUrl(profilePic);
  }, [profilePic]);

  return (
    <div className="iu-box">
      <Avatar className="iu-avatar" src={imgPrevUrl} />
      <input
        className="iu-hidden"
        id=""
        name=""
        type="file"
        accept="image/png, image/jpeg"
        onChange={handleImageChange}
        ref={fileUploadRef}
      />
      <div className="iu-button-group">
        <AHButton
          btntype="action"
          color="primary"
          variant="contained"
          text="Upload New Photo"
          handleClick={showFileUpload}
        />
        <AHButton
          btntype="cancel"
          color="primary"
          variant="text"
          text="Remove"
          handleClick={removeImage}
        />
      </div>
    </div>
  );
};
export default ImageUploader;
