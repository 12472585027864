import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import algoliasearch from 'algoliasearch/lite';
import React from 'react';
import {
  connectHits,
  connectSearchBox,
  Hits,
  InstantSearch
} from 'react-instantsearch-dom';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    top: 10,
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

const Config = algoliasearch('MGCCS3YDN4', '3bf0b2a55f48991cd31a68548528db8a');

const MaterialUISearchBox = (props: any) => {
  const { refine, handleChangeMultiple, hits } = props;
  const defaultGeo = useSelector((state: any) => state?.campaigns?.defaultGeo);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        id="tags-standard"
        autoComplete={false}
        autoHighlight={false}
        autoSelect={false}
        options={hits}
        // @ts-ignore
        getOptionLabel={(option) => `${option.name}, ${option.region_name}`}
        defaultValue={defaultGeo}
        onChange={(event, value) => {
          handleChangeMultiple(value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Search Geography"
            placeholder="Search..."
            onChange={(e) => refine(e.currentTarget.value)}
          />
        )}
      />
    </div>
  );
};

const CustomSearchBox: any = connectSearchBox(connectHits(MaterialUISearchBox));

export const AlgoliaMultiSelect = ({ handleGeo }) => {
  const [search, setSearch] = React.useState([]);
  const handleChangeMultiple = (value: any) => {
    const selected = [];
    for (let i = 0, l = value.length; i < l; i += 1) {
      if (value[i].name) {
        selected.push(value[i]);
      }
    }
    setSearch(value);
    handleGeo(value);
  };

  return (
    <InstantSearch indexName="ui_location" searchClient={Config}>
      <CustomSearchBox
        search={search}
        handleChangeMultiple={handleChangeMultiple}
        Hits={Hits}
      />
    </InstantSearch>
  );
};
