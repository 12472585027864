import { reverseFormatIntervalInSeconds } from 'utils/freqCap';
import API from 'services/api';
import { baseUrl } from 'config';
import types from '../types';
import { AuthRoutesEnum } from 'Routing/Routes';
import { formatDateYYYMMDD, formatStringToJSON } from 'utils/commonFns';

// export const editCampaignAction =
//   (history, enqueueSnackbar) => async (dispatch, getState) => {
//     const advId = getState()?.user?.advertiserId;
//     const admin = getState().user?.admin;
//     const {
//       creative,
//       id: campId,
//       list_url,
//       budget_spent,
//       ...campaign
//     } = getState()?.campaigns?.campaign;
//     if (list_url) campaign.list_url = list_url;
//     const partialFreqCaps = getState()?.campaigns?.partialFreqCap;
//     let freqCaps = [];

//     partialFreqCaps.length > 0 &&
//       partialFreqCaps.forEach((pfc) => {
//         freqCaps = [...freqCaps, reverseFormatIntervalInSeconds(pfc)];
//       });

//     campaign.frequency_cap = freqCaps;
//     campaign.start_date = formatDateYYYMMDD(campaign.start_date);
//     campaign.end_date = formatDateYYYMMDD(campaign.end_date);
//     campaign.pacing = parseInt(campaign.pacing);

//     if (campaign.advanced_targeting.trim() === '') {
//       delete campaign.advanced_targeting;
//     } else {
//       campaign.advanced_targeting = formatStringToJSON(
//         campaign.advanced_targeting
//       );
//     }

//     dispatch({ type: types.CHANGE_SUBMIT, payload: true });

//     const submitEditRes = await API.put(
//       `${baseUrl}/${advId}/campaign/${campId}`,
//       {
//         data: { ...campaign },
//       }
//     );
//   };

export const submitEditCampaign =
  (history, enqueueSnackbar) => async (dispatch, getState) => {
    const advId = getState()?.user?.advertiserId;
    const admin = getState().user?.admin;

    // get campaign object
    const {
      creative,
      id: campId,
      list_url,
      budget_spent,
      ...campaign
    } = getState()?.campaigns?.campaign;

    if (list_url) campaign.list_url = list_url;

    const partialFreqCaps = getState()?.campaigns?.partialFreqCap;
    let freqCaps = [];

    partialFreqCaps.length > 0 &&
      partialFreqCaps.forEach((pfc) => {
        freqCaps = [...freqCaps, reverseFormatIntervalInSeconds(pfc)];
      });

    campaign.frequency_cap = freqCaps;
    dispatch({ type: types.CHANGE_SUBMIT, payload: true });
    campaign.start_date = formatDateYYYMMDD(campaign.start_date);
    campaign.end_date = formatDateYYYMMDD(campaign.end_date);
    campaign.pacing = parseInt(campaign.pacing);

    if (campaign.advanced_targeting.trim() === '') {
      delete campaign.advanced_targeting;
    } else {
      campaign.advanced_targeting = formatStringToJSON(
        campaign.advanced_targeting
      );
    }

    API.put(`${baseUrl}/${advId}/campaign/${campId}`, {
      data: { ...campaign },
    })
      .then((res) => {
        console.log(res);
        if (res.status === 200 && res.data.success === true) {
          // if any creative deleted from UI make sure to delete it from server
          const deletedCreatives = getState().campaigns.deleteCreative;
          deleteCreativesFromServer(deletedCreatives, advId);

          // upload creatives if not uploaded
          const creatives = getState().campaigns.campaign.creative;
          if (creatives.length > 0) {
            creatives.forEach((creative) => {
              if (creative.hasOwnProperty('filehost')) {
                const bodyFormData = new FormData();
                bodyFormData.append('creative_id', creative.id.toString());
                bodyFormData.append('campaign_id', campId);
                API({
                  method: 'post',
                  url: `${baseUrl}/${advId}/creative-campaign-assoc`,
                  data: bodyFormData,
                })
                  .then((assocRes) => {
                    console.log(assocRes);
                    if (assocRes.status === 200) {
                      dispatch({ type: types.CLEAR_EDIT_CAMPAIGN });
                      dispatch({ type: types.CLEAR_PARTIAL_FREQCAP });
                      history.push(AuthRoutesEnum.Dashboard);
                    } else {
                      enqueueSnackbar(
                        `Campaign edit could not be processed. Creative campaign association error!`,
                        { variant: 'error' }
                      );
                    }
                  })
                  .catch((error) => {
                    console.error(error);
                  })
                  .finally(() => {
                    dispatch({ type: types.CHANGE_SUBMIT, payload: false });
                  });
              }
              dispatch({ type: types.CHANGE_SUBMIT, payload: false });
              history.push(AuthRoutesEnum.Dashboard);
            });
          } else {
            dispatch({ type: types.CHANGE_SUBMIT, payload: false });
            history.push(AuthRoutesEnum.Dashboard);
          }
        } else {
          dispatch({ type: types.CHANGE_SUBMIT, payload: false });
          if (res.status === 200 && res?.data?.success === false) {
            enqueueSnackbar(
              `Campaign edit could not be processed. ${res?.data?.message}`,
              { variant: 'error' }
            );
          }
        }
      })
      .catch((error) => {
        console.error(error);
        if (error?.response?.data?.success === false) {
          enqueueSnackbar(
            `Campaign edit could not be processed. ${error?.response?.data?.message}`,
            { variant: 'error' }
          );
        } else {
          admin &&
            enqueueSnackbar(
              'Something went wrong while updating the campaign!'
            );
        }
      })
      .finally(() => {
        dispatch({ type: types.CHANGE_SUBMIT, payload: false });
      });
  };

const deleteCreativesFromServer = (
  deletedCreatives: number[],
  advId: number
) => {
  if (deletedCreatives.length > 0) {
    deletedCreatives.forEach((_id) => {
      API.delete(`${baseUrl}/${advId}/creative/${_id}`)
        .then((res) => {
          console.log(res);
          if (res.status === 200 && res.data?.success === true) {
            console.log('Item Deleted Successfully');
          }
          if (res.status === 200 && res.data?.success === false) {
            console.log('delete creative from list');
          }
        })
        .catch((e) => console.log(e));
    });
  }
};
