import { CircularProgress } from '@material-ui/core';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  RouteComponentProps,
  useHistory,
} from 'react-router-dom';
import { getToken } from '../services/tokenService';
import { logoutAction } from '../store/actions/userActions';
import { NonAuthRoutesEnum } from './Routes';

interface Props {
  Component: React.FC<RouteComponentProps>;
  path: string;
  exact?: boolean;
  location?: any;
}

const PrivateRoute = ({
  Component,
  path,
  exact = false,
  location,
}: Props): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [token, setToken] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const advertiser_id = useSelector((state: any) => state?.user?.advertiserId);

  const message = advertiser_id
    ? 'Please login to view this page'
    : 'You do not have an account';

  React.useEffect(() => {
    const token = getToken();
    setToken(token);
    setLoading(false);
  }, [token]);

  if (token) {
    if (!advertiser_id) {
      dispatch(logoutAction());
    }
  }

  return (
    <>
      {loading ? (
        <div
          style={{
            width: '',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5rem',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Route
          exact={exact ?? false}
          path={path}
          render={(props: RouteComponentProps) => {
            if (token && advertiser_id) {
              return <Component {...props} />;
            } else {
              return (
                <Redirect
                  to={{
                    pathname: NonAuthRoutesEnum.SignIn,
                    state: {
                      message,
                      from: location.pathname,
                    },
                  }}
                />
              );
            }
          }}
        />
      )}
    </>
  );
};
export default PrivateRoute;
