import { useState, useEffect } from 'react'
import { TextField } from '@material-ui/core';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import types from '../../../../store/actions/types';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  cardHandler,
  getLastFourDigits,
  deleteCardAction
} from '../../../../store/actions/userActions';
import AHButton from '../../../common/AHButton/AHButton';
import AHInputBox from '../../../common/AHInputBox';
import CCInput from '../CCInput';
import './style.scss';

const CCForm = function (): JSX.Element {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const advId = useSelector((state: any) => state?.user?.advertiserId);

  const [isLoading, setIsLoading] = useState(false)

  const lastFourDigit = useSelector(
    (state: any) => state?.profile?.card?.last4digits
  );
  const isEdit = useSelector(
    (state: any) => state?.profile?.card?.isEdit
  )
  let lastFour = "**** ".repeat(3) + lastFourDigit;

  useEffect(() => {
    asyncLastFourDigitsFire()
  }, []);

  useEffect(() => {
    asyncLastFourDigitsFire()
  }, [advId])

  const asyncLastFourDigitsFire = async () => {
    // TODO - make the loading feature work eventually
    await setIsLoading(true) 
    await dispatch(getLastFourDigits(enqueueSnackbar));
    await setIsLoading(false) 
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    stripe
      .createToken(elements.getElement(CardNumberElement))
      .then((res) => {
        console.log(res);
        const tokenId = res?.token?.id;
        const reqType = isEdit ? "put" : "post";
        if(tokenId) {
          dispatch(cardHandler(tokenId, enqueueSnackbar, reqType));
        }
        else {
          enqueueSnackbar("Error while creating stripe token!", {variant: "error"});
        }
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar(err.error.message, { variant: 'error' });
      });
  };

  const handleDelete = () => {
    dispatch(deleteCardAction(enqueueSnackbar));
  }

  const handleEdit = () => {
    dispatch({
      type: types.SET_CARD_DIGIT,
      payload: ""
    })
    dispatch({
      type: types.SET_CARD_EDITING,
      payload: true
    })
  }

  return (
    <div>
      {lastFourDigit ? (
        <div data-testid="cc" className="card-box-container">
          <AHInputBox
            type="text"
            name="card-holder"
            placeholder=""
            handleChange={() => { }}
            value={lastFour}
          />
          <div className="card-buttons-wrapper">
            <div className="card-button">
              <AHButton
                text="Edit"
                handleClick={handleEdit}
                btntype="action"
                color="primary"
                variant="contained"
                type="submit"
              />
            </div>
            <div className="card-button">
              <AHButton
                text="Delete"
                handleClick={handleDelete}
                btntype="action"
                color="primary"
                variant="contained"
                type="submit"
              />
            </div>
          </div>
        </div>
      ) : (
        <form data-testid="cc-form" onSubmit={handleSubmit}>
          <div className="cc-content-box cc-number">
            <h6 className="cc-content-title">Card Number</h6>
            <div className="cc-number-box">
              <TextField
                data-testid="cc-number-input"
                name="ccnumber"
                variant="outlined"
                required
                fullWidth
                InputProps={{
                  inputComponent: CCInput,
                  inputProps: {
                    component: CardNumberElement,
                  },
                }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
          </div>

          <div className="cc-footer">
            <div className="cc-expire-date">
              <h6 className="cc-content-title">Expiration Date</h6>
              <div className="cc-exp-date-box">
                <TextField
                  data-testid="cc-exp-input"
                  name="ccexp"
                  variant="outlined"
                  required
                  fullWidth
                  InputProps={{
                    inputProps: {
                      component: CardExpiryElement,
                    },
                    inputComponent: CCInput,
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </div>
            <div className="cc-cvc">
              <h6 className="cc-content-title">CVC</h6>
              <div className="cc-name-box">
                <TextField
                  data-testid="cc-cvc-input"
                  name="cvc"
                  variant="outlined"
                  required
                  fullWidth
                  InputProps={{
                    inputProps: {
                      component: CardCvcElement,
                    },
                    inputComponent: CCInput,
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </div>
          </div>
          <div className="cc-submit-container" data-testid="add-card-button">
            <AHButton
              text={isEdit ? "Edit Card" : "Add Card"}
              handleClick={() => { }}
              btntype="action"
              color="primary"
              variant="contained"
              type="submit"
              disabled={!stripe}
            />
          </div>
        </form>
      )}
    </div>
  );
};
export default CCForm;
