import { Button, CircularProgress } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  getSubmitButtonText,
  isNextButtonDisabled,
} from './buttonFunctionality';

interface ICampFormControlProps {
  activeStep: number;
  steps: string[];
  handleSubmit: () => void;
  handleBack: () => void;
  handleNext: () => void;
}
const CampFormControl: React.FC<ICampFormControlProps> = (props) => {
  const { handleSubmit, activeStep, steps, handleBack, handleNext } = props;
  const isFileUploading = useSelector(
    (state: any) => state?.campaigns?.fileUploading
  );
  const submit = useSelector((state: any) => state?.campaigns?.submit);
  const loading = useSelector((state: any) => state?.campaigns?.loading);
  const camp = useSelector((state: any) => state?.campaigns?.campaign);
  const startDate = useSelector(
    (state: any) => state?.campaigns?.campaign?.start_date
  );

  const partialFreqCap = useSelector(
    (state: any) => state?.campaigns?.partialFreqCap
  );
  const editmode = useSelector((state: any) => state?.campaigns?.editMode);
  const duplicate = useSelector((state: any) => state?.campaigns?.duplicate);
  const campaignType = useSelector(
    (state: any) => state?.campaigns?.campaignType
  );

  return (
    <div>
      <div
        className="action-btn"
        style={
          activeStep !== 0
            ? { justifyContent: 'space-between' }
            : { justifyContent: 'flex-end' }
        }
      >
        {activeStep !== 0 ? (
          <Button
            className="cancel-btn"
            onClick={handleBack}
            data-testid="previous-step-button"
          >
            Previous step
          </Button>
        ) : (
          ''
        )}

        {activeStep === steps.length - 1 ? (
          <Button
            className="next-btn"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isFileUploading || submit || loading}
          >
            {getSubmitButtonText(editmode, duplicate)}
            {submit && (
              <CircularProgress
                style={{ color: '#fff', marginLeft: '.5rem' }}
                size={15}
              />
            )}
          </Button>
        ) : (
          <Button
            data-testid="next-step-button"
            className="next-btn"
            disabled={isNextButtonDisabled({
              activeStep,
              camp,
              partialFreqCap,
              loading,
              campaignType,
            })}
            variant="contained"
            color="primary"
            onClick={handleNext}
          >
            Next
            {loading && (
              <CircularProgress
                style={{ color: '#6c63ff', marginLeft: '.5rem' }}
                size={15}
              />
            )}
          </Button>
        )}
      </div>
    </div>
  );
};
export default CampFormControl;
