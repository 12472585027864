export const categories = [
  { 
    id: 1,
    value: "",
    name: "Category"
  },
  { 
    id: 2,
    value: "IAB1",
    name: "Arts & Entertainment"
  },
  { 
    id: 3,
    value: "IAB2",
    name: "Automotive"
  },
  { 
    id: 4, 
    value: "IAB3",
    name: "Business"
  },
  { 
    id: 5,
    value: "IAB4",
    name: "Careers"
  },
  { 
    id: 6,
    value: "IAB5",
    name: "Education"
  },
  { 
    id: 7,
    value: "IAB6",
    name: "Family & Parenting"
  },
  { 
    id: 8,
    value: "IAB7",
    name: "Health & Fitness"
  },
  { 
    id: 9,
    value: "IAB8",
    name: "Food & Drink"
  },
  { 
    id: 10,
    value: "IAB9",
    name: "Hobbies & Interests"
  },
  { 
    id: 11,
    value: "IAB10",
    name: "Home & Garden"
  },
  { 
    id: 12,
    value: "IAB11",
    name: "Law, Government, & Politics"
  },
  { 
    id: 13,
    value: "IAB12",
    name: "News"
  },
  { 
    id: 14,
    value: "IAB13",
    name: "Personal Finance"
  },
  { 
    id: 15,
    value: "IAB14",
    name: "Society"
  },
  { 
    id: 16,
    value: "IAB15",
    name: "Science"
  },
  { 
    id: 17,
    value: "IAB17",
    name: "Sports"
  },
  { 
    id: 18,
    value: "IAB18",
    name: "Style & Fashion"
  },
  { 
    id: 19,
    value: "IAB19",
    name: "Technology & Computing"
  },
  { 
    id: 20,
    value: "IAB20",
    name: "Travel"
  },
  { 
    id: 21,
    value: "IAB21",
    name: "Real Estate"
  },
  { 
    id: 22,
    value: "IAB22",
    name: "Shopping"
  },
  { 
    id: 23,
    value: "IAB23",
    name: "Religion & Spirituality"
  },
  { 
    id: 24,
    value: "IAB24",
    name: "Uncategorized"
  },
  { 
    id: 25,
    value: "IAB25",
    name: "Non-Standard Content"
  }
]