import React, { Fragment, useState, useEffect } from 'react'
import { addPmpAction } from '../../../../store/actions/userActions';
import { useDispatch } from 'react-redux';
import { TextField } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import AHButton from '../../../common/AHButton/AHButton';
import { Dialog, Transition } from '@headlessui/react'


interface IProps {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setRequestPmpsFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddPmpModal = ({ openModal, setOpenModal, setRequestPmpsFlag }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  let [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const name = e.target.valueOne.value
    const dealId = e.target.valueTwo.value
    const floorCPM = e.target.valueThree.value

    setIsLoading(true)
    const response: any = await dispatch(addPmpAction(name, dealId, floorCPM, enqueueSnackbar));
    if (response.success) {
      setOpenModal(false)
      setRequestPmpsFlag(true)
    } else {
      // NOTE do nothing
      // toast should show
    }
    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(false)
  }, [])

  return (
    <Transition.Root show={openModal} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpenModal}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pb-4 text-left overflow-hidden shadow-xl transform mt-8 transition-all sm:mb-8 sm:mt-64 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div className="">
                <div className="pt-8 pb-16 sm:flex-auto">
                  <h1 className="text-xl font-semibold text-gray-900">Add Marketplace</h1>
                  <p className="mt-2 text-sm text-gray-700">
                  Fill out to add a private marketplace below. 
                  </p>
                </div>
                <form onSubmit={(e) => handleSubmit(e)}>
                  <div className="flex pb-8">
                    <TextField id="name" className="w-full" label="Name" name="valueOne" variant="outlined" />
                  </div>
                  <div className="flex pb-8">
                    <TextField id="name2" className="w-full" label="Deal Id" name="valueTwo" variant="outlined" />
                  </div>
                  <div className="flex pb-8">
                    <TextField id="floorCPM" className="w-full" label="Floor CPM" name="valueThree" variant="outlined" />
                  </div>

                  <div className="flex justify-end">
                    <AHButton
                          disabled={isLoading}
                          text="Submit"
                          handleClick={() => {}}
                          btntype="action"
                          color="primary"
                          variant="contained"
                          type="submit"
                        />
                  </div>
                </form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default AddPmpModal;