import { FormControl, Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { useSelector } from 'react-redux';

const theme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&:hover:not($disabled):not($error) $notchedOutline': {
          border: '1px solid',
          borderColor: '#f0f1f3',
          outline: 'none',
        },
      },
      input: {
        padding: '14px 14px!important',
      },
      notchedOutline: {
        borderColor: 'none',
        border: '1px solid #f0f1f3',
      },
    },
  },
});

interface IProps {
  options: any;
  index?: number;
  value: string;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number | null
  ) => void;
  disabled?: any;
}

const AHMenu = function ({
  disabled,
  options,
  index,
  value,
  handleChange,
}: IProps) {
  const budgetType = useSelector(
    (state: any) => state?.campaigns?.campaign?.budget_type
  );
  return (
    <ThemeProvider theme={theme}>
      <FormControl
        variant="outlined"
        style={{ width: '100%', marginTop: '.5rem' }}
      >
        <Select
          disabled={disabled}
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={value}
          onChange={(e: any) => {
            index === undefined
              ? handleChange(e, null)
              : handleChange(e, index);
          }}
        >
          {options.map((item, index: number) => (
            <MenuItem
              key={new Date().getTime() + index}
              disabled={budgetType === 'cpa' && item.data === 'reach'}
              value={item.data}
            >
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
};
export default AHMenu;
