import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import './style.scss';

const chartSkeleton = ({ width }) => {
  return (
    <div className="chart-skeleton" style={{ width: width }}>
      <div className="chart-inner">
        <div className="ci-content-top">
          <Skeleton variant="text" width={80} />
          <Skeleton variant="text" width={80} />
        </div>
        <div className="ci-content-body">
          <Skeleton variant="rect" height={150} />
        </div>
      </div>
    </div>
  );
};

export default chartSkeleton;
