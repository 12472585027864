import {
  Checkbox,
  FormControlLabel,
  InputAdornment,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IChangeCampValue } from 'store/actions/actions.interface';
import {
  AutoOptimizedCPMToolTip,
  MaxCpmToolTip,
} from '../TooltipComponent/tooltips';
import AHInputBox from 'components/common/AHInputBox';

interface IProps {
  budgetType: string;
  handleDisableMaxCpm: () => boolean;
  changeCampaignValue: (val: IChangeCampValue) => void;
  onCheckStateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDisableAutoCpm: () => boolean;
}
const CPMComponent = (props: IProps) => {
  const {
    budgetType,
    handleDisableMaxCpm,
    changeCampaignValue,
    onCheckStateChange,
    handleDisableAutoCpm,
  } = props;
  const dispatch = useDispatch();
  const { admin } = useSelector((state: any) => state?.user);
  const { max_cpm: maxCpm, auto_optimize_cpm: autocpm } = useSelector(
    (state: any) => state?.campaigns?.campaign
  );

  const HtmlTooltip = withStyles((theme: Theme) => ({
    arrow: {
      color: '#000',
    },
    tooltip: {
      backgroundColor: '#1d1414',
      color: '#fff',
      maxWidth: 500,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #110d0d',
    },
  }))(Tooltip);

  let content2 = (
    <div className="campForm-double-itembox" data-testid="camp-cpm">
      <div className="item-in-double">
        <div className="campForm-input-label">Max CPM, $</div>
        <AHInputBox
          testID="campaign-max-cpm"
          disabled={handleDisableMaxCpm()}
          value={maxCpm}
          type="number"
          placeholder=""
          name=""
          handleChange={(e) => {
            e.target.value > 0 &&
              dispatch(
                changeCampaignValue({
                  name: 'max_cpm',
                  data: e.target.value,
                })
              );
          }}
        />
      </div>
      <div className="item-in-double auto-cpm-check">
        <FormControlLabel
          control={
            <Checkbox
              data-testid="campaign-auto-optimaized-cpm"
              disabled={handleDisableAutoCpm()}
              checked={autocpm}
              onChange={onCheckStateChange}
              name="checkedA"
              style={{ color: 'var(--main-color)' }}
            />
          }
          label="Auto Optimized CPM"
        />
      </div>
    </div>
  );

  if (budgetType === 'cpa') {
    if (!admin) {
      content2 = null;
    }
  }

  return content2;
};
export default CPMComponent;
