// @ts-nocheck
import { Tooltip } from '@material-ui/core';
import 'date-fns';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeCampaignValue } from '../../../store/actions/campaignActions';
import AudienceOption from '../../audience-option';
import Dropzone from '../../dropzone';
import { AlgoliaMultiSelect } from '../../MultiGeoSelector/MultiGeoSelector';
import AdvanceComponent from './components/AdvanceComponent';
import { IGeo } from 'models/geo';
import './style.scss';

const DocumentIcon = '/images/document-icon.svg';
const audienceOptionsData = [
  {
    id: 0,
    title: 'retargeting',
    subtitle: 'Target consumers who have already visited your website.',
  },
  {
    id: 1,
    title: 'lookalike',
    subtitle:
      'Target new consumers who are likely to be interested in your business because they’re similar to your existing customers.',
  },
  {
    id: 2,
    title: 'email',
    subtitle:
      `Target consumers based on their email list.  Emails can be MD5 hashed or unhashed. Email list should a single column of emails. An example can be found at: shorturl.at/jJMY9` , 
  },
  {
    id: 3,
    title: '',
    subtitle: '',
  },
];

const AudienceForm: React.FC = () => {
  const dispatch = useDispatch();
  const campaignType = useSelector(
    (state: any) => state?.campaigns?.campaign?.campaign_type
  );
  // const [selectedOption, setSelectedOption] = useState(
  //   audienceOptionsData[0].title
  // );

  const handleRadioButton = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setSelectedOption(event.target.value);
    dispatch(
      changeCampaignValue({
        name: 'campaign_type',
        data: event.target.value,
      })
    );
  };

  const handleFormSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const handleEmailListLink = (link: string) => {
    dispatch(changeCampaignValue({ name: 'list_url', data: link }));
  };

  const handleGeo = (geo: IGeo[]) => {
    const geoData = [];
    const geoObj = {};

    // inclue object part
    const include = {};
    const cityInc = [];
    const regionInc = [];
    const objectIDsInc = [];

    // exclude object part
    const exclude = {};
    const cityExc = [];
    const regionExc = [];
    const objectIDsExc = [];

    geo.forEach((g: IGeo): void => {
      if ('geoname' in g) {
        cityInc.push(g.geoname);
      } else {
        regionInc.push(`${g?.country_code_3}/${g?.region_code}`);
      }
      objectIDsInc.push(g.objectID);
    });

    include.city = cityInc;
    include.region = regionInc;
    include.objectIDs = objectIDsInc;
    geoObj.include = include;

    exclude.city = cityExc;
    exclude.region = regionExc;
    exclude.objectIDs = objectIDsExc;
    geoObj.exclude = exclude;

    geoData.push(geoObj);

    dispatch(changeCampaignValue({ name: 'geography', data: geoData }));
  };

  return (
    <div className="opt-wrapper audience">
      <div className="optimization_title">Audience & Geography</div>
      <div className="form">
        <div className="form-wrapper">
          <div className="audience-header">
            <div className="audience-header__icon-wrapper">
              <img
                src={DocumentIcon}
                className="audience-header__icon"
                alt=""
              />
            </div>
            <div>
              <h5 className="audience-header__title">
                Add Audience and Geography
              </h5>
              <p className="audience-header__subtitle">
                Choose retargeting, or look alike, or upload email list.
              </p>
            </div>
          </div>

          {/* Audience Filter  */}
          <h6 className="section-title">Audience Filter</h6>
          <form
            data-testid="audience-options"
            className="audience-options"
            onSubmit={handleFormSubmit}
          >
            {audienceOptionsData.map(({ id, title, subtitle }) => (
              <AudienceOption
                key={id}
                title={title}
                subtitle={subtitle}
                selectedOption={campaignType}
                onChange={handleRadioButton}
              />
            ))}
          </form>

          <div data-testid="geobox" className="item geobox">
            {campaignType === 'email' && (
              <Dropzone
                type="email_list"
                acceptedFileType=".csv, .txt"
                getFile={handleEmailListLink}
              />
            )}
            <Tooltip
              placement="top"
              arrow
              title="By default, geography is United States - To Change: please select desired cities and states 👇"

            >
              {/* // title="By default, geography is United States - To Change: please select desired Country, Cities, Zip Codes, and DMAs for Geo-Filtering below 👇" */}
              <h6 className="section-title">Geography Filter:</h6>
              {/* <span>
                  Please select desired Country, Cities, Zip Codes, and DMAs for Geo-Filtering
              </span> */}
             

            </Tooltip>
            <div className="geo-wrapper">
              <br></br>
              {/* <p className="geo-include-p">Include:</p> */}
              <AlgoliaMultiSelect handleGeo={handleGeo}/>
            </div>
            {/*Exclude Form*/}
            {/* <div className="geo-wrapper">
              <br></br>
              <p className="geo-exclude-p">Exclude:</p>
              <AlgoliaMultiSelect handleGeo={handleGeo} />
            </div> */}
          </div>

          {/* Advance field  */}
          <div style={{ marginTop: '3rem' }}>
            <AdvanceComponent />
          </div>
          {/* Advance field end  */}
        </div>
      </div>
    </div>
  );
};
export default AudienceForm;
