import { CampaignTypeEnum } from '../../../constants/enums';
import { formatStringToJSON } from '../../../utils/commonFns';

const isValid = (item) => {
  return !!item;
};

const isValidCpm = (cpm, auto_optimized_cpm) => {
  if (!!cpm || auto_optimized_cpm) {
    return true;
  }
  return false;
};

const isValidFreqCap = (partialFreqCap) => {
  if (partialFreqCap.length === 0) return true;

  if (partialFreqCap.length > 0) {
    for (let i = 0; i < partialFreqCap.length; i++) {
      if (!partialFreqCap[i].frequency) {
        return false;
      }
      if (!partialFreqCap[i].intervalValue) {
        return false;
      }
    }
    return true;
  }
};

interface IParams {
  activeStep: number;
  camp: any;
  partialFreqCap: any;
  loading: boolean;
  campaignType: string;
}
export const isNextButtonDisabled = (params: IParams): boolean => {
  const { activeStep, camp, partialFreqCap, loading, campaignType } =
    params;
  const { advanced_targeting } = camp;
  if (activeStep === 0) {
    if (campaignType === CampaignTypeEnum.PERFORMANCE) {
      if (
        isValid(camp.name) &&
        isValid(camp.start_date) &&
        isValid(camp.end_date) &&
        isValid(camp.budget) &&
        isValidCpm(camp.max_cpm, camp.auto_optimize_cpm) &&
        isValid(camp.kpi_value) &&
        isValid(camp.kpi_type) &&
        isValidFreqCap(partialFreqCap) &&
        isValid(camp.pacing) &&
        !loading
      ) {
        return false;
      } else return true;
    }
    if (campaignType === CampaignTypeEnum.IMPRESSION) {
      if (
        isValid(camp.name) &&
        isValid(camp.start_date) &&
        isValid(camp.end_date) &&
        isValid(camp.budget) &&
        isValid(camp.budget_type) &&
        isValidCpm(camp.max_cpm, camp.auto_optimize_cpm) &&
        isValid(camp.kpi_value) &&
        isValid(camp.kpi_type) &&
        isValidFreqCap(partialFreqCap) &&
        isValid(camp.pacing) &&
        !loading
      ) {
        return false;
      } else return true;
    }
  } else if (activeStep === 1) {
    if (
      advanced_targeting.trim() !== '' &&
      !formatStringToJSON(advanced_targeting)
    ) {
      return true;
    }

    // if email selected and email is not uploaded -> disabled
    if (camp.campaign_type === 'email') {
      return camp.list_url ? false : true;
    } else {
      return false;
    }
  }
  return false;
};

export const getSubmitButtonText = (editMode: boolean, duplicate: boolean) => {
  let submitButtonText = '';

  if (editMode) {
    submitButtonText = 'Update Campaign';

    if (duplicate) {
      submitButtonText = 'Add Campaign';
    }
  } else {
    submitButtonText = 'Add Campaign';
  }
  return submitButtonText;
};
