export function sortObjByDate(obj) {
  const sortedDates = Object.keys(obj).sort(
    (a: number | string, b: number | string) => {
      a = new Date(a).getTime();
      b = new Date(b).getTime();
      return a - b;
    }
  );

  const sortedObject = sortedDates.reduce((acc, cur) => {
    acc.set(cur, obj[cur]);
    return acc;
  }, new Map());

  return Array.from(sortedObject);
}

// --> GET Impression Data
export const getDataForImpression = (data) => {
  const { daily } = data;

  const impressions = daily.reduce((acc, cur) => {
    let key = cur['DATE'];

    if (!acc[key]) {
      acc[key] = 0.0;
    }

    acc[key] = acc[key] + cur['IMPRESSIONS'];

    return acc;
  }, {});
  return sortObjByDate(impressions);
  // const sortedImpressions = sortObjByDate(impressions);

  // return Object.entries(sortedImpressions);
};

export const getMinDate = (data) => {
  // console.log(data);
  let minTime = Infinity;
  let d = data;

  let date = new Date();
  d.daily.map((item) => {
    date = new Date(item['DATE']);
    let time = date.getTime();
    if (time < minTime) {
      minTime = time;
    }
  });
  return minTime;
};

// Get lowest date from Attributed Sessions data
export const getMinDateFromAttrSession = (data = []) => {
  let minTime = Infinity;
  let d = [...data];

  let date = new Date();
  d.map((item) => {
    date = new Date(item['DAY']);
    let time = date.getTime();
    if (time < minTime) {
      minTime = time;
    }
  });

  return minTime;
};

export const getDataForCost = (data) => {
  const { daily } = data;

  const spend = daily.reduce((acc, cur) => {
    let key = cur['DATE'];

    if (!acc[key]) {
      acc[key] = 0.0;
    }

    acc[key] =
      parseFloat(acc[key]) + Math.round(parseFloat(cur['SPEND']) * 100) / 100;
    acc[key] = parseFloat(acc[key]).toFixed(2);

    return acc;
  }, {});

  return sortObjByDate(spend);
};

export const getTotalImpression = (data) => {
  const impressions = data.daily.reduce((accumulator, currentValue) => {
    return accumulator + currentValue['IMPRESSIONS'];
  }, 0);
  return impressions;
};

export const getTotalCost = (data) => {
  const costs = data.daily.reduce((accumulator, currentValue) => {
    return accumulator + parseFloat(currentValue['SPEND']);
  }, 0.0);
  return costs.toFixed(2);
};

// ROAS - Line Graph
export const getDataForRoas = (data) => {
  const { daily = [] } = data;

  const roasByDate = daily.reduce((acc, obj) => {
    let key = obj['DATE'];

    if (!acc[key]) {
      acc[key] = 0.0;
    }

    // handle - divide something with zero
    if (parseFloat(obj['SPEND']) === 0.0) {
      acc[key] = acc[key] + 0.0;
      return acc;
    }

    let roas =
      Math.round(
        (parseFloat(obj['REVENUE']) / parseFloat(obj['SPEND'])) * 100
      ) / 100;

    acc[key] = parseFloat(acc[key]) + roas;
    acc[key] = parseFloat(acc[key]).toFixed(2);

    return acc;
  }, {});

  // const sortedRoas =
  return sortObjByDate(roasByDate);
  // console.log(Object.entries(sortedRoas));
  // return Object.entries(sortedRoas);
};

// ---->  TOTAL ROAS
export const getTotalRoas = (data) => {
  const totalRevenue = data.daily.reduce((acc, cur) => {
    return acc + parseFloat(cur['REVENUE']);
  }, 0.0);

  const totalSpend = data.daily.reduce((acc, cur) => {
    return acc + parseFloat(cur['SPEND']);
  }, 0.0);

  const totalRoas = totalRevenue / totalSpend;

  return totalRoas.toFixed(2);
};

interface IAttrSess {
  ADVERTISER_ID: number;
  ATTRIBUTED_SESSIONS: number;
  CAMPAIGN_ID: number;
  DAY: string;
}
export const getDataForAttrSess = (data: IAttrSess[]) => {
  const attrSess = data.reduce((acc, cur: IAttrSess) => {
    let key = cur['DAY'];
    if (!acc[key]) {
      acc[key] = 0.0;
    }
    acc[key] = acc[key] + cur['ATTRIBUTED_SESSIONS'];
    return acc;
  }, {});

  // const sortedAttrSess =
  return sortObjByDate(attrSess);
  // return Object.entries(sortedAttrSess);
};
export const getTotalAttrSess = (data: IAttrSess[]): number => {
  return data.reduce((acc: number, cur: IAttrSess) => {
    return acc + cur.ATTRIBUTED_SESSIONS;
  }, 0.0);
};

// Gender Percentage Pi-Chart
const getTotalGenderData = (data) => {
  const totalGenderData = data.total.reduce((acc, cur) => {
    return acc + cur.gender[0].female + cur.gender[0].male;
  }, 0);
  return totalGenderData;
};

const getTotalMenData = (data) => {
  const totalMaleData = data.total.reduce((acc, cur) => {
    return acc + cur.gender[0].male;
  }, 0);
  return totalMaleData;
};

const getTotalWomenData = (data) => {
  const totalFemaleData = data.total.reduce((acc, cur) => {
    return acc + cur.gender[0].female;
  }, 0);
  return totalFemaleData;
};

export const getTotalWomenPercentage = (data) => {
  const totalGenderData = parseInt(getTotalGenderData(data));
  const totalFemaleData = parseInt(getTotalWomenData(data));
  const totalPercentage = (totalFemaleData * 100) / totalGenderData;
  return totalPercentage;
};

export const getTotalMenPercentage = (data) => {
  const totalGenderData = parseInt(getTotalGenderData(data));
  const totalMaleData = parseInt(getTotalMenData(data));
  const totalPercentage = (totalMaleData * 100) / totalGenderData;
  return totalPercentage;
};

// Age percentage Pi-chart
const getTotalAgeData = (data) => {
  const totalAgeData = data.total.reduce((acc, cur) => {
    return (
      acc +
      Object.values(cur.age[0]).reduce((a: number, b: number) => {
        return a + b;
      }, 0)
    );
  }, 0);
  return totalAgeData;
};

const getRangeTotalData = (data, range) => {
  const totalRangeData = data.total.reduce((acc, cur) => {
    return acc + cur.age[0][range];
  }, 0);
  return totalRangeData;
};

export const getAgeRangePercentage = (data, range) => {
  const totalAgeData = getTotalAgeData(data);
  const totalRangedata = getRangeTotalData(data, range);
  const total = (totalRangedata * 100) / totalAgeData;
  return total;
};

// Location Graph
const getTotalLocationData = (data) => {
  const totalLocationsData = data.total.reduce((acc, cur) => {
    return (
      acc +
      Object.values(cur.locations[0]).reduce((a: number, b: number) => {
        return a + b;
      }, 0)
    );
  }, 0);
  return totalLocationsData;
};

const getTotalCityData = (data, city) => {
  const totalCityData = data.total.reduce((acc, cur) => {
    return acc + cur.locations[0][city];
  }, 0);
  return totalCityData;
};

export const getCityPercentage = (data, city) => {
  const totalCityData = getTotalCityData(data, city);
  const totalLocationData = getTotalLocationData(data);
  return (totalCityData * 100) / totalLocationData;
};

// --> Get Previous Month
const getPreviousMonth = () => {
  const d = new Date();
  const m = d.getMonth();
  d.setMonth(m - 1);
  if (d.getMonth() === m) d.setDate(0);
  return d;
};

// --> Get Next Month
const getNextMonth = () => {
  const d = new Date();
  const m = d.getMonth();
  d.setMonth(m + 1);
  if (d.getMonth() === m) d.setMonth(0);
  return d;
};

// --> Add Comma inside numbers
const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// --> Generate Impression for a campaign
// filtering by campaign id from historical data
// sum of the impressions from filtered historical data
const generateImpressionForTable = (campId: string, reportData) => {
  const { historical = [] } = reportData;

  const filteredHistoricalData = historical.filter(
    (item) => item.CAMPAIGN_ID == campId
  );

  const impressions = filteredHistoricalData.reduce((acc, cur) => {
    return acc + cur.IMPRESSIONS;
  }, 0);

  return impressions;
};

// ---> Generate Cost for a campaign
const generateCostForTable = (
  campId: number,
  budgetType: string,
  reportData: any
): string => {
  const { daily = [], historical = [] } = reportData;

  // Filter Daily Data For Current Campaign ID
  const filteredDataByCampId = historical.filter(
    (item) => item.CAMPAIGN_ID == campId
  );

  // Total Budget Spent from daily_data
  const totalCostFromData = filteredDataByCampId.reduce((acc, cur) => {
    let currentValue = 0;
    if (budgetType === 'spend' || budgetType === 'cpa' || budgetType === "completed_audio_ads" || budgetType === 'impressions') {
      currentValue = Number.parseFloat(cur.SPEND);
      console.log("spend", currentValue);
    } 
    return acc + currentValue;
  }, 0.0 );
  return totalCostFromData.toFixed(2);
};

// ----> Generate ROAS for a campaign
function generateCampaignRoasForTable(campId: number, reportData): string {
  const { daily = [], historical = [] } = reportData;

  const filteredByCampID = historical.filter(
    (item) => item.CAMPAIGN_ID === campId
  );

  const filteredRevenue = filteredByCampID.reduce((acc, cur) => {
    return acc + Number.parseFloat(cur.REVENUE);
  }, 0.0);

  const filteredSpend = filteredByCampID.reduce((acc, cur) => {
    return acc + Number.parseFloat(cur.SPEND);
  }, 0.0);

  if (filteredRevenue === 0.0 || filteredSpend === 0.0) {
    return '0.0';
  }

  return (filteredRevenue / filteredSpend).toFixed(2);
}

// ----> Generate Pacing
// const generateCampaignPacing = (pacingParam: IGPacing, reportData): number => {
//   const { campId, budget, budgetType, startDate, endDate } = pacingParam;

//   const start = new Date(startDate).getTime();
//   const end = new Date(endDate).getTime();

//   let yesterday = new Date();
//   yesterday.setDate(yesterday.getDate() - 1);
//   const yesterdayInMili = yesterday.getTime();

//   let historyData: IHistoryData;
//   if (reportData) {
//     historyData = reportData?.historical.find(
//       (item: any) => item.CAMPAIGN_ID === campId
//     );
//   }

//   if (!historyData) return 0;

//   const {
//     COST_TOTAL,
//     IMPRESSIONS_TOTAL,
//     COMPLETED_IMPRESSIONS_TOTAL,
//   } = historyData;

//   let delivered_units: number = 0;
//   let target: number = budget;
//   let pacing: number = 0;

//   if (budgetType === 'spend' || budgetType === 'cpa') {
//     delivered_units = Number.parseFloat(COST_TOTAL) * 0.75;
//   }
//   else if (budgetType === 'impressions') {
//     delivered_units = IMPRESSIONS_TOTAL;
//   } else if (budgetType === 'completed_audio_ads') {
//     delivered_units = COMPLETED_IMPRESSIONS_TOTAL;
//   }

//   pacing =
//     delivered_units / target / ((yesterdayInMili - start) / (end - start));

//   if (pacing === NaN) return 0;

//   if (pacing > 1) return 100;

//   return pacing * 100;
// };

export default {
  sortObjByDate,
  getPreviousMonth,
  getDataForCost,
  getDataForImpression,
  getDataForRoas,
  getMinDate,
  getTotalCost,
  getTotalRoas,
  getTotalImpression,
  numberWithCommas,
  generateImpressionForTable,
  generateCostForTable,
  generateCampaignRoasForTable,
  // generateCampaignPacing,
  getNextMonth,
  getMinDateFromAttrSession,
  getDataForAttrSess,
  getTotalAttrSess,
};
