import React, { useImperativeHandle, useRef } from 'react';
import './style.scss';

const CCInput = function ({ component: Component, inputRef, ...props }) {
  const elementRef = useRef();

  useImperativeHandle(inputRef, () => ({
    //@ts-ignore
    focus: () => elementRef.current.focus,
  }));

  return (
    <Component
      onReady={(element) => (elementRef.current = element)}
      {...props}
    />
  );
};
export default CCInput;
